import axios from 'axios';
import axiosRetry from 'axios-retry';
import { setupCache } from 'axios-cache-adapter';
import qs from 'qs';

const clientCache = setupCache({
  // Время жизни в кеше - 10 минут
  maxAge: 10 * 60 * 1000,
  // Задаем лимит количества сохраненных записей в кеше, чтобы вся память не съедалась
  limit: 100,
  key: (req) => {
    // необходимо переопределить дефолтную генерацию ключа для каждого запроса, т.к. из-за того, что у нас в юрл передаются объекты,
    // axios-cache-adapter превращал их [object Object] и из-за этого настройка exclude.query = false не работала должным образом
    const params = req.paramsSerializer(req.params);
    return req.url + '?' + params;
  },
  exclude: {
    query: false,
    filter: (req) => !req.useCache
  }
});

export const defaultClient = axios.create({
  // Используем кеширующий адаптер
  adapter: clientCache.adapter,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

defaultClient.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    });
  };

  return config;
});

// Повторяем запросы при ошибке сети до тех пор, пока она не исчезнет
axiosRetry(defaultClient, {
  // Повторяем 10 раз, потом показываем ошибку
  retries: 10,
  // Повторный запрос через 5 секунд
  retryDelay: () => 5000,
  retryCondition: (error) => {
    return (
      // Повторяем только GET запросы
      error.config?.method === 'get' &&
      // Только если мы не получили ответ или ответ связан с проблемами на сервере
      (!error.response || error.response.status > 500)
    );
  }
});
