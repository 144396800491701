import { AxiosRequestConfig } from 'axios';
import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { apiUrl } from '../../config';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  url: `${apiUrl}/lib/artists`,
  method: 'get',
  useCache: true
};

export interface Artist {
  id: number;
  origin_id?: string;
  name: string;
  created_at?: string;
  updated_at?: string;
}

export interface ArtistQueryVars {
  filter?: {
    name?: string;
  };
}

const queryOptions: IUseAxiosQueryOptions<ArtistQueryVars, Artist[]> = {
  query,
  mapData: (result) => result.data,
  mapVariables: (variables) => ({ params: variables })
};

export function useArtists(variables?: ArtistQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables: variables
  });
}
