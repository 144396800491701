import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@proscom/ui-react';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow_down.svg';

import s from './Dropdown.module.scss';

export interface DropdownItem {
  id: number;
  title: string;
  value: any;
}

export interface DropdownProps {
  /**
   * Список элементов
   */
  items: DropdownItem[];

  /**
   * Текущий индекс
   */
  selectedId: number;

  /**
   * Колбэк при клике на пункт меню
   */
  onChange: (item: DropdownItem) => void;

  /**
   * Пропы для хедера
   */
  headerProps?: React.PropsWithoutRef<'button'>;

  /**
   * Произвольный класс
   */
  className?: string;

  /**
   * Если задан этот проп, то вместо текущего значения дропдауна показывается переданный
   */
  headerContent?: React.ReactNode;

  /**
   * Произвольные классы для внутренних элементов
   */
  classes?: {
    control?: string;
    list?: string;
    item?: string;
  };
}

export const Dropdown = ({
  items,
  selectedId,
  headerProps,
  className,
  classes,
  headerContent,
  onChange
}: DropdownProps) => {
  const wrapperRef = useRef(null);
  const [isOpened, setIsOpened] = useState(false);

  const currentOption = useMemo(() => {
    return items.find((_item: DropdownItem) => {
      return _item.id === selectedId;
    });
  }, [items, selectedId]);

  const onButtonClick = (item: DropdownItem) => {
    setIsOpened(false);
    onChange(item);
  };

  const handleOutsideClick = useCallback(() => {
    setIsOpened(false);
  }, []);

  useClickOutside(wrapperRef, isOpened ? handleOutsideClick : null);

  return (
    <div className={clsx(s.Dropdown, className)} ref={wrapperRef}>
      <button
        onClick={() => setIsOpened(!isOpened)}
        className={clsx(s.Dropdown__control, classes?.control)}
        {...headerProps}
      >
        {headerContent || (
          <>
            {currentOption.title}
            <ArrowDownIcon className={s.Dropdown__icon_arrow} />
          </>
        )}
      </button>

      {isOpened && (
        <div className={clsx(s.Dropdown__list, classes?.list)}>
          {items.map((item, itemIndex) => {
            return (
              <button
                key={itemIndex}
                className={clsx(s.Dropdown__item, {
                  [s.Dropdown__item_selected]: itemIndex === selectedId
                })}
                onClick={() => onButtonClick(item)}
              >
                {item.title}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
