import React from 'react';
import { Helmet } from 'react-helmet';
import { Location } from 'history';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { MainBanner } from '../../common/components/ui/Banner/MainBanner';
import { Card } from '../../common/components/ui/Card/Card';

import BannerBackgroundImage from '../../assets/images/banner_zendikar.png';
import DuskCardImage from '../../assets/images/dusk.jpg';
import NexusOfFateCardImage from '../../assets/images/nexus-of-fate.jpg';

import { SoldCardBanner } from '../../common/components/ui/Banner/SoldCardBanner';
import { useWindowSize } from '../../utils/helpers/useWindowSize';
import { Link } from '../../common/components/ui/Link/Link';
import { CardSize } from '../../common/components/ui/Card/CardImage';
import {
  Button,
  ButtonVariant
} from '../../common/components/ui/Button/Button';
import { CommonStore } from '../../store/CommonStore';
import { STORE_COMMON } from '../../store/stores';
import { NewCards } from './blocks/NewCards';
import { Formats } from './blocks/Formats';
import { Expansions } from './blocks/Expansions';
import layoutStyles from '../_layouts/DefaultLayout.module.scss';

import s from './IndexPage.module.scss';
import buttonStyles from '../../common/components/ui/Button/Button.module.scss';

export interface IndexPageProps {
  location: Location;
}

const firstCardArts = {
  normal: DuskCardImage
};

const secondCardArts = {
  normal: NexusOfFateCardImage
};

export function IndexPage({ location }: IndexPageProps) {
  const { isDesktop, isTabletLand, isMobile } = useWindowSize();
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const soldCardBanner = (
    <div className={s.IndexPage__soldBanner}>
      <SoldCardBanner
        card={
          <Card
            size={isMobile ? CardSize.banner : CardSize.large}
            images={secondCardArts}
          />
        }
        minPrice={100}
        actionButton={
          <Button
            variant={ButtonVariant.primary}
            className={s.IndexPage__actionButton}
            onClick={() => {
              commonStore.setSellCardModal({
                title: 'Продайте ваши карточки',
                description:
                  'Отправьте нам список карточек, которые вы хотите продать. Мы свяжемся с вами в ближайшее время!',
                buttonText: 'Отправить список карточек'
              });
            }}
          >
            Отправить список карт
          </Button>
        }
      />
    </div>
  );

  return (
    <div>
      <Helmet title={'Главная'} />
      <MainBanner
        title={
          isDesktop || isTabletLand ? (
            <span>
              Magic
              <br />
              The Gathering
            </span>
          ) : (
            'Magic The Gathering'
          )
        }
        description="Самая полная в России коллекция карт на продажу и обмен с доставкой в любую точку мира. Собирайте колоду в любимый формат Magic и находите недостающие карты в свою коллекцию."
        actionButton={
          <Link
            to="/cards"
            className={clsx(
              buttonStyles.Button,
              buttonStyles.Button_variant_primary,
              s.IndexPage__actionButton
            )}
          >
            Смотреть каталог
          </Link>
        }
        backgroundImage={<img src={BannerBackgroundImage} alt="Zendikar art" />}
        firstCard={<Card size={CardSize.large} images={firstCardArts} />}
        secondCard={<Card size={CardSize.large} images={secondCardArts} />}
        background="linear-gradient(to bottom, #261222 0%, #1B101B 100%)"
      />

      <div className={layoutStyles.DefaultLayoutWrapper}>
        <div className={layoutStyles.DefaultLayout}>
          <Expansions />
        </div>
      </div>

      <div className={s.IndexPage__gradientBackground}>
        <Formats />
      </div>

      <div
        className={layoutStyles.DefaultLayoutWrapper}
        style={{ paddingBottom: isDesktop ? 158 : 0 }}
      >
        <div className={layoutStyles.DefaultLayout}>
          <NewCards />
          {isDesktop && soldCardBanner}
        </div>
      </div>

      {!isDesktop && soldCardBanner}
    </div>
  );
}
