import React from 'react';
import clsx from 'clsx';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { declinedText } from '@proscom/ui-utils';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button/Button';
import { ReactComponent as RemoveIcon } from '../../../assets/icons/remove.svg';
import { STORE_LOCATION } from '../../../store/stores';
import { hideCardTypesFilter } from '../../../config';
import { useFilterCounter } from '../../../common/hooks/useFilterCounter';
import { FilterFormat } from './blocks/FilterFormat';
import { FilterSets } from './blocks/FilterSets';
import { FilterLang } from './blocks/FilterLang';
import { FilterPrice } from './blocks/FilterPrice';
import { FilterDecorations } from './blocks/FilterDecorations';
import { FilterColors } from './blocks/FilterColors';
import { FilterTypes } from './blocks/FilterTypes';
import { FilterManacost } from './blocks/FilterManacost';
import { FilterArtists } from './blocks/FilterArtists';
import { FilterRarity } from './blocks/FilterRarity';
import { FilterState } from './blocks/FilterState';
import { FilterInSale } from './blocks/FilterInSale';
import s from './FilterCard.module.scss';

// hide нужен только для планшетов и мобилок, чтобы показать, что фильтры закрываются, но еще удалять их со страницы не нужно
export type FilterCardState = 'visible' | 'hiding' | 'hidden';

export interface FilterCardProps {
  state: FilterCardState;
  onApply?: () => void;
  filterRef?: React.RefObject<HTMLDivElement>;
}

export const FilterCard = ({
  state,
  onApply,
  filterRef,
  ...props
}: FilterCardProps) => {
  const { isMobile, isTablet } = useWindowSize();
  const [locationState, locationStore] =
    useStore<LocationStore>(STORE_LOCATION);

  const appliedFiltersCounter = useFilterCounter(locationState.query);

  return (
    <div
      className={clsx(s.FilterCard, {
        [s.FilterCard_tablet]: isTablet,
        [s.FilterCard_visible]: state === 'visible',
        [s.FilterCard_hide]: state === 'hiding'
      })}
      ref={filterRef}
    >
      <div className={s.FilterCard__header}>Фильтры</div>
      {appliedFiltersCounter > 0 && (
        <Button
          className={s.FilterCard__clear}
          to={'/cards'}
          size={ButtonSize.small}
        >
          <RemoveIcon />
          Сбросить все
        </Button>
      )}

      <FilterInSale />
      <FilterState />
      <FilterFormat />
      <FilterSets />
      <FilterDecorations />
      <FilterRarity />
      <FilterLang />
      <FilterColors />
      <FilterManacost />
      <FilterArtists />
      <FilterPrice />
      {!hideCardTypesFilter && <FilterTypes />}

      {isMobile && (
        <div className={s.FilterCard__footer}>
          <div className={s.FilterCard__footerLabel}>
            Применено
            <div className={s.FilterCard__footerCounter}>
              {declinedText(
                appliedFiltersCounter,
                '$n фильтр',
                '$n фильтра',
                '$n фильтров'
              )}
            </div>
          </div>
          <div className={s.FilterCard__footerAction}>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.small}
              fullWidth={true}
              onClick={() => onApply?.()}
            >
              Применить
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
