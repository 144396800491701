import React from 'react';
import clsx from 'clsx';

import s from './Tag.module.scss';

export enum TagSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum TagVariant {
  normal = 'normal',
  primary = 'primary',
  secondary = 'secondary',
  amount = 'amount',
  foil = 'foil'
}

export type TagProps<ComponentType extends React.ElementType> = {
  /**
   * Размер тега ("small", "medium", "large")
   */
  size?: TagSize;

  /**
   * Стиль тега ("normal", "primary", "secondary", "amount", "foil")
   */
  variant?: TagVariant;

  /**
   * Значение счетчика у тега
   */
  amount?: number;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Произвольные классы для элементов компонента
   */
  classes?: {
    content?: string;
    amount?: string;
  };
} & Omit<React.ComponentProps<ComponentType>, 'size'>;

export function Tag<ComponentType extends React.ElementType>({
  size = TagSize.small,
  variant = TagVariant.normal,
  children,
  amount,
  className,
  classes,
  ...props
}: TagProps<ComponentType>) {
  return (
    <div
      className={clsx(
        s.Tag,
        className,
        s[`Tag_size_${size}`],
        s[`Tag_variant_${variant}`]
      )}
      {...props}
    >
      <span className={classes?.content}>{children}</span>
      {variant === TagVariant.amount && amount ? (
        <span className={clsx(s.Tag__amount, classes?.amount)}>
          {amount > 0 ? amount : 0}
        </span>
      ) : null}
    </div>
  );
}

Tag.Size = TagSize;
Tag.Variant = TagVariant;
