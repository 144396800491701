import { stringifyObjectShallow } from '../object';

export class UnexpectedResultError extends Error {
  name = 'UnexpectedResultError';

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, UnexpectedResultError.prototype);
  }
}

export function ensureTrue<T>(
  value: T | null | undefined,
  message?: string
): T {
  if (!value) {
    throw new UnexpectedResultError(message);
  }
  return value;
}

export function getShapeErrorMessage<T extends { [key: string]: any }>(
  value: T,
  keys: (keyof T)[],
  message: string
): string {
  const targetShape = `{ ${keys.join(', ')} }`;
  const currentShape = stringifyObjectShallow(value);
  return (
    message + `. Expected shape \`${targetShape}\`, but got \`${currentShape}\``
  );
}

export function ensureKeyTrue<
  T extends { [key: string]: any },
  K extends keyof T
>(object: T, key: K, message: string): T[K] {
  return ensureTrue(object[key], getShapeErrorMessage(object, [key], message));
}
