import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react';
import { AxiosResponse } from 'axios';
import { apiUrl } from '../../config';
import { checkForFormValidationError } from '../../utils/log';
import { ApiResponse } from '../interfacesResponse';
import { ensureTrue } from '../../utils/error/UnexpectedResultError';

export interface IOrderUpdateVars {
  delivery_method_id?: string;
  name?: string;
  phone?: string;
  email?: string;
  country?: string;
  city?: string;
  address?: string;
  postcode?: string;
  apartment_number?: string;
  floor_number?: string;
  entrance_number?: string;
  intercom_code?: string;
  comment?: string;
  pay_personal?: boolean;
}

export interface IOrderUpdateResponse extends ApiResponse {
  success: boolean;
}

export function useOrderUpdate(id: number, secret: string) {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (data: IOrderUpdateVars): Promise<IOrderUpdateResponse> => {
      const methodUrl = `/orders/${id}`;
      return client
        .put(apiUrl + methodUrl, data, {
          params: {
            secret: secret
          }
        })
        .then((response: AxiosResponse<IOrderUpdateResponse>) => {
          const t = response.data;
          ensureTrue(t.success, `Call to \`${methodUrl}\` was not successful`);
          return t;
        })
        .catch(
          (err: unknown): IOrderUpdateResponse => {
            // Если ошибка валидации, то перевыбрасываем её
            checkForFormValidationError(err);

            // Все остальные ошибки никак не обрабатываем
            throw err;
          }
        );
    },
    {
      finishedTimeout: 5000,
      singleton: true
    }
  );
}
