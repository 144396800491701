import { AxiosRequestConfig } from 'axios';
import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { cleanObject } from '@proscom/ui-utils';
import { skipIf, skipIfNull } from '@proscom/prostore';
import { useMemo } from 'react';
import { apiUrl } from '../../config';
import {
  CardData,
  PaginationItem,
  PaginationMeta,
  paginationMetaEmpty
} from '../interfacesResponse';
import { BaseRequestConfig } from '../BaseRequestConfig';
import { CardsQueryResponse } from './useCards';

const query: BaseRequestConfig = {
  url: `${apiUrl}/meta-cards`,
  method: 'get'
};

export interface PagesCountResponse {
  total: number;
  userKey: string;
  requestKey: string;
}

export interface PagesCountQueryVars {
  sort?: string;
  include?: 'originalCard' | 'originalCard.originalSet' | string;
  append?: 'is_showcase' | string;
  filter?: {
    id?: string;
    is_foil?: string;
    search?: string;
    rarity?: string;
    colors?: string;
    cmc?: string;
    showcase?: string;
    price_from?: number;
    price_to?: number;
    artists?: string;
    format?: string;
    type?: string;
    state?: string;
    set?: string;
    lang?: string;
  };
}

const queryOptions: IUseAxiosQueryOptions<
  PagesCountQueryVars,
  PagesCountResponse
> = {
  query,
  mapVariables: (variables) => ({
    params: {
      ...variables,
      onlyCount: true
    }
  }),
  skipQuery: skipIfNull(null)
};

export function usePagesCount(variables?: PagesCountQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables: variables ? cleanObject(variables) : null
  });
}
