import React, { SVGProps } from 'react';
import { ReactComponent as LogoIcon } from '../../../../assets/icons/logo.svg';
import { Link, LinkProps } from '../Link/Link';

export interface LogoProps extends LinkProps {
  iconProps?: SVGProps<SVGSVGElement>;
}

export function Logo({ iconProps, ...props }: LogoProps) {
  return (
    <Link to="/" {...props}>
      <LogoIcon {...iconProps} />
    </Link>
  );
}
