import React from 'react';
import clsx from 'clsx';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/cross.svg';
import { Link } from '../../../../common/components/ui/Link/Link';
import { STORE_LOCATION } from '../../../../store/stores';
import s from './StepBlock.module.scss';

export const steps = [
  { step: 1, annotation: 'Получатель' },
  { step: 2, annotation: 'Доставка' },
  { step: 3, annotation: 'Оплата' },
  { step: 4, annotation: 'Подтверждение' }
];

export type StepsType = 0 | 1 | 2 | 3 | 4;

export interface StepBlockProps {
  step: number;
  error?: number[];
  completed?: number[];
  enabledSteps?: StepsType; //шаг, до которого можно переключать шаги (включительно). Если 0, то переключать нельзя
}

export function StepBlock({
  step,
  error,
  completed,
  enabledSteps = 0
}: StepBlockProps) {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );

  return (
    <div className={s.StepBlock}>
      {steps.map((item, key) => {
        const isCompletedStep =
          item.step < step || completed?.some((i) => i === item.step);
        const isErrorStep = error ? error?.some((i) => i === item.step) : false;
        const isLink = item.step <= enabledSteps;

        function handleLinkClick() {
          locationStore.changeQuery({ step: item.step });
        }

        return (
          <Link
            onClick={() => (isLink ? handleLinkClick() : null)}
            key={key}
            className={clsx(s.StepBlock__step, {
              [s.StepBlock_active]:
                item.step <= step || item.step <= enabledSteps,
              [s.StepBlock_current]: item.step === step,
              [s.StepBlock_completed]: isCompletedStep || isErrorStep,
              [s.StepBlock__step_asLink]: isLink
            })}
          >
            <div className={s.StepBlock__stepCount}>
              {isErrorStep ? (
                <ErrorIcon className={s.StepBlock__errorIcon} />
              ) : isCompletedStep ? (
                <CheckIcon />
              ) : (
                item.step
              )}
            </div>
            <div className={s.StepBlock__stepAnnotation}>{item.annotation}</div>
          </Link>
        );
      })}
    </div>
  );
}
