import React, { useEffect, useRef } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { Textarea, TextareaProps } from '../Textarea';
import { Input } from '../Input';

export interface ControlledTextareaProps extends TextareaProps {
  name: string;
  registerOptions?: RegisterOptions;
}

export function ControlledTextarea({
  name,
  onChange,
  registerOptions,
  ...props
}: ControlledTextareaProps) {
  const { register, setValue, unregister, errors, watch } = useFormContext<{
    [name: string]: string;
  }>();

  const fieldData = watch(name) || '';

  useEffect(() => {
    register({ name, ...registerOptions });
    return () => unregister(name);
  }, [name, register, registerOptions, unregister]);

  return (
    <>
      <Textarea
        name={name}
        value={fieldData}
        invalid={!!errors[name]}
        errorText={errors[name] && errors[name].message}
        onChange={(value) => {
          setValue(name, value);
          onChange && onChange(value);
        }}
        {...props}
      />
    </>
  );
}
