import React from 'react';
import clsx from 'clsx';
import { CardData } from '../../../../../store/interfacesResponse';
import { useWindowSize } from '../../../../../utils/helpers/useWindowSize';
import { makeTags } from '../../../../../utils/data';
import { CardSize } from '../CardImage';
import { Card } from '../Card';
import { Tag } from '../../Tag/Tag';
import { CardSetName } from '../../Set/CardSetName';
import { Link } from '../../Link/Link';
import { CardHelper } from '../../../../../utils/helpers/Card';
import { OriginalCardHelper } from '../../../../../utils/helpers/OriginalCard';
import { CardBlockProps } from './CardBlock';
import s from './CardBlock.module.scss';

export interface OrderCardDetailedBlockProps
  extends Omit<CardBlockProps, 'data'> {
  data: CardData;
  actions?: React.ReactNode;
}

export function OrderCardDetailedBlock({
  data,
  isFullWidth,
  actions
}: OrderCardDetailedBlockProps) {
  const { isMobile, isTablet, isTabletLand } = useWindowSize();
  const originCard = data?.original_card;

  const tags = makeTags(data);

  let cardImageSize = CardSize.small;
  if (isTabletLand || isTablet) {
    cardImageSize = CardSize.xsmall;
  }

  if (isMobile) {
    cardImageSize = CardSize.xxsmall;
  }

  return (
    <div
      className={clsx(s.CardBlock, {
        [s.CardBlock__isFullWidth]: isFullWidth
      })}
    >
      <div className={s.CardBlock__cardContainer}>
        <Card
          foil={data.is_foil}
          className={s.CardBlock__image}
          images={OriginalCardHelper.getImageUris(originCard)}
          size={cardImageSize}
          enablePreview
        />

        <div className={s.CardBlock__innerTagsList}>
          {tags.map((tag, key) => (
            <Tag key={key} variant={tag.variant}>
              {tag.label}
            </Tag>
          ))}
        </div>
      </div>

      <CardSetName originSet={data.original_card.original_set} />

      <div className={s.CardBlock__titleBlock}>
        <Link to={CardHelper.getUrl(data)} className={s.CardBlock__title}>
          {CardHelper.getName(data)}
        </Link>
        <div className={s.CardBlock__outerTagsList}>
          {tags.map((tag, key) => (
            <Tag key={key} variant={tag.variant}>
              {tag.label}
            </Tag>
          ))}
        </div>
      </div>

      <div className={s.CardBlock__actions}>{actions}</div>
    </div>
  );
}
