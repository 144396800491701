import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '../../Checkbox/Checkbox';
import s from './ControlledCheckbox.module.scss';

export function ControlledCheckbox({
  name,
  onChange,
  children,
  ...props
}: CheckboxProps) {
  const { register, unregister, setValue, watch } = useFormContext<{
    [name: string]: boolean;
  }>();
  const fieldData = watch(name);

  useEffect(() => {
    register({ name });
    return () => unregister(name);
  }, [name, register, unregister]);

  return (
    <Checkbox
      className={s.OrderDetails__policy}
      name={name}
      checked={fieldData}
      onChange={(checked, e) => {
        setValue(name, checked);
        onChange?.(checked, e);
      }}
      {...props}
    >
      {children}
    </Checkbox>
  );
}
