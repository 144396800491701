import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export function useTabs<Tab, Value>(
  tabs: Tab[],
  valueFn: (tab: Tab) => Value,
  defaultTab?: Tab,
  onClick?: (value: Value) => void
) {
  const [selected, setSelected] = useState(
    (defaultTab && valueFn(defaultTab)) || valueFn(tabs[0])
  );
  const getTabProps = (value: Value) => ({
    value,
    active: selected === value,
    onClick: () => {
      setSelected(value);
      onClick && onClick(value);
    }
  });
  return [selected, getTabProps, setSelected] as const;
}

export function useFormTabs<Tab, Value>(
  fieldName: string,
  tabs: Tab[],
  valueFn: (tab: Tab) => Value,
  defaultTab?: Tab
) {
  const { register, setValue, unregister, watch } = useFormContext();
  const fieldData = watch<string, Value>(
    fieldName,
    defaultTab ? valueFn(defaultTab) : valueFn(tabs[0])
  );

  const setFieldValue = useCallback(
    (value: Value) => {
      setValue(fieldName, value);
    },
    [fieldName, setValue]
  );

  useEffect(() => {
    register({ name: fieldName });
    return () => unregister(fieldName);
  }, [fieldName, register, unregister]);

  const getTabProps = (value: Value) => ({
    value,
    active: fieldData === value,
    onClick: () => {
      setFieldValue(value);
    }
  });

  return [fieldData, getTabProps, setFieldValue] as const;
}
