import React, { useCallback } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import { useWindowSize } from '../../../../utils/helpers/useWindowSize';

export interface HorizontalScrollProps {
  // Реф на блок со скроллбаром
  scrollbarRef: React.RefObject<Scrollbar>;

  // Ширина родительского контейнера
  contentWidth: number;

  // Ширина, в пределах которой блок отображается
  parentWidth: number;

  // Высота скроллящегося блока
  height: number;

  // Содердимое скроллящегося блока
  children: React.ReactNode;

  // Инвертированные стили
  invert?: boolean;

  // Колбэк, когда горизонтальный скроллбар появляется/скрывается
  onChangeScroll?: (bool) => void;
}

export const HorizontalScroll = ({
  scrollbarRef,
  contentWidth,
  parentWidth,
  children,
  invert = false,
  height,
  onChangeScroll = undefined
}: HorizontalScrollProps) => {
  const { isMobile } = useWindowSize();

  const onUpdate = useCallback(
    (e) => {
      onChangeScroll?.(e.scrollXPossible);
    },
    [onChangeScroll]
  );

  return (
    <Scrollbar
      ref={
        scrollbarRef as any /* Внутри компонента что-то не так с типами, поэтому пришлось превращать в any */
      }
      style={{
        width: parentWidth,
        left: (parentWidth - contentWidth) / -2, //`calc((100% - ${contentWidth}px) / -2)`,
        height: height
      }}
      onUpdate={onUpdate}
      trackXProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;

          const trackStyles = {
            ...style,
            width: isMobile ? 170 : 560,
            left: isMobile ? 'calc(50% - 86px)' : `calc(50% - 280px)`,
            height: '4px',
            borderRadius: '4px',
            backgroundColor: `rgba(0, 25, 44, ${invert ? 0.6 : 0.1})`
          };

          return <div {...restProps} ref={elementRef} style={trackStyles} />;
        }
      }}
      thumbXProps={{
        renderer: (props) => {
          const { elementRef, style, ...restProps } = props;
          const thumbStyles = {
            ...style,
            backgroundColor: invert ? 'white' : '#FE724C'
          };
          return <div {...restProps} ref={elementRef} style={thumbStyles} />;
        }
      }}
    >
      {children}
    </Scrollbar>
  );
};
