import React, { MouseEventHandler, useState } from 'react';
import { Link } from '../Link/Link';
import { OriginalSet } from '../../../../store/interfacesResponse';
import s from '../SetsPopup/SetsPopup.module.scss';

export interface PopupSetNameProps {
  set: OriginalSet;
  onClick?: (e: any) => void;
  format?: string;
}

export function PopupSetName({
  set,
  onClick,
  format,
  ...props
}: PopupSetNameProps) {
  const [isValidImage, setIsValidImage] = useState(true);

  return (
    <Link
      className={s.SetsPopup__expansion}
      key={set.id}
      to={`/cards?sets=${set.id}` + (format ? '&format=' + format : '')}
      onClick={onClick}
      {...props}
    >
      {isValidImage ? (
        <img
          src={set.icon_svg_uri}
          className={s.SetsPopup__expansionIcon}
          alt={set.name}
          onError={() => setIsValidImage(false)}
        />
      ) : (
        <div className={s.SetsPopup__placeholderIcon} />
      )}
      <div className={s.SetsPopup__expansionTitle}>{set.name}</div>
    </Link>
  );
}
