import React from 'react';
import clsx from 'clsx';
import { ReactComponent as GridIcon } from '../../../../assets/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/list.svg';

import s from './Switcher.module.scss';

export enum SwitcherOption {
  grid = 'grid',
  list = 'list'
}

export interface SwitcherProps {
  /**
   * Текущая вкладка
   */
  current: SwitcherOption;

  /**
   * Коллбек при изменении вкладки
   * @param current
   */
  onChange: (current: SwitcherOption) => void;

  /**
   * Произвольный класс
   */
  className?: string;
}

export const Switcher = ({
  current,
  onChange,
  className,
  ...props
}: SwitcherProps) => {
  const onClickHandler = (targetTab) => {
    if (targetTab !== current) {
      onChange(targetTab);
    }
  };

  return (
    <div className={clsx(s.Switcher, className)} {...props}>
      <button
        className={clsx(s.Switcher__option, {
          [s.Switcher__option_active]: current === SwitcherOption.grid
        })}
        onClick={() => onClickHandler(SwitcherOption.grid)}
        title="Сеткой"
      >
        <GridIcon />
      </button>

      <button
        className={clsx(s.Switcher__option, {
          [s.Switcher__option_active]: current === SwitcherOption.list
        })}
        onClick={() => onClickHandler(SwitcherOption.list)}
        title="Списком"
      >
        <ListIcon />
      </button>
    </div>
  );
};
