import React from 'react';
import clsx from 'clsx';
import { Link, LinkProps } from '../Link/Link';
import { ReactComponent as RightArrow } from '../../../../assets/icons/arrow_right.svg';
import s from './BackLink.module.scss';

export interface BackLinkProps extends Omit<LinkProps, 'href'> {
  title: string;
  className?: string;
}

export function BackLink({ className, title, ...props }: BackLinkProps) {
  return (
    <nav className={clsx(s.BackLink, className)}>
      <Link className={s.BackLink__link} {...props}>
        <RightArrow className={s.BackLink__arrow} />
        {title}
      </Link>
    </nav>
  );
}
