import React from 'react';
import clsx from 'clsx';
import s from './CardInfoLoading.module.scss';
import cardInfoStyles from './CardInfo.module.scss';

export function CardInfoLoading() {
  return (
    <div className={clsx(cardInfoStyles.CardInfo, s.CardInfoLoading)}>
      <div
        className={clsx(
          cardInfoStyles.CardInfo__image,
          s.CardInfoLoading__imageContainer
        )}
      >
        <div className={s.CardInfoLoading__image} />
      </div>
      <div className={s.CardInfo__contentTitle}>
        <div className={cardInfoStyles.CardInfo__set}>
          <div className={s.CardInfoLoading__setName} />
        </div>
        <div className={cardInfoStyles.CardInfo__title}>
          <div className={s.CardInfoLoading__title} />
        </div>
        <div className={cardInfoStyles.CardInfo__tags}>
          <div className={s.CardInfoLoading__tag} />
          <div className={s.CardInfoLoading__tag} />
        </div>
      </div>

      <div className={cardInfoStyles.CardInfo__contentCard}>
        <div className={s.CardInfoLoading__stateContainer}>
          <div className={s.CardInfoLoading__state} />
          <div className={s.CardInfoLoading__state} />
          <div className={s.CardInfoLoading__state} />
          <div className={s.CardInfoLoading__state} />
          <div className={s.CardInfoLoading__state} />
        </div>
        <div className={cardInfoStyles.CardInfo__availability}>
          <div className={s.CardInfoLoading__textRow} />
        </div>
        <div className={cardInfoStyles.CardInfo__price}>
          <div className={s.CardInfoLoading__price} />
        </div>
        <div className={cardInfoStyles.CardInfo__addCard}>
          <div className={s.CardInfoLoading__button} />
        </div>
        <div className={cardInfoStyles.CardInfo__annotation}>
          <div className={s.CardInfoLoading__textRow} />
          <div className={s.CardInfoLoading__textRow} />
          <div className={s.CardInfoLoading__textRow} />
          <div className={s.CardInfoLoading__textRow} />
          <div className={s.CardInfoLoading__textRow} />
        </div>
      </div>
    </div>
  );
}
