import React from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import { queryLoader } from '../../../../common/components/utils/queryLoader';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { URL_KEY_TYPES } from '../../../../store/urlKeys';
import { FilterCardBlock } from '../FilterCardBlock';
import { useCardTypes } from '../../../../store/query/useCardTypes';
import { STORE_LOCATION } from '../../../../store/stores';
import { changeListFilterQuery } from '../../../../common/components/utils/filter';

export const FilterTypes = React.memo(function FilterTypes() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_TYPES
  ]);

  const cardTypesQuery = useCardTypes();
  const cardTypesData = cardTypesQuery.state.data;

  return (
    <FilterCardBlock header="Тип карты">
      {queryLoader(cardTypesQuery, { centered: true }) || (
        <CheckboxList direction={CheckboxListDirection.vertical}>
          {cardTypesData.map((option) => {
            return (
              <Checkbox
                key={option.id}
                checked={query[URL_KEY_TYPES]?.indexOf(option.name) > -1}
                onChange={(state) =>
                  changeListFilterQuery(
                    query,
                    locationStore,
                    URL_KEY_TYPES,
                    option.name,
                    state
                  )
                }
              >
                {option.name}
              </Checkbox>
            );
          })}
        </CheckboxList>
      )}
    </FilterCardBlock>
  );
});
