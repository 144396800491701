import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { useClickOutside } from '@proscom/ui-react';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { Link, LinkProps } from '../Link/Link';
import { useSets } from '../../../../store/query/useSets';
import { FORMATS, SETS_TYPES } from '../../../../constants';
import { SpinnerSize } from '../Spinner/Spinner';
import { queryLoader } from '../../utils/queryLoader';
import { STORE_LOCATION } from '../../../../store/stores';
import { PopupSetName } from '../Set/PopupSetName';
import { OriginalSet } from '../../../../store/interfacesResponse';
import buttonStyles from '../Button/Button.module.scss';
import s from './SetsPopup.module.scss';

export interface Set {
  title: string;
  icon?: string;
}

export interface SetsGroup {
  title: string;
  expansions: Set[];
}

export interface Format {
  id: number;
  title: string;
  groups: SetsGroup[];
}

export interface ExpansionsProps {
  onFormatClick?: (selectedSet) => void;
  onOutsideClick?: () => void;
  currentFormatId?: number;
  setLinkPropsFn?: (OriginalSet) => LinkProps;
}

export function groupSetsByTypes(setsList: OriginalSet[]) {
  const types: Record<string, OriginalSet[]> = {};

  setsList.forEach((set) => {
    if (!types[set.set_type]) {
      types[set.set_type] = [];
    }

    types[set.set_type].push(set);
  });

  return types;
}

export const SetsPopup = ({
  currentFormatId,
  onFormatClick,
  onOutsideClick,
  setLinkPropsFn
}: ExpansionsProps) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );

  const currentFormat = useMemo(() => {
    if (currentFormatId === -1) {
      return FORMATS[0];
    }

    return FORMATS.find((format) => {
      return format.id === currentFormatId;
    });
  }, [currentFormatId]);

  const setsQuery = useSets({
    filter: { format: currentFormat.value }
  });
  const setsData = setsQuery.state.data;

  const groupedByTypesSets = useMemo(() => {
    if (!setsData) {
      return null;
    }

    return groupSetsByTypes(setsData);
  }, [setsData]);

  const formatTypes = groupedByTypesSets && [
    ...SETS_TYPES,
    ...Object.keys(groupedByTypesSets).filter(
      (setsType) => SETS_TYPES.indexOf(setsType) === -1
    )
  ];

  const listsRef = useRef(null);
  const blockRef = useRef(null);

  useClickOutside(blockRef, onOutsideClick ? onOutsideClick : null);

  useEffect(() => {
    listsRef.current.scrollTop = 0;
  }, [currentFormatId]);

  return (
    <div className={s.SetsPopup}>
      <div className={s.SetsPopup__content} ref={blockRef}>
        <div className={s.SetsPopup__setList}>
          <div className={s.SetsPopup__header}>Формат игры</div>

          {FORMATS.map((format) => (
            <button
              key={format.id}
              className={clsx(s.SetsPopup__set, {
                [s.SetsPopup__set_selected]: format.id === currentFormat.id
              })}
              onClick={() => onFormatClick?.(format.id)}
            >
              {format.name}
            </button>
          ))}
        </div>
        <div className={s.SetsPopup__lists} ref={listsRef}>
          <div className={s.SetsPopup__header}>Издания</div>
          {queryLoader(setsQuery, { size: SpinnerSize.medium }) || (
            <div className={s.SetsPopup__groupsList}>
              {setsData &&
                formatTypes.map(
                  (formatType, formatTypeId) =>
                    groupedByTypesSets[formatType] && (
                      <div className={s.SetsPopup__group} key={formatTypeId}>
                        <div className={s.SetsPopup__groupTitle}>
                          {formatType.replace('_', ' ')}
                        </div>
                        <div className={s.SetsPopup__setsGrid}>
                          {groupedByTypesSets[formatType].map((set) => {
                            return (
                              <PopupSetName
                                key={set.id}
                                set={set}
                                onClick={() => onOutsideClick()}
                                format={currentFormat.value}
                                {...setLinkPropsFn?.(set)}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )
                )}
            </div>
          )}
          <Link
            to="/cards"
            onClick={onOutsideClick}
            className={clsx(
              buttonStyles.Button,
              buttonStyles.Button_variant_primary,
              buttonStyles.Button_size_small,
              s.SetsPopup__allCards
            )}
          >
            Перейти в каталог
          </Link>
        </div>
      </div>
    </div>
  );
};
