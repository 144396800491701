import { AxiosRequestConfig } from 'axios';
import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { skipIfNull } from '@proscom/prostore';
import { apiUrl } from '../../config';
import { OriginalSet } from '../interfacesResponse';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  url: `${apiUrl}/sets`,
  method: 'get',
  useCache: true
};

export interface SetsQueryVars {
  filter?: {
    format?: string;
  };
  fields?: {
    original_sets?: string;
  };
}

const queryOptions: IUseAxiosQueryOptions<
  SetsQueryVars | null,
  OriginalSet[]
> = {
  query,
  mapData: (result) => result.data,
  mapVariables: (variables) => ({
    params: {
      ...variables,
      fields: {
        original_sets: 'id,name,icon_svg_uri,set_type'
      }
    }
  }),
  skipQuery: skipIfNull(null)
};

export function useSets(variables: SetsQueryVars = {}) {
  return useAxiosQuery({
    queryOptions,
    variables: variables
  });
}
