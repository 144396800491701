import { useAsyncOperation } from '@proscom/prostore-react';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { apiUrl } from '../../config';
import { CardConditionEnum } from '../../common/components/ui/Card/CardStatusEnum';
import { ensureKeyTrue } from '../../utils/error/UnexpectedResultError';
import { runIfAxiosError, toastError } from '../../utils/log';
import { AxiosApiResponse, CardData } from '../interfacesResponse';
import { OrderStatusEnum, PaymentStatusEnum } from '../../constants';

export interface IOrderQueryItem {
  id: number;
  state: CardConditionEnum;
  amount: number;
}

export interface IOrderQueryVars {
  cards: IOrderQueryItem[];
}

export interface IPayment {
  amount: number;
  created_at: string;
  details: any;
  id: number;
  link: string;
  provider: string;
  provider_order_id: string;
  status: PaymentStatusEnum;
  target_id: number;
  target_type: string;
  timeout: string;
  updated_at: string;
}

export interface IOrderCreateResponse {
  card_id: number;
  id: number;
  address: string;
  created_at: string;
  updated_at: string;
  delivery_method?: {
    code: string;
  };
  delivery_method_id: number;
  delivery_method_price: number;
  name: string;
  phone: string;
  email: string;
  country: string;
  city: string;
  postcode: string;
  apartment_number: string;
  floor_number: string;
  entrance_number: string;
  intercom_code: string;
  comment: string;
  delivery_price: number;
  card_price: number;
  total_price: number;
  secret: string;
  state_info: {
    [key in CardConditionEnum]: {
      price: number;
      quantity: number;
    };
  };
  order_cards: IOrderCreateResponse[];
  status: OrderStatusEnum;
  payment?: IPayment;
  card: CardData;
}

export function useOrderCreate() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (data: IOrderQueryVars): Promise<IOrderCreateResponse> => {
      return client
        .post(`${apiUrl}/orders`, data)
        .then((response: AxiosApiResponse<IOrderCreateResponse>) => {
          // Проверяем, что бекенд вернул ожидаемые данные
          return ensureKeyTrue(
            response.data,
            'data',
            `Call to \`/orders\` returned bad shape`
          );
        })
        .catch((e: unknown) => {
          let info = '';
          // Пойманная ошибка может быть какая угодно, не только из Axios
          runIfAxiosError(e, (err) => {
            // Для этого запроса бекенд возвращает 422 только когда
            // пытаемся купить больше карточек, чем на самом деле есть
            if (err.response.status === 422) {
              info = 'Часть товаров уже недоступна. Обновите страницу';
            }
          });
          // Все ошибки выводим как тост
          toastError(e, `Ошибка оформления заказа. ${info}`);
          // Перевыбрасываем ошибку, чтобы можно было дополнительно обработать снаружи
          throw e;
        });
    },
    {
      finishedTimeout: 5000,
      singleton: true
    }
  );
}
