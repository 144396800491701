import React, { ReactNode, useEffect, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as RotateIcon } from '../../../../assets/icons/rotate.svg';
import { Popup } from '../Popup/Popup';
import { Button } from '../Button/Button';
import { CommonStore } from '../../../../store/CommonStore';
import { STORE_COMMON } from '../../../../store/stores';
import s from './CardPreviewModal.module.scss';

export interface CardPreviewModalProps {
  card: ReactNode;
  onClose: (e: React.MouseEvent) => void;
}

export interface CardPreviewModalContent {
  card: ReactNode;
}

export const CardPreviewModal = () => {
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    setRotation(0);
  }, [commonStoreState.previewCardModal]);

  return (
    <Popup
      isOpen={!!commonStoreState.previewCardModal}
      onClose={() => {
        if (commonStoreState.previewCardModal) {
          commonStore.setPreviewCardModal(null);
        }
      }}
      className={s.CardPreviewModal}
      overlayClassName={s.CardPreviewModal__overlay}
      classes={{
        closeButton: s.CardPreviewModal__closeButton
      }}
    >
      <div
        style={{
          transform: `rotate(${rotation}deg) scale(${
            rotation % 180 !== 0 ? 0.8 : 1
          })`
        }}
        className={s.CardPreviewModal__content}
      >
        {commonStoreState.previewCardModal?.card}
      </div>

      <Button
        className={s.CardPreviewModal__rotateButton}
        onClick={() => setRotation(rotation + 90)}
      >
        <RotateIcon />
      </Button>
    </Popup>
  );
};
