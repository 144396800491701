import {
  IQueryValue,
  LocationStore,
  useLocationQuery as useProstoreLocationQuery
} from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { Location } from 'history';
import { TransformedQueryParams } from '../../store/urlKeys';
import { STORE_LOCATION } from '../../store/stores';

/**
 * Переопреляем состояние LocationStore с более точным типом TransformedQueryParams
 */
export interface LocationStoreState {
  /**
   * Текущий Location, как в react-router
   */
  location: Location | null;

  /**
   * Десериализованные значения квери-параметров
   */
  query: TransformedQueryParams | null;

  /**
   * Сериализованные значения квери-параметров
   */
  rawQuery: { [key: string]: IQueryValue } | null;
}

export function useLocationQuery<
  Keys extends readonly string[] = readonly string[]
>(keys: Keys) {
  return useProstoreLocationQuery<TransformedQueryParams, Keys>(
    STORE_LOCATION,
    keys
  );
}

export function useLocationStore() {
  const [state, store] = useStore<LocationStore>(STORE_LOCATION);
  return [state as LocationStoreState, store] as const;
}
