import React from 'react';

import clsx from 'clsx';
import { Link, LinkProps } from '../Link/Link';
import StarDecoration from '../../../../assets/images/star.svg';
import EllipseDecoration from '../../../../assets/images/ellipse.svg';
import s from './ExpansionCard.module.scss';

export enum ExpansionCardDecorations {
  variant1 = 1,
  variant2 = 2,
  variant3 = 3,
  variant4 = 4,
  variant5 = 5
}

export interface ExpansionCardProps extends LinkProps {
  title: string;
  image: React.ReactNode;
  decoration?: ExpansionCardDecorations;
}

export const ExpansionCard = ({ title, image, decoration, ...props }) => {
  return (
    <Link
      className={clsx(
        s.ExpansionCard,
        s[`ExpansionCard_decoration_${decoration}`]
      )}
      {...props}
    >
      <div className={s.ExpansionCard__image}>{image}</div>
      <img
        src={
          decoration === ExpansionCardDecorations.variant2 ||
          decoration === ExpansionCardDecorations.variant4
            ? EllipseDecoration
            : StarDecoration
        }
        alt=""
        className={s.ExpansionCard__decoration}
      />
      <div className={s.ExpansionCard__title}>{title}</div>
      <div className={s.ExpansionCard__action}>Смотреть</div>
    </Link>
  );
};
