import React, { ReactElement, useMemo } from 'react';
import { sumBy } from 'lodash-es';
import clsx from 'clsx';
import { IDeliveryMethod } from '../../../store/query/useOrderDeliveryMethod';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { useFormTabs } from '../../../utils/helpers/useTabs';
import { TabButton } from '../../../common/components/ui/Button/TabButton/TabButton';
import { CardButton } from '../../../common/components/ui/Button/CardButton/CardButton';
import { Button } from '../../../common/components/ui/Button/Button';
import { OrderLayout } from '../../../common/components/layout/OrderLayout/OrderLayout';
import { BackLink } from '../../../common/components/ui/BackLink/BackLink';
import {
  IOrderItem,
  OrderDetails
} from '../../../common/components/ui/Order/OrderDetails';
import { IOrderDetails, OrderDetailsEnum } from '../../../utils/data';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as TruckIcon } from '../../../assets/icons/truck.svg';
import { ReactComponent as MapPinIcon } from '../../../assets/icons/map-pin.svg';
import { ReactComponent as BoxIcon } from '../../../assets/icons/package.svg';
import { OrderHelper } from '../../../utils/helpers/OrderHelper';
import { StepBlock, StepsType } from './StepBlock/StepBlock';
import {
  DeliveryBoxberry,
  DeliveryCourier,
  DeliveryInternational,
  DeliveryPickup,
  RussianPost
} from './DeliveryForms';
import s from '../common.module.scss';

export enum DeliveryTypeEnum {
  russia = 'russia',
  international = 'international'
}

export enum DeliveryEnum {
  russia = 'local_mail',
  boxberry = 'boxberry',
  courier = 'local_courier',
  pickup = 'local_pickup'
}

export enum DeliveryInternationalEnum {
  emc = 'emc',
  dhl = 'dhl'
}

const deliveryIcon: {
  [key in DeliveryEnum | DeliveryInternationalEnum]: ReactElement;
} = {
  [DeliveryEnum.russia]: <MailIcon />,
  [DeliveryEnum.boxberry]: <BoxIcon />,
  [DeliveryEnum.courier]: <TruckIcon />,
  [DeliveryEnum.pickup]: <MapPinIcon />,
  [DeliveryInternationalEnum.emc]: <MailIcon />,
  [DeliveryInternationalEnum.dhl]: <BoxIcon />
};

interface ITabOption {
  value: string;
  title: string;
}

const tabOptions: ITabOption[] = [
  {
    title: 'По России',
    value: DeliveryTypeEnum.russia
  },
  {
    title: 'Международная',
    value: DeliveryTypeEnum.international
  }
];
const componentsDelivery = {
  [DeliveryEnum.russia]: RussianPost,
  [DeliveryEnum.boxberry]: DeliveryBoxberry,
  [DeliveryEnum.courier]: DeliveryCourier,
  [DeliveryEnum.pickup]: DeliveryPickup
};

export interface IDeliveryOptions {
  local: IDeliveryMethod[];
  internal: IDeliveryMethod[];
}

export interface IDeliveryFormData {
  deliveryType: DeliveryTypeEnum;
  deliveryLocal: string;
  deliveryInternational: string;
  city?: string;
  postcode?: string;
  address?: string;
  apartment_number?: string;
  floor_number?: string;
  entrance_number?: string;
  intercom_code?: string;
  comment?: string;
  country?: string;
}

export interface DeliveryFormProps {
  deliveryMethods: IDeliveryOptions;
  orderInfo: IOrderDetails;
  enabledSteps?: StepsType;
}

export function DeliveryForm({
  orderInfo,
  deliveryMethods,
  enabledSteps = 0
}: DeliveryFormProps) {
  const { isDesktop, isMobile } = useWindowSize();

  const [tab, getTabProps] = useFormTabs(
    'deliveryType',
    tabOptions,
    (x) => x.value
  );

  const [localDeliveryCode, getLocalDeliveryProps] = useFormTabs(
    'deliveryLocal',
    deliveryMethods.local,
    (x) => x.code
  );

  const [
    internationalDeliveryCode,
    getInternationalDeliveryProps
  ] = useFormTabs<IDeliveryMethod, string>(
    'deliveryInternational',
    deliveryMethods.internal,
    (x) => x.code
  );

  const DeliveryComponent = componentsDelivery[localDeliveryCode];

  const [deliveryDetails, totalPrice] = useMemo(() => {
    const method =
      tab === DeliveryTypeEnum.russia
        ? deliveryMethods.local.find((i) => i.code === localDeliveryCode)
        : deliveryMethods.internal.find(
            (i) => i.code === internationalDeliveryCode
          );

    const orderInfoDetails = orderInfo.orderDetails.find(
      (i) => i.type === OrderDetailsEnum.cards
    );

    let totalPrice = sumBy([orderInfoDetails], (x) => x.price);

    const deliveryDetail: IOrderItem = {
      title: 'Доставка',
      price:
        method.price_free && totalPrice >= method.price_free ? 0 : method.price,
      type: OrderDetailsEnum.delivery
    };

    totalPrice += deliveryDetail.price;

    const orderDetails = [orderInfoDetails, deliveryDetail];

    return [orderDetails, totalPrice];
  }, [
    deliveryMethods.internal,
    deliveryMethods.local,
    internationalDeliveryCode,
    localDeliveryCode,
    orderInfo.orderDetails,
    tab
  ]);

  return (
    <OrderLayout
      title={'Оформление заказа'}
      backLink={
        <BackLink
          title={'Получатель'}
          to={`/orders/${orderInfo.id}?step=1&token=${orderInfo.secret}`}
        />
      }
      rightBlock={
        <OrderDetails
          order={deliveryDetails}
          totalPrice={totalPrice}
          buttonText={
            isMobile ? 'Дальше' : !isDesktop ? 'Выбрать способ доставки' : null
          }
        />
      }
    >
      <div className={s.OrderPage}>
        {!isMobile && <StepBlock step={2} enabledSteps={enabledSteps} />}
        <div>
          <div className={s.OrderDetails__title}>Тип доставки:</div>
          <div
            className={clsx(
              s.OrderDetails__deliveryTypes,
              s.OrderDetails__mobileFullWidth
            )}
          >
            {tabOptions.map((item, key) => (
              <TabButton
                key={key}
                className={s.OrderDetails__deliveryType}
                title={item.title}
                {...getTabProps(item.value)}
              />
            ))}
          </div>
        </div>
        <div className={s.OrderDetails__title}>Способ доставки:</div>
        {tab === DeliveryTypeEnum.russia ? (
          <>
            <div className={s.OrderDetails__deliveryTypes}>
              {deliveryMethods.local.map((item, key) => (
                <CardButton
                  key={key}
                  className={s.OrderDetails__deliveryType}
                  title={item.name}
                  text={item.comment}
                  price={OrderHelper.getDeliveryPrice(item, totalPrice)}
                  price_free={item.price_free}
                  icon={deliveryIcon[item.code]}
                  {...getLocalDeliveryProps(item.code)}
                />
              ))}
            </div>
            <DeliveryComponent />
          </>
        ) : (
          <>
            <div className={s.OrderDetails__deliveryTypes}>
              {deliveryMethods.internal.map((item, key) => (
                <CardButton
                  key={key}
                  className={s.OrderDetails__deliveryType}
                  title={item.name}
                  text={item.comment}
                  icon={deliveryIcon[item.code]}
                  price={OrderHelper.getDeliveryPrice(item, totalPrice)}
                  price_free={item.price_free}
                  {...getInternationalDeliveryProps(item.code)}
                />
              ))}
            </div>
            <DeliveryInternational />
          </>
        )}
        {isDesktop && (
          <Button className={s.OrderDetails__nextButton} type={'submit'}>
            Перейти к оплате
          </Button>
        )}
      </div>
    </OrderLayout>
  );
}
