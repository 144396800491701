import React from 'react';
import clsx from 'clsx';
import { ErrorMessage, ErrorMessageProps } from '../ErrorMessage/ErrorMessage';
import { Button } from '../Button/Button';
import { Link } from '../Link/Link';
import { getApiErrorMessageWithCode } from '../../../../utils/log';
import buttonStyles from '../Button/Button.module.scss';
import s from '../ErrorMessage/ErrorMessage.module.scss';

export interface QueryErrorMessageProps extends ErrorMessageProps {
  error?: any;
  onReload?: () => void;
  showCatalogLink?: boolean;
}

export function QueryErrorMessage({
  text = 'Ошибка запроса',
  error,
  onReload,
  children,
  showCatalogLink = false,
  ...props
}: QueryErrorMessageProps) {
  const errorMessage = getApiErrorMessageWithCode(error, error.message);

  return (
    <ErrorMessage text={text} description={errorMessage} {...props}>
      {children}
      {onReload && (
        <Button onClick={onReload} className={s.ErrorMessage__actionButton}>
          Перезагрузить
        </Button>
      )}
      {showCatalogLink && (
        <Link
          to="/cards"
          className={clsx(
            buttonStyles.Button,
            buttonStyles.Button_size_medium,
            buttonStyles.Button_variant_primary,
            s.ErrorMessage__actionButton
          )}
        >
          Сбросить фильтр
        </Link>
      )}
    </ErrorMessage>
  );
}
