import React from 'react';
import { CardLoadingBlock, CardLoadingBlockProps } from './CardLoadingBlock';

export interface CardLoadingListProps {
  amount: number;
  cardLoadingProps?: CardLoadingBlockProps;
}

export function CardLoadingList({
  amount,
  cardLoadingProps
}: CardLoadingListProps) {
  const result = [];

  for (let i = 0; i < amount; i++) {
    result.push(<CardLoadingBlock key={i} {...cardLoadingProps} />);
  }

  return <>{result}</>;
}
