import React from 'react';
import clsx from 'clsx';

import s from './InputHelpBlock.module.scss';

export enum HelpBlockStatus {
  info = 'info',
  error = 'error'
}

export interface InputHelpBlockProps {
  /**
   * Состояние, что в поле ввода неправильное значение
   */
  status: HelpBlockStatus;

  /**
   * Пользовательский класс
   */
  className?: string;

  /**
   * Содержимое подсказки
   */
  children?: React.ReactChild;
}

export const InputHelpBlock: React.FC<InputHelpBlockProps> = ({
  status = HelpBlockStatus.info,
  className,
  children,
  ...props
}: InputHelpBlockProps) => {
  return (
    <div
      className={clsx(s.InputHelpBlock, className, [
        s[`InputHelpBlock_status_${status}`]
      ])}
      {...props}
    >
      {children}
    </div>
  );
};
