import { useCallback } from 'react';

export function useYupValidationResolver<T>(validationSchema) {
  return useCallback(
    async (data) => {
      try {
        const values =
          (await validationSchema?.validate(data, {
            abortEarly: false
          })) || data;
        return {
          values,
          errors: {}
        };
      } catch (e) {
        const errors = {};
        for (const error of e.inner) {
          errors[error.path] = {
            type: error.type ?? 'validation',
            message: error.message
          };
        }

        return {
          values: {},
          errors
        };
      }
    },
    [validationSchema]
  );
}
