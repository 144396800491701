/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Scrollbar, { ScrollbarProps } from 'react-scrollbars-custom';
import { SCROLL_OFFSET } from '../../constants';

export function useIndexHorizontalScroll(
  scrollRef: React.RefObject<Scrollbar>
) {
  const [blockWidth, setBlockWidth] = useState(0);

  const [isScrollPossible, setIsScrollPossible] = useState(false);
  const blockRef = useRef(null);

  useEffect(() => {
    setBlockWidth(blockRef.current?.offsetWidth);
  });

  const handleArrowClick = (offset) => {
    scrollRef.current.scrollerElement.scrollBy({
      left: offset,
      behavior: 'smooth'
    });
  };

  const handleForwardClick = () => {
    handleArrowClick(SCROLL_OFFSET);
  };

  const handleBackwardClick = () => {
    handleArrowClick(-SCROLL_OFFSET);
  };

  return {
    scrollRef,
    isScrollPossible,
    setIsScrollPossible,
    handleArrowClick,
    handleForwardClick,
    handleBackwardClick,
    blockRef,
    blockWidth
  };
}
