import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { cleanObject } from '@proscom/ui-utils';
import { skipIfNull } from '@proscom/prostore';
import { apiUrl } from '../../config';
import { CardData, PaginationMeta } from '../interfacesResponse';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  url: `${apiUrl}/cards?allNoCache=1`,
  method: 'get'
};

export interface CardsQueryResponse {
  data: CardData[];
  meta: PaginationMeta;
}

export interface CardsQueryVars {
  page?: string;
  sort?: string;
  include?: 'originalCard' | 'originalCard.originalSet' | string;
  append?: 'is_showcase' | string;
  filter?: {
    id?: string;
    is_foil?: string;
    search?: string;
    rarity?: string;
    colors?: string;
    cmc?: string;
    showcase?: string;
    price_from?: number;
    price_to?: number;
    artists?: string;
    format?: string;
    type?: string;
    state?: string;
    set?: string;
    lang?: string;
  };
}

const queryOptions: IUseAxiosQueryOptions<CardsQueryVars, CardsQueryResponse> =
  {
    query: {
      url: `${apiUrl}/cards?allNoCache=1`,
      method: 'get'
    },
    mapVariables: (variables) => ({ params: variables }),
    skipQuery: skipIfNull(null)
  };

const queryOptionsNoCache: IUseAxiosQueryOptions<
  CardsQueryVars,
  CardsQueryResponse
> = {
  query: {
    url: `${apiUrl}/cards?allNoCache=1`,
    method: 'get'
  },
  mapVariables: (variables) => ({ params: variables }),
  skipQuery: skipIfNull(null)
};

export function useCards(variables?: CardsQueryVars, noCache?: boolean) {
  return useAxiosQuery({
    queryOptions: noCache ? queryOptionsNoCache : queryOptions,
    variables: variables ? cleanObject(variables) : null
  });
}
