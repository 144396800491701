import React from 'react';
import clsx from 'clsx';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as ClearFilterIcon } from '../../../assets/icons/cross.svg';
import {
  Button,
  ButtonSize
} from '../../../common/components/ui/Button/Button';

import {
  Amount,
  AmountSize
} from '../../../common/components/ui/Amount/Amount';
import s from './FilterButton.module.scss';

export interface FilterButtonProps {
  opened: boolean;
  className?: string;
  onClick?: () => void;
  count?: number;
}

export const FilterButton = ({
  opened,
  className,
  count,
  ...props
}: FilterButtonProps) => {
  return (
    <Button
      size={ButtonSize.small}
      className={clsx(s.FilterButton, className)}
      {...props}
    >
      {opened ? <ClearFilterIcon /> : <FilterIcon />}
      {count > 0 ? (
        <Amount
          size={AmountSize.small}
          className={s.FilterButton__counter}
          value={count}
        />
      ) : null}
    </Button>
  );
};
