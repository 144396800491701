import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { InputProps } from '../Input';
import { PhoneInput } from '../PhoneInput';

export interface ControlledPhoneInputProps extends InputProps {
  name: string;
  registerOptions?: RegisterOptions;
}

export function ControlledPhoneInput({
  name,
  onChange,
  registerOptions,
  ...props
}: ControlledPhoneInputProps) {
  const {
    register,
    unregister,
    setError,
    errors,
    setValue,
    watch
  } = useFormContext<{
    [name: string]: string;
  }>();
  const fieldData = watch(name) || '';

  useEffect(() => {
    register({ name, ...registerOptions });
    return () => unregister(name);
  }, [name, register, registerOptions, unregister]);

  return (
    <>
      <PhoneInput
        name={name}
        value={fieldData}
        invalid={!!errors[name]}
        errorText={errors[name] && errors[name].message}
        onChange={(value) => {
          setValue(name, value);
          onChange && onChange(value);
        }}
        {...props}
      />
    </>
  );
}
