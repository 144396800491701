import React, { ReactNode, useMemo } from 'react';
import { capitalize } from 'lodash-es';
import { CardData, MetaCard } from '../../store/interfacesResponse';
import { TagVariant } from '../components/ui/Tag/Tag';

// import { ReactComponent as FoilIcon } from '../../assets/icons/foil.svg';

export interface ITag {
  label: string | ReactNode;
  variant: TagVariant;
  title?: string;
}

export function useCardTags(data: MetaCard | CardData, includeLang?: boolean) {
  return useMemo(() => {
    const tags: ITag[] = [];

    if (
      'meta_card_id' in data &&
      data.meta_card_id &&
      includeLang &&
      data.original_card.front_lang
    ) {
      tags.push({
        label: capitalize(data.original_card.front_lang),
        variant: TagVariant.normal
      });
    }

    if (data.is_showcase) {
      tags.push({
        label: 'Шоукейс',
        variant: TagVariant.primary
      });
    }

    if (data.is_foil) {
      tags.push({
        // label: <FoilIcon />,
        label: 'FOIL',
        variant: TagVariant.foil
      });
    }

    return tags;
  }, [data, includeLang]);
}
