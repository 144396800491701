import React from 'react';
import { Link, LinkProps } from '../Link/Link';

export interface ContactEmailLinkProps extends LinkProps {
  orderId?: string | number;
}

export function ContactEmailLink({ orderId, ...props }: ContactEmailLinkProps) {
  return (
    <Link
      href={
        `mailto:customer@gfcards.ru` +
        (orderId ? `?subject=Заказ%20${orderId}` : '')
      }
      {...props}
    >
      customer@gfcards.ru
    </Link>
  );
}
