import { useCallback } from 'react';
import { useStore } from '@proscom/prostore-react';
import { toast } from 'react-toastify';
import { CardConditionEnum } from '../components/ui/Card/CardStatusEnum';
import { CartStore } from '../../store/CartStore';
import { STORE_CART } from '../../store/stores';

export enum CartStockTextState {
  notInCart, //ни одной единицы товара не добавили в корзину
  inCart, // в корзину добавили несколько единиц товаров, однако на складе есть еще
  allInCart // в корзину добавили все доступные единицы товаров
}

export function useCart() {
  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const cart = cartStore.getItems();

  const getCartCount = useCallback(
    (cardId, condition) => {
      const currentCard = cart.find((card) => card.id === cardId);
      return currentCard ? currentCard.conditions[condition] || 0 : 0;
    },
    [cart]
  );

  const getConditionStockText = useCallback(
    (
      cardId: number,
      condition: CardConditionEnum,
      stockCount: number = 0
    ): CartStockTextState => {
      const cartCount = getCartCount(cardId, condition);

      if (cartCount === 0) {
        return CartStockTextState.notInCart;
      }

      if (cartCount < stockCount) {
        return CartStockTextState.inCart;
      }

      return CartStockTextState.allInCart;
    },
    [getCartCount]
  );

  const handleChangeCart = useCallback(
    ({
      cardId,
      condition,
      value,
      stockCount
    }: {
      cardId: number;
      condition: CardConditionEnum;
      value: number;
      stockCount: number;
    }): void => {
      const cartCount = getCartCount(cardId, condition);

      if (value === cartCount && value === stockCount) {
        toast('На складе больше нет данного товара');
        return;
      }

      cartStore.onChangeCount(cardId, condition, value);
    },
    [getCartCount, cartStore]
  );

  return {
    getCartCount,
    getConditionStockText,
    handleChangeCart
  };
}
