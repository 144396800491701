import React from 'react';
import clsx from 'clsx';
import { ButtonRadio } from './ButtonRadio';
import s from './ButtonRadio.module.scss';

export interface ButtonRadioOption {
  label: string;
  value: any;
  disabled?: boolean;
}

interface ButtonsRadioProps {
  header?: string;
  options?: ButtonRadioOption[];
  onChange: (value) => void;
  selected?: any;
  className?: string;
  renderLabel?: (option: ButtonRadioOption) => React.ReactNode; // функция для рендера отдельной опции
}

export function ButtonsRadio({
  header,
  options,
  onChange,
  selected,
  className,
  renderLabel
}: ButtonsRadioProps) {
  return (
    <div className={clsx(s.ButtonRadios, className)}>
      {header && <div className={s.ButtonRadios__header}>{header}</div>}
      {options?.map((option, key) => {
        return (
          <ButtonRadio
            key={key}
            id={option.value}
            disabled={option.disabled}
            active={option.value === selected}
            onClick={() => onChange(option.value)}
          >
            {renderLabel ? renderLabel(option) : option.label}
          </ButtonRadio>
        );
      })}
    </div>
  );
}
