import React from 'react';
import { match as Match } from 'react-router-dom';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { customQueryLoader } from '../../common/components/utils/queryLoader';
import { ScrollBlock } from '../../common/components/ui/ScrollBlock/ScrollBlock';
import { CardBlock } from '../../common/components/ui/Card/CardBlock/CardBlock';
import { useWindowSize } from '../../utils/helpers/useWindowSize';
import { useSimilarCards } from '../../store/query/useSimilarCards';
import { BackButton } from '../../common/components/ui/Button/BackButton';
import { CardLoadingList } from '../../common/components/ui/Card/CardLoadingList';
import { STORE_LOCATION } from '../../store/stores';
import { useMetaCard } from '../../store/query/useMetaCard';
import { CardInfo } from './CardInfo/CardInfo';
import { CardInfoLoading } from './CardInfo/CardInfoLoading';
import layoutStyles from '../_layouts/DefaultLayout.module.scss';
import s from './CardPage.module.scss';

export interface CardPageProps {
  match: Match<{ cardId: string }>;
}

export function CardPage({ match }: CardPageProps) {
  const { isMobile } = useWindowSize();
  const { cardId } = match.params;

  const [locationState, locationStore] =
    useStore<LocationStore>(STORE_LOCATION);

  const cardQuery = useMetaCard(cardId, {
    append: 'is_showcase',
    include: 'card.originalCard'
  });
  const similarCardsQuery = useSimilarCards(cardId, {
    include: 'originalCard.originalSet'
  });

  const cardData = cardQuery.state.data;
  const similarCards = similarCardsQuery.state.data;

  const {
    cardLang,
    cardId: simpleCardId,
    cardState,
    ...queryWithoutCardLangAndState
  } = locationState.query;

  const urlPreserver = locationStore.createUrlPreserver(
    queryWithoutCardLangAndState
  );

  return (
    <div className={s.CardPage}>
      <BackButton className={s.CardPage__backLink} to={urlPreserver('/cards')}>
        Каталог
      </BackButton>

      {customQueryLoader(cardQuery, <CardInfoLoading />) || (
        <CardInfo data={cardData} />
      )}

      <div className={layoutStyles.DefaultLayoutWrapper}>
        <ScrollBlock
          cardWidth={288}
          cardsCount={10}
          height={isMobile ? 450 : 530}
          cardGap={isMobile ? 8 : 24}
          className={s.CardPage__moreCard}
          header="Похожие товары"
          noScroll={similarCardsQuery.state.error}
        >
          {customQueryLoader(
            similarCardsQuery,
            <CardLoadingList
              amount={10}
              cardLoadingProps={{ isCompact: true }}
            />
          ) ||
            similarCards.map((card, iCard) => (
              <CardBlock key={iCard} data={card} />
            ))}
        </ScrollBlock>
      </div>
    </div>
  );
}
