import React, { useRef } from 'react';
import { ReactComponent as ShowCollectionIcon } from '../../../../assets/icons/show_collection.svg';
import { Link, LinkProps } from '../Link/Link';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { useIndexHorizontalScroll } from '../../../hooks/useIndexHorizontalScroll';
import { useViewportWidth } from '../../../hooks/useViewportWidth';
import { IndexPageBlock } from '../IndexPageBlock/IndexPageBlock';
import { HorizontalScroll } from '../HorizontalScroll/HorizontalScroll';

import indexPageBlockStyles from '../IndexPageBlock/IndexPageBlock.module.scss';

export interface ScrollBlockProps {
  cardsCount: number;
  cardWidth: number;
  cardGap: number;
  header: string;
  height: number;
  linkProps?: LinkProps;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  invert?: boolean;
  noScroll?: boolean;
}

export const ScrollBlock = ({
  cardsCount,
  cardWidth,
  cardGap,
  header,
  height,
  linkProps,
  className,
  children,
  invert,
  noScroll,
  ...props
}: ScrollBlockProps) => {
  const { isMobile } = useWindowSize();
  const parentWidth = useViewportWidth();

  const scrollbarsRef = useRef(null);

  const {
    isScrollPossible,
    setIsScrollPossible,
    handleForwardClick,
    handleBackwardClick,
    blockRef,
    blockWidth
  } = useIndexHorizontalScroll(scrollbarsRef);

  const getPadding = () => {
    return isMobile ? 16 : `calc((100vw - ${blockWidth}px) / 2)`;
  };

  const getWidth = () => {
    return isMobile
      ? 32 + (cardWidth + cardGap) * cardsCount
      : parentWidth +
          cardWidth * cardsCount +
          cardGap * (cardsCount - 1) -
          blockWidth;
  };

  return (
    <IndexPageBlock
      header={header}
      actionLink={
        linkProps ? (
          <Link
            className={indexPageBlockStyles.IndexPageBlock__link}
            {...linkProps}
          >
            {isMobile ? <ShowCollectionIcon /> : 'Перейти в каталог'}
          </Link>
        ) : null
      }
      showArrows={isScrollPossible && !noScroll}
      onForwardClick={handleForwardClick}
      onBackClick={handleBackwardClick}
      blockRef={blockRef}
      invert={invert}
    >
      {noScroll ? (
        children
      ) : (
        <HorizontalScroll
          scrollbarRef={scrollbarsRef}
          onChangeScroll={setIsScrollPossible}
          height={height}
          contentWidth={blockWidth}
          parentWidth={parentWidth}
        >
          <div
            className={className}
            style={{
              paddingLeft: getPadding(),
              paddingRight: getPadding(),
              width: getWidth()
            }}
            {...props}
          >
            {children}
          </div>
        </HorizontalScroll>
      )}
    </IndexPageBlock>
  );
};
