import React from 'react';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as NothingFoundIcon } from '../../../../assets/icons/nothing_found.svg';
import { Link } from '../Link/Link';
import { STORE_LOCATION } from '../../../../store/stores';
import { URL_KEY_PAGE } from '../../../../store/urlKeys';
import s from './NothingFoundScreen.module.scss';

export interface NothingFoundScreenProps {
  page?: number;
  totalPages?: number;
}

export const NothingFoundScreen = ({
  page,
  totalPages
}: NothingFoundScreenProps) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );

  const urlPreserver = locationStore.createUrlPreserver(locationState.query);

  return (
    <div className={s.NothingFoundScreen}>
      <NothingFoundIcon />
      <div className={s.NothingFoundScreen__title}>Нет результатов поиска</div>
      <div className={s.NothingFoundScreen__description}>
        Попробуйте{' '}
        <Link to="/cards" className={s.NothingFoundScreen__link}>
          сбросить фильтр
        </Link>
        {page > 1 && totalPages && (
          <>
            <br /> или{' '}
            <Link
              to={urlPreserver('/cards', { [URL_KEY_PAGE]: totalPages })}
              className={s.NothingFoundScreen__link}
            >
              перейти на предыдущую страницу
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
