import ReactDOM from 'react-dom';

export function createBatcher() {
  const batchQueue = [];
  let batchTimeout = null;

  function executeQueue() {
    batchTimeout = null;
    ReactDOM.unstable_batchedUpdates(() => {
      for (let cb of batchQueue) {
        cb();
      }
    });
    batchQueue.length = 0;
  }

  function queueFunction(cb) {
    batchQueue.push(cb);
    if (!batchTimeout) {
      batchTimeout = setTimeout(executeQueue, 0);
    }
  }

  return queueFunction;
}
