import React from 'react';
import clsx from 'clsx';
import {
  Spinner,
  SpinnerSize
} from '../../../common/components/ui/Spinner/Spinner';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import s from '../../../common/components/layout/OrderLayout/OrderLayout.module.scss';
import orderStyles from '../common.module.scss';

export function OrderLoadingBlock() {
  const { isDesktop } = useWindowSize();

  return (
    <div className={s.OrderLayout__layout}>
      <div className={s.OrderLayout}>
        <div className={s.OrderLayout__backLink} />
        <h1 className={s.OrderLayout__title}>Оформление заказа</h1>
        <div className={s.OrderLayout__contentLayout}>
          <div className={s.OrderLayout__content}>
            <div
              className={clsx(
                orderStyles.OrderPage,
                orderStyles.OrderPage_loading
              )}
            >
              <Spinner loading size={SpinnerSize.XXL} centered />
            </div>
          </div>
          {isDesktop && (
            <div
              className={clsx(
                orderStyles.OrderPage,
                orderStyles.OrderPage_loading
              )}
            >
              <Spinner loading size={SpinnerSize.XXL} centered />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
