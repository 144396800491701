import React from 'react';
import clsx from 'clsx';
import { Spinner } from '../Spinner/Spinner';

import { Link, LinkProps } from '../Link/Link';
import { IntrinsicPropsWithoutRef } from '../../../types';
import s from './Button.module.scss';

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  iconButton = 'iconButton'
}

export enum ButtonSize {
  medium = 'medium',
  small = 'small'
}

export type ButtonProps = (
  | IntrinsicPropsWithoutRef<'button'>
  | IntrinsicPropsWithoutRef<'a'>
  | LinkProps
) & {
  /**
   * Заблокированное состояние
   */
  disabled?: boolean;

  /**
   * Произвольные классы
   */
  className?: string;

  /**
   * Вариант оформления ("primary", "secondary")
   */
  variant?: ButtonVariant;

  /**
   * Размер кнопки ("normal", "small")
   */
  size?: ButtonSize;

  /**
   * Содержимое кнопки
   */
  children?: React.ReactNode;

  fullWidth?: boolean;

  to?: string;

  href?: string;

  spinner?: boolean;
};

function ButtonComponent(props) {
  const { to, href, children } = props;
  return to ? (
    <Link {...props} />
  ) : href ? (
    <a {...props}>{children}</a>
  ) : (
    <button {...props} />
  );
}

export const Button = ({
  variant,
  size = ButtonSize.medium,
  className,
  children,
  fullWidth,
  spinner,
  ...props
}: ButtonProps) => {
  return (
    <ButtonComponent
      className={clsx(s.Button, className, s[`Button_size_${size}`], {
        [s[`Button_variant_${variant}`]]: variant,
        [s.Button_fullWidth]: fullWidth,
        [s.Button_withSpinner]: spinner
      })}
      {...props}
    >
      {spinner ? (
        <>
          <div className={s.Button__content}>{children}</div>
          <Spinner className={s.Button__spinner} loading />
        </>
      ) : (
        children
      )}
    </ButtonComponent>
  );
};

Button.Variant = ButtonVariant;
Button.Size = ButtonSize;
