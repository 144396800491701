import React from 'react';
import clsx from 'clsx';
import { Header } from '../../common/components/ui/Header/Header';
import { Footer } from '../../common/components/ui/Footer/Footer';
import s from './common.module.scss';

export function ErrorLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    return (
      <>
        <Header />
        <div className={s.LayoutWrapper}>
          <div className={clsx(s.Layout, s.ErrorLayout)}>
            <Comp {...props} />
          </div>
        </div>
        <Footer />
      </>
    );
  };
}
