import React, { useMemo } from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as BurgerIcon } from '../../../../assets/icons/burger.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search_header.svg';
import { ReactComponent as CatalogIcon } from '../../../../assets/icons/catalog.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove_header.svg';
import { ReactComponent as BagIcon } from '../../../../assets/icons/bag_mobile.svg';

import { Link } from '../Link/Link';
import { MobileSetsPopup } from '../SetsPopup/MobileSetsPopup';
import { Logo } from '../Logo/Logo';
import { CartStore } from '../../../../store/CartStore';
import { STORE_CART, STORE_COMMON } from '../../../../store/stores';
import { Amount, AmountSize } from '../Amount/Amount';
import { CommonStore } from '../../../../store/CommonStore';
import { DEFAULT_SELL_CARD_POPUP_CONTENT } from '../Popups/SellCardPopup';
import { DesktopHeaderProps } from './DesktopHeader';
import { HeaderSearch } from './HeaderSearch';
import s from './Header.module.scss';

export enum MobileHeaderVariant {
  normal = 'normal',
  search = 'search',
  modal = 'modal',
  menu = 'menu',
  expansions = 'expansions'
}

export interface MobileHeaderProps extends DesktopHeaderProps {
  variant: MobileHeaderVariant;
  onSearchClose?: () => void;
  onModalClose?: () => void;
  onChangeVariant?: (variant: MobileHeaderVariant) => void;
}

export const MobileHeader = ({
  variant,
  onSearchClose,
  onModalClose,
  onChangeVariant,
  currentFormatId,
  setCurrentFormatId,
  setLinkProps
}: MobileHeaderProps) => {
  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);
  const cart = cartStore.getItems();

  let headerContent = null;
  switch (variant) {
    case MobileHeaderVariant.normal:
      headerContent = (
        <>
          <Logo iconProps={{ className: s.Header__logo }} />
          <div className={s.Header__navigation}>
            <Link className={s.Header__link} to="/cart">
              <BagIcon />
              {cart.length ? (
                <Amount
                  size={AmountSize.small}
                  value={cart.length}
                  className={s.Header__cartCounter}
                />
              ) : null}
            </Link>
            <Link
              className={s.Header__link}
              onClick={() => onChangeVariant(MobileHeaderVariant.expansions)}
            >
              <CatalogIcon />
            </Link>
            <Link
              className={s.Header__link}
              onClick={() => onChangeVariant(MobileHeaderVariant.search)}
            >
              <SearchIcon />
            </Link>
            <Link
              className={s.Header__link}
              onClick={() => onChangeVariant(MobileHeaderVariant.menu)}
            >
              <BurgerIcon />
            </Link>
          </div>
        </>
      );
      break;
    case MobileHeaderVariant.search:
      headerContent = (
        <>
          <HeaderSearch />
          <div className={s.Header__navigation}>
            <button
              className={s.Header__link}
              onClick={() => onSearchClose?.()}
            >
              <RemoveIcon />
            </button>
          </div>
        </>
      );
      break;
    case MobileHeaderVariant.modal:
      headerContent = (
        <>
          <Logo iconProps={{ className: s.Header__logo }} />
          <div className={s.Header__navigation}>
            <button className={s.Header__link} onClick={() => onModalClose?.()}>
              <RemoveIcon />
            </button>
          </div>
        </>
      );
      break;
    case MobileHeaderVariant.menu:
      headerContent = (
        <>
          <Logo iconProps={{ className: s.Header__logo }} />
          <div className={s.Header__navigation}>
            <button className={s.Header__link} onClick={() => onModalClose?.()}>
              <RemoveIcon />
            </button>
          </div>
          {ReactDOM.createPortal(
            <div className={s.Header__mobileMenu}>
              <Link to="/cards" className={s.Header__mobileMenuLink}>
                Магазин
              </Link>
              <Link
                href="#"
                className={s.Header__mobileMenuLink}
                onClick={(e) => {
                  e.preventDefault();
                  commonStore.setSellCardModal(DEFAULT_SELL_CARD_POPUP_CONTENT);
                }}
              >
                Продать карточки
              </Link>
              <Link
                href={'/deliveryandpayments'}
                className={s.Header__mobileMenuLink}
              >
                Доставка и оплата
              </Link>
              <Link to={'/policies'} className={s.Header__mobileMenuLink}>
                Юридические документы
              </Link>
            </div>,
            document.body
          )}
        </>
      );
      break;
    case MobileHeaderVariant.expansions:
      headerContent = (
        <>
          <Logo iconProps={{ className: s.Header__logo }} />
          <div className={s.Header__navigation}>
            <button className={s.Header__link} onClick={() => onModalClose?.()}>
              <RemoveIcon />
            </button>
          </div>
          <MobileSetsPopup
            currentFormatId={currentFormatId}
            onFormatClick={setCurrentFormatId}
            setLinkPropsFn={setLinkProps}
            onClose={() => onChangeVariant(MobileHeaderVariant.normal)}
            onAllCardsClick={() => onChangeVariant(MobileHeaderVariant.normal)}
          />
        </>
      );
      break;
  }

  return (
    <header
      className={clsx(s.Header, {
        [s.Header_fixed]:
          variant === MobileHeaderVariant.modal ||
          variant === MobileHeaderVariant.expansions ||
          variant === MobileHeaderVariant.menu
      })}
    >
      <div className={s.Header__content}>{headerContent}</div>
    </header>
  );
};
