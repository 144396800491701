import React from 'react';
import { UseRequestStoreResult } from '@proscom/prostore-react';
import { QueryErrorMessage } from '../ui/QueryErrorMessage/QueryErrorMessage';
import { Spinner, SpinnerProps } from '../ui/Spinner/Spinner';

export type UseRequestStoreResultAny = UseRequestStoreResult<any>;

/**
 * Рендерит лоадер, если запрос идет, ошибку, если он ошибочен
 * и возвращает null, если все ок
 *
 * @param query - запрос
 * @param spinnerProps - параметры спиннера
 *
 * @usage В jsx-коде:
 *  queryLoader(query) || <div>Data</div>
 */
export function queryLoader(
  query: UseRequestStoreResultAny,
  spinnerProps: SpinnerProps = {}
) {
  return query.check.spinner ? (
    <Spinner loading {...spinnerProps} />
  ) : query.check.error ? (
    <QueryErrorMessage error={query.state.error} onReload={query.load} />
  ) : null;
}

/**
 * Рендерит customSpinner, если query в процессе загрузки
 *
 * @param query - запрос
 * @param customSpinner - элемент спиннера
 * @param showCatalogLink - показывать ли ссылку на каталог карточек
 */
export function customQueryLoader(
  query: UseRequestStoreResultAny,
  customSpinner: React.ReactNode,
  showCatalogLink: boolean = false
) {
  if (query.check.spinner) {
    return customSpinner;
  }

  if (query.check.error) {
    return (
      <QueryErrorMessage
        error={query.state.error}
        onReload={query.load}
        showCatalogLink={showCatalogLink}
      />
    );
  }

  return null;
}

/**
 * Рендерит мини-лоадер, если запрос идет, ошибку, если он ошибочен
 * и возвращает null, если все ок
 *
 * @param query - запрос
 *
 * @usage В jsx-коде:
 *  queryLoaderInline(query) || <div>Data</div>
 */
export function queryLoaderInline(query: UseRequestStoreResultAny) {
  return query.check.spinner ? (
    '...'
  ) : query.check.error ? (
    <QueryErrorMessage error={query.state.error} onReload={query.load} />
  ) : null;
}

/**
 * Рендерит лоадер, если запрос идет, ошибку, если он ошибочен,
 * специальный контент, если данных нет,
 * и возвращает null, если все ок
 *
 * @param query - запрос
 * @param renderNotFound - рендер-функция для специального контента
 *
 * @usage В jsx-коде:
 *  queryLoaderWithExistenceCheck(query) || <div>Data</div>
 */
export function queryLoaderWithExistenceCheck(
  query: UseRequestStoreResultAny,
  renderNotFound: () => React.ReactNode = () => 'Не найдено'
) {
  return (
    queryLoader(query) || (!query.state.data ? renderNotFound() : undefined)
  );
}

/**
 * Рендерит лоадер, если хотя бы один из переданных запросов грузится,
 * рендерит ошибку, если хотя бы один из переданных запросов ошибочек,
 * возвращает null, если все ок
 *
 * @param queries - запросы
 * @usage В jsx-коде:
 *  multiQueryLoader([query1, query2]) || <div>Data</div>
 */
export function multiQueryLoader(queries: UseRequestStoreResultAny[]) {
  const spinner = queries.some((q) => q.check.spinner);
  const errorQuery = queries.find((q) => q.check.error);
  return spinner ? (
    <Spinner />
  ) : errorQuery ? (
    <QueryErrorMessage
      error={errorQuery.state.error}
      onReload={errorQuery.load}
    />
  ) : null;
}
