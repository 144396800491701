import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { RUBLE } from '@proscom/ui-utils';
import { useStore } from '@proscom/prostore-react';
import {
  getCardForTextarea,
  getStockDescription
} from '../../../../utils/data';
import { CardConditionType } from '../Card/CardStatusEnum';
import {
  AvailableButton,
  NotAvailableButton,
  PreorderButton
} from '../Card/CardAvailableBlock';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { ICardState } from '../../../hooks/useCardStates';
import { CartStore } from '../../../../store/CartStore';
import { STORE_CART, STORE_COMMON } from '../../../../store/stores';
import { prettyNumber } from '../../../../utils/number';
import { CommonStore } from '../../../../store/CommonStore';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import {
  DEFAULT_FIND_CARD_POPUP_CONTENT,
  DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT
} from '../Popups/FindCardPopup';
import s from './CardState.module.scss';

export enum CardStateVariant {
  // варианты для отдельного состояния
  normal = 'Normal', //карточка есть на складе, у нее задана правильная цена, она вышла в продажу
  invalidPrice = 'InvalidPrice', //карточка есть на складе, но ее цена задана неверно
  preorderState = 'PreorderState', //предзаказ определенного состояния

  // варианты, которые действуют сразу на всю карточку
  preorder = 'Preorder', //карточка не вышла в продажу
  preorderFromStock = 'PreorderFromStock', //карточка типа "есть на складе" и доступна для предзаказа
  nonAvailable = 'NonAvailable', //карточки нет на складе, но у нее задана правильная цена
  invalidAllStates = 'InvalidAllStates' //у всех состояний карточки неправильно заданная цена
}

export interface CardStateProps {
  cardId: number;
  cardTitle: string;
  cardIsFoil: boolean;
  data: ICardState;
  variant?: CardStateVariant; //если передается вручную, но показывается переданный вариант. Иначе вычисляется вариант автоматически внутри компонента
  isMetaCard?: boolean;
  showDeleteButton?: boolean;
  preorderPrice?: number;
}

export function CardState({
  cardId,
  cardTitle,
  cardIsFoil,
  data,
  variant = CardStateVariant.normal,
  isMetaCard,
  showDeleteButton,
  preorderPrice
}: CardStateProps) {
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);
  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const cart = cartStore.getItems();
  const { isMobile } = useWindowSize();

  const handlePreorderClick = useCallback(() => {
    commonStore.setFindCardModalContent({
      title: 'Оформить предзаказ карточки',
      description:
        'Оставьте нам свою почту чтобы оформить предзаказ. Мы свяжемся с вами когда карточка поступит в продажу.',
      buttonText: 'Оформить предзаказ',
      textareaDefaultValue: getCardForTextarea({
        id: cardId,
        title: cardTitle,
        isFoil: cardIsFoil,
        condition: data.type
      })
    });
  }, [data.type, cardIsFoil, cardTitle, cardId, commonStore]);

  const handleFindClick = useCallback(() => {
    const defaultModalContent =
      variant === CardStateVariant.nonAvailable ||
      variant === CardStateVariant.preorder
        ? DEFAULT_FIND_CARD_POPUP_CONTENT
        : DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT;

    commonStore.setFindCardModalContent({
      ...defaultModalContent,
      textareaDefaultValue: getCardForTextarea({
        id: cardId,
        title: cardTitle,
        isFoil: cardIsFoil,
        condition: data.type
      })
    });
  }, [data.type, cardIsFoil, cardTitle, cardId, commonStore, variant]);

  const [actionButton, contentText, descriptionText] = useMemo(() => {
    if (variant === CardStateVariant.normal) {
      return [
        <AvailableButton
          isMobile={isMobile}
          cardId={cardId}
          condition={data.type}
          maxStock={data.stock}
          price={data.price}
        />,
        isMetaCard ? data.language.toUpperCase() : data.type?.toUpperCase(),
        isMetaCard
          ? CardConditionType.getFullName(data.type)
          : CardConditionType.getName(data.type)
      ];
    } else if (variant === CardStateVariant.preorderState) {
      return [
        <AvailableButton
          isMobile={isMobile}
          cardId={cardId}
          condition={data.type}
          maxStock={data.stock}
          price={data.price}
          isPreorder
        />,
        isMetaCard ? data.language.toUpperCase() : data.type?.toUpperCase(),
        isMetaCard
          ? CardConditionType.getFullName(data.type)
          : CardConditionType.getName(data.type)
      ];
    } else if (variant === CardStateVariant.preorder) {
      return [
        <NotAvailableButton
          isMobile={isMobile}
          onClick={handleFindClick}
          buttonText={'Заказать поиск'}
          showIconOnMobile={data.price >= 1}
        />,
        `Новинка! Скоро поступит в продажу` +
          (data.language ? ` (${data.language})` : ''),
        null
      ];
    } else if (variant === CardStateVariant.invalidPrice) {
      return [
        <NotAvailableButton
          isMobile={isMobile}
          onClick={handleFindClick}
          buttonText={'Оформить заявку'}
        />,
        isMetaCard ? data.language.toUpperCase() : data.type?.toUpperCase(),
        isMetaCard
          ? CardConditionType.getFullName(data.type)
          : CardConditionType.getName(data.type)
      ];
    } else if (variant === CardStateVariant.nonAvailable) {
      return [
        <NotAvailableButton
          isMobile={isMobile}
          onClick={handleFindClick}
          buttonText={'Заказать поиск'}
          showIconOnMobile={data.price >= 1}
        />,
        'Нет в наличии',
        showDeleteButton
          ? ''
          : data.price >= 1
          ? 'Цена указана ориентировочно'
          : null
      ];
    } else if (variant === CardStateVariant.invalidAllStates) {
      return [
        <NotAvailableButton
          isMobile={isMobile}
          onClick={handleFindClick}
          buttonText={'Оформить заявку'}
          showIconOnMobile={false}
        />,
        'Цена не указана',
        null
      ];
    }
  }, [variant, isMobile, cardId, data.type, data.stock, data.price, data.language, isMetaCard, handleFindClick, showDeleteButton]);

  const countInCart = useMemo(() => {
    const currentCard = cart.find((card) => card.id === cardId);

    return currentCard ? currentCard.conditions[data.type] || 0 : 0;
  }, [cart, cardId, data.type]);

  const isStockVisible =
    (!isMobile &&
      (variant === CardStateVariant.normal ||
        variant === CardStateVariant.invalidPrice ||
        variant === CardStateVariant.preorderState)) ||
    (isMobile &&
      variant === CardStateVariant.invalidPrice &&
      !showDeleteButton);
  const isPriceVisible =
    variant === CardStateVariant.normal ||
    (variant === CardStateVariant.nonAvailable && !showDeleteButton);
  const isInDescriptionStock = isMobile && variant === CardStateVariant.normal;

  return (
    <div
      className={clsx(s.CardState, s['CardState_variant' + variant], {
        [s.CardState_cantAddMore]: countInCart && data.stock === countInCart,
        [s.CardState_variantNonAvailableWithoutPrice]:
          variant === CardStateVariant.nonAvailable && data.price < 1,
        [s.CardState_withDeleteButton]: showDeleteButton
      })}
    >
      <div className={s.CardState__content}>
        <div className={s.CardState__title}>{contentText}</div>
        {descriptionText && (
          <>
            <div className={s.CardState__description}>{descriptionText}</div>
            {isInDescriptionStock && (
              <div
                className={clsx(
                  s.CardState__description,
                  s.CardState__descriptionStock
                )}
              >
                {getStockDescription(data.stock, countInCart)}
              </div>
            )}
          </>
        )}
      </div>
      {isStockVisible && (
        <div className={s.CardState__stock}>
          {getStockDescription(data.stock, countInCart)}
        </div>
      )}
      {isPriceVisible && data.price < 1 && (
        <div className={s.CardState__price}>&nbsp;</div>
      )}
      {isPriceVisible &&
        data.price >= 1 &&
        variant === CardStateVariant.nonAvailable && (
          <div className={s.CardState__price}>
            ~{prettyNumber(data.price, RUBLE)}
          </div>
        )}
      <div className={s.CardState__button}>{actionButton}</div>
      {showDeleteButton && (
        <button
          onClick={() => cartStore.removeFromCart(cardId, data.type)}
          className={s.CardState__deleteButton}
        >
          <TrashIcon />
        </button>
      )}
    </div>
  );
}
