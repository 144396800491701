import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from '../Link/Link';
import {
  OriginalCard,
  OriginalSet
} from '../../../../store/interfacesResponse';
import s from '../Card/CardBlock/CardBlock.module.scss';

export interface CardSetNameProps {
  originSet: OriginalSet;
  className?: string;
}

export function CardSetName({ originSet, className }: CardSetNameProps) {
  const [isImageValid, setIsImageValid] = useState(true);

  const imageUrl = originSet.icon_svg_uri;

  useEffect(() => {
    setIsImageValid(true);
  }, [imageUrl]);

  return (
    <Link
      to={`/cards?sets=${originSet.id}`}
      className={clsx(s.CardBlock__expansion, className)}
    >
      {originSet.name}{' '}
      {isImageValid && (
        <img
          src={imageUrl}
          className={s.CardBlock__expansionIcon}
          alt={originSet.name + ' icon'}
          onError={() => setIsImageValid(false)}
        />
      )}
    </Link>
  );
}
