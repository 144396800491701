import React, { useEffect, useMemo } from 'react';
import { Select } from '../../../../common/components/ui/Select/Select';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { FilterCardBlock } from '../FilterCardBlock';
import { useCheckboxesFromSelect } from '../../../../common/hooks/useCheckboxesFromSelect';
import { useSets } from '../../../../store/query/useSets';
import { URL_KEY_SETS } from '../../../../store/urlKeys';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { FILTER_SELECT_TABLET_WIDTH } from '../../../../constants';

export const FilterSets = React.memo(function FilterSets() {
  const setsQuery = useSets();
  const setsData = setsQuery.state.data;

  const filteredSets = useMemo(() => {
    return setsData?.filter(
      (set) =>
        [
          'expansion',
          'core',
          'commander',
          'masters',
          'masterpiece',
          'draft_innovation'
        ].indexOf(set.set_type) > -1
    );
  }, [setsData]);

  const { isTablet } = useWindowSize();

  const {
    addItem: addSet,
    removeItem: removeSet,
    list: setsList,
    selectedItems: selectedSets
  } = useCheckboxesFromSelect(URL_KEY_SETS, setsData, filteredSets);

  return (
    <FilterCardBlock header="Издание">
      <>
        <Select
          options={setsList}
          values={null}
          placeholder="Выбрать"
          onChange={(option) => {
            addSet(option.value);
          }}
          width={isTablet && FILTER_SELECT_TABLET_WIDTH}
        />
        {selectedSets.length > 0 && (
          <CheckboxList direction={CheckboxListDirection.vertical}>
            {selectedSets.map((setItem, setItemIndex) => (
              <Checkbox
                key={setItemIndex}
                checked
                onChange={() => removeSet(setItem?.value)}
              >
                {setItem?.label}
              </Checkbox>
            ))}
          </CheckboxList>
        )}
      </>
    </FilterCardBlock>
  );
});
