import { BehaviorStore } from '@proscom/prostore';
import { Subject } from 'rxjs';
import { MobileHeaderVariant } from '../common/components/ui/Header/MobileHeader';
import { FeedBackModalContent } from '../common/components/ui/Popups/CardBasePopup';
import { CardPreviewModalContent } from '../common/components/ui/Card/CardPreviewModal';

export interface CommonStoreState {
  mobileHeaderVariant: MobileHeaderVariant;
  findCardModal: FeedBackModalContent | null;
  sellCardModal: FeedBackModalContent | null;
  previewCardModal: CardPreviewModalContent | null;
}

const initialState: CommonStoreState = {
  mobileHeaderVariant: MobileHeaderVariant.normal,
  findCardModal: null,
  sellCardModal: null,
  previewCardModal: null
};

export class CommonStore extends BehaviorStore<CommonStoreState> {
  onHeaderClose$ = new Subject<boolean>();

  constructor() {
    super(initialState);
  }

  setMobileHeaderVariant(mobileHeaderVariant: MobileHeaderVariant) {
    this.setState({
      mobileHeaderVariant
    });
  }

  closeHeader() {
    this.onHeaderClose$.next();
    this.closeModals();
  }

  isModalHeader() {
    return this.state.mobileHeaderVariant === MobileHeaderVariant.modal;
  }

  setFindCardModalContent(modalContent: FeedBackModalContent | null) {
    this.setState({
      findCardModal: modalContent,
      sellCardModal: null,
      previewCardModal: null
    });
  }

  setSellCardModal(modalContent: FeedBackModalContent | null) {
    this.setState({
      sellCardModal: modalContent,
      findCardModal: null,
      previewCardModal: null
    });
  }

  setPreviewCardModal(modalContent: CardPreviewModalContent | null) {
    this.setState({
      previewCardModal: modalContent,
      findCardModal: null,
      sellCardModal: null
    });
  }

  closeModals() {
    this.setState({
      previewCardModal: null,
      findCardModal: null,
      sellCardModal: null
    });
  }
}
