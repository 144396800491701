import { LocationStore } from '@proscom/prostore-react-router';
import { useCallback, useMemo } from 'react';
import { TransformedQueryParams } from '../../store/urlKeys';

export function useLocationState<
  State,
  Key extends keyof Query,
  Query = TransformedQueryParams
>(
  query: Query,
  locationStore: LocationStore,
  queryKey: Key,
  transformer: (value: Query[Key]) => State
) {
  const queryValue = query[queryKey];
  const value = useMemo(() => transformer(queryValue), [
    transformer,
    queryValue
  ]);
  const setCardType = useCallback(
    (value: State) => {
      locationStore.changeQuery({
        [queryKey]: value
      });
    },
    [locationStore, queryKey]
  );
  return [value, setCardType] as const;
}
