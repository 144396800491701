import breakpoints from './_baseBreakpoints.scss';

export function getPxValue() {}

export const MEDIA_QUERIES = {
  mobile: parseFloat(breakpoints.mediaWidthMobile),
  tablet: parseFloat(breakpoints.mediaWidthTablet),
  tablet_land: parseFloat(breakpoints.mediaWidthTabletLand)
};

export const SCROLL_OFFSET = 500;

export const SORT_OPTIONS = [
  {
    id: 0,
    title: 'По изданиям',
    value: '-stock'
  },
  {
    id: 1,
    title: 'Сначала новинки',
    value: '-stock,-release'
  },
  {
    id: 2,
    title: 'По алфавиту',
    value: '-stock,name'
  },
  {
    id: 3,
    title: 'Сначала дешевле',
    value: '-stock,price'
  },
  {
    id: 4,
    title: 'Сначала дороже',
    value: '-stock,-price'
  }
];

export const MANACOST = [
  {
    name: '0 маны',
    id: 0
  },
  {
    name: '0.5 маны',
    id: 0.5
  },
  {
    name: '1 мана',
    id: 1
  },
  {
    name: '2 маны',
    id: 2
  },
  {
    name: '3 маны',
    id: 3
  },
  {
    name: '4 маны',
    id: 4
  },
  {
    name: '5 маны',
    id: 5
  },
  {
    name: '6 маны',
    id: 6
  },
  {
    name: '7 маны',
    id: 7
  },
  {
    name: '8 маны',
    id: 8
  },
  {
    name: '9 маны',
    id: 9
  },
  {
    name: '10 маны',
    id: 10
  },
  {
    name: '11 маны',
    id: 11
  },
  {
    name: '12 маны',
    id: 12
  },
  {
    name: '13 маны',
    id: 13
  },
  {
    name: '14 маны',
    id: 14
  },
  {
    name: '15 маны',
    id: 15
  },
  {
    name: '16 маны',
    id: 16
  },
  {
    name: '1000000 маны',
    id: 1000000
  }
];

export const FORMATS = [
  {
    id: 1,
    name: 'Standard',
    value: 'standard'
  },
  {
    id: 2,
    name: 'Pioneer',
    value: 'pioneer'
  },
  {
    id: 3,
    name: 'Modern',
    value: 'modern'
  },
  {
    id: 4,
    name: 'Legacy',
    value: 'legacy'
  },
  {
    id: 5,
    name: 'Pauper',
    value: 'pauper'
  },
  {
    id: 6,
    name: 'EDH',
    value: 'edh'
  },
  {
    id: 100,
    name: 'Все издания',
    value: undefined
  }
];

export const CARD_STATES = [
  {
    name: 'Новая (GM)',
    value: 'gm'
  },
  {
    name: 'Почти новая (NM)',
    value: 'nm'
  },
  {
    name: 'Мало играли (SP)',
    value: 'sp'
  },
  {
    name: 'Умеренно играли (MP)',
    value: 'mp'
  },
  {
    name: 'Много играли (HP)',
    value: 'hp'
  }
];

export const SETS_TYPES = [
  'expansion',
  'core',
  'commander',
  'masters',
  'masterpiece',
  'draft_innovation'
];

export const SEARCH_PLACEHOLDERS = [
  'Яркий огонь',
  'Элементаль Плюща',
  'Быстрокрылый Пегас',
  'Алчный головорез',
  'Грифон Аббатства',
  'Сила отрицания',
  'Патруль небесных всадников',
  'MH1 217',
  'M19 314',
  'Bound in Gold',
  'Magda, Brazen Outlaw',
  'Icebreaker Kraken',
  'Path of Ancestry',
  'Sol Ring',
  'Snow-Covered Island',
  'Village Rites',
  'Bretagard Stronghold'
];

export const ARROW_LEFT = '\u2190'; // ←
export const ARROW_RIGHT = '\u2192'; // →
export const MAC_OPTION = '\u2325'; // ⌥

export const FILTER_SELECT_TABLET_WIDTH = 302;
export const FILTER_SELECT_DESKTOP_WIDTH = 226;

export const ERROR_VALIDATION_MESSAGE = 'The given data was invalid.';

export enum OrderStatusEnum {
  NEW = 'NEW',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  DELIVERY = 'DELIVERY',
  PAID_STOCK_NEGATIVE = 'PAID_STOCK_NEGATIVE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELLED = 'CANCELLED',
  DONE = 'DONE'
}

export enum PaymentStatusEnum {
  NEW = 'NEW', // Заказ зарегистрирован, но не оплачен
  PREAUTHORIZED = 'PREAUTHORIZED', // Предавторизованная сумма захолдирована (для двухстадийных платежей)
  AUTHORIZED = 'AUTHORIZED', // Проведена полная авторизация суммы заказа
  CANCELLED = 'CANCELLED', // Авторизация отменена
  CANCELLED_FAILED = 'CANCELLED_FAILED', // Ошибка авторизации отменены
  REFUNDED = 'REFUNDED', // По транзакции была проведена операция возврата
  REJECTED = 'REJECTED', // Авторизация отклонена
  FAILED = 'FAILED'
}

export const DELIVERY_LOCAL_MAIL = 'local_mail';
export const DELIVERY_LOCAL_COURIER = 'local_courier';
export const DELIVERY_LOCAL_PICKUP = 'local_pickup';
export const DELIVERY_EMC = 'emc';
export const DELIVERY_DHL = 'dhl';
