import React from 'react';
import { ErrorMessage } from '../ui/ErrorMessage/ErrorMessage';

export interface IErrorComponentProps {
  text?: string;
  description?: string;
}

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  component: React.ComponentType<IErrorComponentProps>;
  renderError?: (props: IErrorComponentProps) => React.ReactNode;
}

export interface IErrorBoundaryState {
  error: any;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, IErrorBoundaryState> {
  state = {
    error: null
  };

  static defaultProps = {
    component: ErrorMessage
  };

  componentDidUpdate(
    prevProps,
    prevState,
    snapshot
  ) {
    if (this.props.children !== prevProps.children) {
      this.setState({ error: null });
    }
  };

  static getDerivedStateFromError(error: any) {
    return { error };
  };

  render() {
    const { error } = this.state;
    const { children, component: ErrorComponent, renderError } = this.props;

    if (error) {
      const props = {
        text: 'Произошла ошибка',
        description: error.toString()
      };

      if (ErrorComponent) {
        return <ErrorComponent {...props} />;
      } else if (renderError) {
        return renderError(props);
      } else {
        return false;
      }
    }

    return children;
  }
}
