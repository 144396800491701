import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as InstagramIcon } from '../../../../assets/icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/facebook.svg';
import { ReactComponent as VKIcon } from '../../../../assets/icons/vk.svg';
import { ReactComponent as TelegramIcon } from '../../../../assets/icons/telegram.svg';

import { Link } from '../Link/Link';
import { Logo } from '../Logo/Logo';
import { CommonStore } from '../../../../store/CommonStore';
import { STORE_COMMON } from '../../../../store/stores';
import { DEFAULT_FIND_CARD_POPUP_CONTENT } from '../Popups/FindCardPopup';
import { DEFAULT_SELL_CARD_POPUP_CONTENT } from '../Popups/SellCardPopup';
import { ContactPhoneLink } from '../Contacts/ContactPhoneLink';
import s from './Footer.module.scss';

export const Footer = () => {
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <footer className={s.Footer}>
      <div className={s.Footer__content}>
        <div className={s.Footer__contentMain}>
          <div className={s.Footer__info}>
            <Logo className={s.Footer__logo} />
            <div className={s.Footer__copyright}>
              © {currentYear} - All Rights Reserved
            </div>
          </div>
          <div className={s.Footer__linksList}>
            <Link to="/cards" className={s.Footer__link}>
              Магазин
            </Link>
            <Link
              to="/deliveryandpayments"
              className={clsx(s.Footer__link, s.Footer__linkDelivery)}
            >
              Доставка и оплата
            </Link>
            <Link
              className={clsx(s.Footer__link, s.Footer__link)}
              onClick={(e) => {
                e.preventDefault();
                commonStore.setFindCardModalContent(
                  DEFAULT_FIND_CARD_POPUP_CONTENT
                );
              }}
            >
              Поиск карточек
            </Link>
            <Link
              to="/policies"
              className={clsx(s.Footer__link, s.Footer__linkDocuments)}
            >
              Юридические документы
            </Link>
            <Link
              className={clsx(s.Footer__link, s.Footer__link)}
              onClick={(e) => {
                e.preventDefault();
                commonStore.setSellCardModal(DEFAULT_SELL_CARD_POPUP_CONTENT);
              }}
            >
              Продажа карточек
            </Link>
          </div>
          <div className={s.Footer__contacts}>
            <ContactPhoneLink className={s.Footer__phone} />
            <div className={s.Footer__address}>
              г. Москва, Малый Дровяной пер., д.6
            </div>
            <div className={s.Footer__socials}>
              <Link
                href="https://instagram.com/club.goldfish"
                rel="noopener noreferrer nofollow"
                target="_blank"
                className={s.Footer__socialLink}
              >
                <InstagramIcon />
              </Link>
              <Link
                href="https://vk.com/magicgoldfish"
                rel="noopener noreferrer nofollow"
                target="_blank"
                className={s.Footer__socialLink}
              >
                <VKIcon />
              </Link>
              <Link
                href="https://t.me/goldfishclub"
                rel="noopener noreferrer nofollow"
                target="_blank"
                className={s.Footer__socialLink}
              >
                <TelegramIcon />
              </Link>
              <Link
                href="https://facebook.com/groups/magicgoldfish"
                rel="noopener noreferrer nofollow"
                target="_blank"
                className={s.Footer__socialLink}
              >
                <FacebookIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className={s.Footer__contentExt}>
          <div className={s.FooterCopy}>
            <a
              className={s.FooterCopy__link}
              href="https://proscom.ru/"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Сделано в <span>Proscom</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
