import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { useDebouncedCallback } from 'use-debounce';
import { STORE_LOCATION } from '../../store/stores';

export function useSearch() {
  const [locationState, locationStore] =
    useStore<LocationStore>(STORE_LOCATION);

  const [search, setSearch] = useState(locationState.query.q || '');
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setSearch(locationState.query.q);
  }, [locationState.query.q]);

  const delayedHandleTextFieldChange = useDebouncedCallback(() => {
    locationStore.changeQuery({
      q: search
    });
    setIsLoading(false);
  }, 1000);

  const handleSearchChange = (value, withLoading = true) => {
    //Не нужно показывать загрузку, когда поле ввода пустое
    setIsLoading(withLoading && !!value.trim());

    setSearch(value);
    delayedHandleTextFieldChange.callback();
  };

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    if (location.pathname !== '/cards') {
      history.push('/cards?q=' + search);
    } else {
      delayedHandleTextFieldChange.cancel();
      locationStore.changeQuery({ q: search });
    }
  };

  const handleQueryLoaded = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (location.pathname === '/cards') {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [search, location.pathname]);

  return {
    search,
    isLoading,
    handleSearchChange,
    handleSearchFormSubmit,
    handleQueryLoaded
  };
}
