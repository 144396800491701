import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isEqual } from 'lodash-es';

export function useDebouncedValue<T>(value: T, delay: number = 1000) {
  const [result, setResult] = useState<T>(value);
  const [pending, setPending] = useState(false);
  const latestValueRef = useRef(value);

  const setResultDebounced = useDebouncedCallback((data: T) => {
    setPending(false);
    setResult(data);
  }, delay);

  useEffect(() => {
    if (!isEqual(latestValueRef.current, value)) {
      latestValueRef.current = value;
      setPending(true);
      setResultDebounced.callback(value);
    }
  }, [value, setResultDebounced]);

  return [result, pending, setResultDebounced] as const;
}
