import React from 'react';
import { createNumberMask } from 'text-mask-addons';
import { Input, InputProps } from './Input';

export const numberMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' '
});

export const NumberInput = ({
  mask,
  onChange,
  value,
  ...props
}: InputProps) => {
  return (
    <Input
      mask={mask || numberMask}
      onChange={(value) => onChange?.(value.replace(/[^0-9]/g, ''))}
      value={value || ''}
      {...props}
    />
  );
};
