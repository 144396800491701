import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { useWindowSize } from '../../../../../utils/helpers/useWindowSize';
import { Tag } from '../../Tag/Tag';
import { Card } from '../Card';
import { ReactComponent as ComingSoonIcon } from '../../../../../assets/images/comingsoon.svg';
import { ReactComponent as NotInSaleIcon } from '../../../../../assets/images/notinsale.svg';
import { Link } from '../../Link/Link';
import { CardSize } from '../CardImage';
import { STORE_LOCATION } from '../../../../../store/stores';
import { CardSetName } from '../../Set/CardSetName';
import { MetaCardStatesList } from '../../CardStates/CardStatesList';
import { useCardTags } from '../../../../hooks/useCardTags';
import { useMetaCardStates } from '../../../../hooks/useCardStates';
import { CardStateVariant } from '../../CardStates/CardState';
import { MetaCardHelper } from '../../../../../utils/helpers/MetaCard';
import { CardBlockProps } from './CardBlock';
import s from './CardBlock.module.scss';

export interface CardDetailedBlockProps extends CardBlockProps {
  actions?: ReactNode;
  isOrderCard?: boolean;
}

export const MetaCardDetailedBlock = ({
  data,
  className,
  isFullWidth
}: CardDetailedBlockProps) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );
  const { isMobile, isTablet, isTabletLand } = useWindowSize();

  const tags = useCardTags(data);

  let cardImageSize = CardSize.small;
  if (isTabletLand || isTablet) {
    cardImageSize = CardSize.xsmall;
  }

  if (isMobile) {
    cardImageSize = CardSize.xxsmall;
  }

  const urlPreserver = locationStore.createUrlPreserver(locationState.query);

  const { commonState } = useMetaCardStates(data);

  return (
    <div
      className={clsx(s.CardBlock, className, {
        [s.CardBlock__isFullWidth]: isFullWidth
      })}
    >
      <div className={s.CardBlock__cardContainer}>
        <Card
          foil={data.is_foil}
          className={s.CardBlock__image}
          images={MetaCardHelper.getImageUris(data)}
          size={cardImageSize}
          enablePreview
        />

        <div className={s.CardBlock__innerTagsList}>
          {tags.map((tag, key) => (
            <Tag key={key} variant={tag.variant}>
              {tag.label}
            </Tag>
          ))}
        </div>
      </div>

      {commonState === CardStateVariant.preorder ||
      commonState === CardStateVariant.preorderFromStock ? (
        <ComingSoonIcon className={s.CardBlock__comingSoon} />
      ) : commonState === CardStateVariant.nonAvailable ? (
        <NotInSaleIcon className={s.CardBlock__comingSoon} />
      ) : null}

      <CardSetName originSet={data.original_set} />

      <div className={s.CardBlock__titleBlock}>
        <Link
          to={urlPreserver(`/cards/${data.id}`)}
          className={s.CardBlock__title}
        >
          {data.name}
        </Link>
      </div>

      <div className={s.CardBlock__actions}>
        <MetaCardStatesList data={data} />
      </div>
    </div>
  );
};
