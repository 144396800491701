import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { useMemo } from 'react';
import { STORE_LOCATION } from '../../store/stores';
import { Artist } from '../../store/query/useArtists';

export const useCheckboxesFromSelect = (
  name: string,
  data: any,
  filteredData: any = null
) => {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );

  if (!filteredData) {
    filteredData = data;
  }

  const value = locationState.query[name];

  const originalList = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((item) => ({
      value: item.id,
      label: item.name
    }));
  }, [data]);

  const list = useMemo(() => {
    if (filteredData === data) {
      return originalList;
    }

    if (!filteredData) {
      return [];
    }

    return filteredData.map((item) => ({
      value: item.id,
      label: item.name
    }));
  }, [data, originalList, filteredData]);

  const addItem = (id: number) => {
    if (value && value.indexOf(id) > -1) {
      return;
    }

    locationStore.changeQuery({
      [name]: value ? [...value, id] : [id]
    });
  };

  const removeItem = (id: number) => {
    locationStore.changeQuery({
      [name]: value.filter((item) => item !== id)
    });
  };

  const selectedItems = useMemo(() => {
    if (
      !data ||
      !locationState.query[name] ||
      locationState.query[name]?.length === 0
    ) {
      return [];
    }

    return locationState.query[name].map((itemId) =>
      originalList.find((item) => item.value === itemId)
    );
  }, [data, originalList, locationState.query, name]);

  return {
    addItem,
    removeItem,
    selectedItems,
    list
  };
};
