import { maxBy, minBy } from 'lodash-es';
import { tryParseIso } from '@proscom/ui-utils-date';
import { CardData } from '../store/interfacesResponse';
import { TagVariant } from '../common/components/ui/Tag/Tag';
import { CardConditionEnum } from '../common/components/ui/Card/CardStatusEnum';
import { IOrderCreateResponse } from '../store/mutation/useOrderCreate';
import { IOrderItem } from '../common/components/ui/Order/OrderDetails';
import { CartStore } from '../store/CartStore';

export interface IMakeTags {
  label: string;
  variant?: TagVariant;
}

export function makeTags(data: CardData, includeLang = false): IMakeTags[] {
  const tags = [];
  const originalCardInfo = data?.original_card;

  if (!originalCardInfo) {
    return [];
  }

  if (includeLang && data.original_card.lang) {
    tags.push({ label: data.original_card.lang, variant: TagVariant.normal });
  }

  if (data.is_foil) {
    tags.push({ label: 'FOIL', variant: TagVariant.foil });
  }
  // if (originalCardInfo.nonfoil) {
  //   tags.push({ label: 'nonfoil', variant: TagVariant.foil });
  // }
  if (data.is_showcase) {
    tags.push({ label: 'Шоукейс', variant: TagVariant.primary });
  }

  return tags;
}

export interface ICardOptions {
  type: CardConditionEnum;
  price: number;
  stock: number;
  coefficient: number;
  isAvailable: boolean;
}

export interface ICardStatus {
  /**
   * Минимальная цена любого из типов
   */
  minPrice: number;
  /**
   * Максимальная цена любого из типов
   */
  maxPrice: number;
  /**
   * Дата релиза
   */
  cardReleaseDate: Date;
  /**
   * Скоро ли в продаже
   */
  comingSoon: boolean;
  /**
   * Продается ли.
   */
  notInSale: boolean;
  /**
   * Есть ли в наличии
   */
  hasStock: boolean;

  cardStatuses: ICardOptions[];

  rawCardStatuses: ICardOptions[];

  invalidPrice: boolean;

  haveValidPrices: boolean;

  data: CardData;
}

export function makeCardStatus(
  data: CardData,
  cartStore?: CartStore
): ICardStatus {
  const conditions = Object.keys(data.state_info) as CardConditionEnum[];
  const cardStatuses: ICardOptions[] = conditions.map((condition) => ({
    type: condition,
    price: data.state_info[condition].price,
    stock: data.state_info[condition].stock,
    coefficient: data.state_info[condition].coefficient,
    isAvailable: !!(
      data.state_info[condition].price >= 1 && data.state_info[condition].stock
    )
  }));

  const availableStatuses = cardStatuses.filter(
    (i) => i.stock || cartStore?.getItemCount(data.id, i.type) > 0
  );
  const cardReleaseDate = tryParseIso(data.original_card?.released_at);
  const now = new Date();

  const invalidPriceStatuses = cardStatuses.filter(
    (i) => i.stock && i.price < 1
  );

  return {
    minPrice:
      minBy<ICardOptions>(
        availableStatuses.filter((status) => status.price),
        (i) => i.price
      )?.price || 0,
    maxPrice: maxBy<ICardOptions>(cardStatuses, (i) => i.price)?.price || 0,
    comingSoon: cardReleaseDate && cardReleaseDate > now,
    cardReleaseDate: cardReleaseDate,
    notInSale: availableStatuses.length === 0,
    hasStock: availableStatuses.length > 0,
    cardStatuses: availableStatuses,
    rawCardStatuses: cardStatuses,
    invalidPrice: invalidPriceStatuses.length === cardStatuses.length,
    haveValidPrices: availableStatuses.some((i) => i.price >= 1),
    data
  };
}

export interface IOrderDetails {
  id: number;
  orderDetails: IOrderItem[];
  totalPrice: number;
  secret: string;
  order: IOrderCreateResponse;
}

export enum OrderDetailsEnum {
  cards = 'cards',
  delivery = 'delivery'
}

export function makeOrderInfo(
  order: IOrderCreateResponse,
  token: string
): IOrderDetails | null {
  const orderInfo: IOrderItem[] = [];
  if (!order) {
    return null;
  }
  // Подсчет карт и вывод цены
  const cardTotal = order.card_price;
  let totalCards = 0;
  order.order_cards.forEach((card) => {
    Object.values(CardConditionEnum).forEach((condition) => {
      totalCards += card[`quantity_${condition}`];
    });
  });
  orderInfo.push({
    title: `Карты (${totalCards} шт)`,
    price: cardTotal,
    type: OrderDetailsEnum.cards
  });

  if (order.delivery_method_price) {
    orderInfo.push({
      title: `Доставка`,
      price: order.delivery_price,
      type: OrderDetailsEnum.delivery
    });
  }

  return {
    id: order.id,
    orderDetails: orderInfo,
    totalPrice: order.total_price,
    secret: token,
    order
  };
}

export function getStockDescription(inStock: number, inCart: number) {
  return inCart === 0 && inStock === 0
    ? ''
    : inCart && inStock === 0
    ? 'Нет в наличии'
    : inCart > inStock
    ? `[!] Осталось только ${inStock} шт`
    : inCart === inStock
    ? 'Добавлено все что есть'
    : `Есть еще ${inStock - inCart} шт`;
}

export interface ICardForTextarea {
  id: number;
  title: string;
  condition?: CardConditionEnum;
  isFoil?: boolean;
}

export function getCardForTextarea({
  id,
  title,
  condition,
  isFoil
}: ICardForTextarea) {
  const conditionString = condition ? ` (${condition.toUpperCase()})` : '';
  const foilString = isFoil ? ' [foil]' : '';

  let totalStr = `${title}${conditionString}${foilString}`;

  if (id) {
    totalStr = `${id} - ${totalStr}`;
  }

  return totalStr;
}

export function isCardPreOrder(releaseDate: string) {
  return new Date(releaseDate) > new Date();
}
