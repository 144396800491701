import { AxiosRequestConfig } from 'axios';
import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { skipIfNull } from '@proscom/prostore';
import { apiUrl } from '../../config';
import { OriginalSet } from '../interfacesResponse';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  url: `${apiUrl}/sets/newest`,
  method: 'get'
};

export interface NewestSetsQueryVars {
  filter?: {
    format?: string;
  };
}

const queryOptions: IUseAxiosQueryOptions<
  NewestSetsQueryVars,
  OriginalSet[]
> = {
  query,
  mapData: (result) => result.data,
  mapVariables: (variables) => ({ params: variables })
};

export function useNewestSets(variables?: NewestSetsQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables: variables
  });
}
