import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { EMDASH } from '@proscom/ui-utils';
import AppRoutes from './routes';
import './App.scss';
import { CardPreviewModal } from './common/components/ui/Card/CardPreviewModal';
import { FindCardPopup } from './common/components/ui/Popups/FindCardPopup';
import { SellCardPopup } from './common/components/ui/Popups/SellCardPopup';

const siteTitle = 'Goldfish';

export function App() {
  return (
    <Fragment>
      <Helmet
        defaultTitle={siteTitle}
        titleTemplate={`%s ${EMDASH} ${siteTitle}`}
      />
      <AppRoutes />
      <ToastContainer hideProgressBar closeButton limit={3} closeOnClick />
      <CardPreviewModal />
      <FindCardPopup />
      <SellCardPopup />
    </Fragment>
  );
}
