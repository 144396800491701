import React from 'react';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError, useStore } from '@proscom/prostore-react';
import { Helmet } from 'react-helmet';
import { useCardFeedback } from '../../../../store/mutation/useCardFeedback';
import { CommonStore } from '../../../../store/CommonStore';
import { STORE_COMMON } from '../../../../store/stores';
import {
  FormValidationError,
  FrontendValidationError
} from '../../../../utils/error/FormValidationError';
import { applyFormValidationErrors, toastError } from '../../../../utils/log';
import { CardBasePopup, IPopupFormData } from './CardBasePopup';

export const DEFAULT_SELL_CARD_POPUP_CONTENT = {
  title: 'Продайте ваши карточки',
  description:
    'Отправьте нам список карточек, которые вы хотите продать. Мы свяжемся с вами в ближайшее время!',
  buttonText: 'Отправить список карточек'
};

export function SellCardPopup() {
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const { run } = useCardFeedback();

  const onSubmitSell = useCallback(
    (data: IPopupFormData, formMethods) => {
      run({
        name: data.name,
        email: data.email,
        body: data.cards,
        to_sell: true
      })
        .then(() => {
          toast('Список карт успешно отправлен');
          commonStore.setSellCardModal(null);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;

          applyFormValidationErrors(formMethods, err);

          toastError(err);
        });
    },
    [run, commonStore]
  );

  return (
    <>
      {!!commonStoreState.sellCardModal && (
        <Helmet title={commonStoreState.sellCardModal?.title} />
      )}
      <CardBasePopup
        isOpen={!!commonStoreState.sellCardModal}
        title={commonStoreState.sellCardModal?.title}
        onSubmit={onSubmitSell}
        onRequestClose={() => {
          if (commonStoreState.sellCardModal) {
            commonStore.setSellCardModal(null);
          }
        }}
        description={commonStoreState.sellCardModal?.description}
        buttonText={commonStoreState.sellCardModal?.buttonText}
        textareaDefaultValue={
          commonStoreState.sellCardModal?.textareaDefaultValue
        }
      />
    </>
  );
}
