import { useMemo } from 'react';
import { tryParseIso } from '@proscom/ui-utils-date';
import { useStore } from '@proscom/prostore-react';
import {
  CardConditionStock,
  CardData,
  MetaCard
} from '../../store/interfacesResponse';
import { CardConditionEnum } from '../components/ui/Card/CardStatusEnum';
import { CardStateVariant } from '../components/ui/CardStates/CardState';
import { STORE_CART } from '../../store/stores';
import { CartStore } from '../../store/CartStore';
import { MetaCardHelper } from '../../utils/helpers/MetaCard';
import { firstSymbolUppercase } from '../../utils/helpers/StringHelper';
import { CardHelper } from '../../utils/helpers/Card';

export interface ICardState {
  state: CardStateVariant;
  type?: CardConditionEnum;
  price?: number;
  stock?: number;
  conditionData?: CardConditionStock;
  language?: string;
  id?: number;
}

export type TPriceRange = {
  minAvailable: number | null;
  max: number;
};

export type TCondition = {
  id: number;
  type: CardConditionEnum;
  language: string;
  originLanguage: string;
  price: number;
  stock: number;
  isAvailable: boolean;
  isValid: boolean;
  conditionData: CardConditionStock;
};

export interface ICardStatesData {
  commonState: CardStateVariant;
  states: ICardState[];
  allStates?: TCondition[];
  priceRange?: TPriceRange;
  availableStates?: TCondition[];
  validStates?: TCondition[];
  minPreorderPrice?: number;
}

export function useMetaCardStates(metaCardData: MetaCard): ICardStatesData {
  const [allStates, availableStates, validStates, priceRange] = useMemo(() => {
    let _priceRange: TPriceRange = {
      minAvailable: null, // минимальная цена из доступных на складе
      max: null // максимальная цена всех состояний
    };

    const _allStates = [],
      _availableStates = [],
      _validStates = [];

    metaCardData.cards.forEach((card) => {
      for (const condition in CardConditionEnum) {
        const { price, stock } = card.state_info[condition];
        const isValidPrice = price >= 1;

        if (
          stock &&
          price > 0 &&
          (_priceRange.minAvailable === null ||
            price < _priceRange.minAvailable)
        ) {
          _priceRange.minAvailable = price;
        }

        if (_priceRange.max === null || price > _priceRange.max) {
          _priceRange.max = price;
        }

        const conditionItem: TCondition = {
          id: card.id,
          language: card.original_card.front_lang,
          originLanguage: card.original_card.lang,
          type: condition as CardConditionEnum,
          price,
          stock,
          isAvailable: Boolean(stock),
          isValid: isValidPrice,
          conditionData: card.state_info[condition]
        };

        _allStates.push(conditionItem);
        if (conditionItem.isAvailable) {
          _availableStates.push(conditionItem);
        }

        if (conditionItem.isValid) {
          _validStates.push(conditionItem);
        }
      }
    });

    return [_allStates, _availableStates, _validStates, _priceRange];
  }, [metaCardData]);

  const cardReleaseDate = tryParseIso(metaCardData.released_at);
  const today = new Date();

  /* Если дата релиза карты больше сегодняшней даты, то это предзаказ */
  if (cardReleaseDate > today) {
    let minPreorderPrice = null;

    for (const card of metaCardData.cards) {
      // Если предзаказ без стока, то берем в сравнение еще и значение, указанное в fixed_price
      const cardMinimumPrice = CardHelper.getMinimumPrice(
        card,
        !!availableStates.length
      );
      if (minPreorderPrice === null || cardMinimumPrice < minPreorderPrice) {
        minPreorderPrice = cardMinimumPrice;
      }
    }

    if (availableStates.length) {
      return {
        commonState: CardStateVariant.preorderFromStock,
        states: availableStates.map((state) => ({
          id: state.id,
          state: CardStateVariant.preorderState,
          language: state.language,
          type: state.type,
          price: state.price,
          stock: state.stock
        })),
        availableStates,
        validStates,
        allStates,
        minPreorderPrice
      };
    }

    return {
      commonState: CardStateVariant.preorder,
      states: [
        {
          state: CardStateVariant.preorder,
          language: MetaCardHelper.getCardPreorderLanguages(metaCardData)
            .map((lang) => firstSymbolUppercase(lang))
            .join(', ')
        }
      ],
      minPreorderPrice,
      allStates,
      availableStates
    };
  }

  /* Если нет карточек на складе ни для одного состояния */
  if (availableStates.length === 0) {
    return {
      commonState: CardStateVariant.nonAvailable,
      states: [
        {
          state: CardStateVariant.nonAvailable,
          price: priceRange.max
        }
      ],
      priceRange,
      allStates
    };
  }

  /* Если у всех состояний карточки указана неверная цена */
  if (availableStates.length && validStates.length === 0) {
    return {
      commonState: CardStateVariant.invalidAllStates,
      states: [
        {
          state: CardStateVariant.invalidAllStates
        }
      ],
      allStates
    };
  }

  /* Во всех остальных вариантах выводим все доступные на складе состояния (если цена невалидная, ставим соответствующий статус) */
  return {
    commonState: validStates.length
      ? CardStateVariant.normal
      : CardStateVariant.invalidPrice,
    states: availableStates.map((state) => {
      return {
        state: state.isValid
          ? CardStateVariant.normal
          : CardStateVariant.invalidPrice,
        type: state.type,
        price: state.price,
        stock: state.stock,
        language: state.language,
        id: state.id
      };
    }),
    priceRange,
    availableStates,
    validStates,
    allStates
  };
}

export function useCardStates(
  cardData: CardData,
  onlyCart: boolean = false
): ICardStatesData {
  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const cart = cartStore.getItems();

  const cardInCart = useMemo(() => {
    if (!onlyCart) return {};

    return (
      cart.find((cartCard) => cartCard.id === cardData.id)?.conditions || {}
    );
  }, [onlyCart, cardData, cart]);

  const conditions = onlyCart
    ? Object.keys(cardInCart)
    : (Object.keys(cardData.state_info) as CardConditionEnum[]);
  const states = cardData.state_info;

  const [allStates, availableStates, validStates, priceRange] = useMemo(() => {
    let _priceRange: TPriceRange = {
      minAvailable: null, // минимальная цена из доступных на складе
      max: null // максимальная цена всех состояний
    };

    const _availableStates: TCondition[] = [],
      _validStates: TCondition[] = [];

    const _allStates: TCondition[] = conditions.map((condition) => {
      const price = states[condition].price;
      const stock = states[condition].stock;
      const isValidPrice = price >= 1;

      if (
        stock &&
        price > 0 &&
        (_priceRange.minAvailable === null || price < _priceRange.minAvailable)
      ) {
        _priceRange.minAvailable = price;
      }

      if (_priceRange.max === null || price > _priceRange.max) {
        _priceRange.max = price;
      }

      const conditionItem: TCondition = {
        id: cardData.id,
        language: cardData.original_card.front_lang,
        originLanguage: cardData.original_card.lang,
        type: condition as CardConditionEnum,
        price,
        stock,
        isAvailable: Boolean(stock),
        isValid: isValidPrice,
        conditionData: cardData?.state_info[condition]
      };

      if (conditionItem.isAvailable) {
        _availableStates.push(conditionItem);
      }

      if (conditionItem.isValid) {
        _validStates.push(conditionItem);
      }

      return conditionItem;
    });

    return [_allStates, _availableStates, _validStates, _priceRange];
  }, [states, conditions, cardData]);

  const cardReleaseDate = tryParseIso(cardData.original_card?.released_at);
  const today = new Date();

  /* Если дата релиза карты больше сегодняшней даты, то это предзаказ */
  if (cardReleaseDate > today) {
    if (availableStates.length) {
      return {
        commonState: CardStateVariant.preorderFromStock,
        states: availableStates.map((state) => ({
          id: state.id,
          state: CardStateVariant.preorderState,
          language: state.language,
          type: state.type,
          price: state.price,
          stock: state.stock
        }))
      };
    }

    return {
      commonState: CardStateVariant.preorder,
      states: [
        {
          state: CardStateVariant.preorder
        }
      ],
      allStates
    };
  }

  /* Если нет карточек на складе ни для одного состояния */
  if (availableStates.length === 0) {
    return {
      commonState: CardStateVariant.nonAvailable,
      states: [
        {
          state: CardStateVariant.nonAvailable,
          price: priceRange.max
        }
      ],
      priceRange,
      allStates
    };
  }

  /* Если у всех состояний карточки указана неверная цена */
  if (availableStates.length && validStates.length === 0) {
    return {
      commonState: CardStateVariant.invalidAllStates,
      states: [
        {
          state: CardStateVariant.invalidAllStates
        }
      ],
      allStates
    };
  }

  /* Во всех остальных вариантах выводим все доступные на складе состояния (если цена невалидная, ставим соответствующий статус) */
  return {
    commonState: validStates.length
      ? CardStateVariant.normal
      : CardStateVariant.invalidPrice,
    states: availableStates.map((state) => {
      return {
        state: state.isValid
          ? CardStateVariant.normal
          : CardStateVariant.invalidPrice,
        type: state.type,
        price: state.price,
        stock: state.stock
      };
    }),
    priceRange,
    availableStates,
    validStates,
    allStates
  };
}
