import React, { ChangeEvent, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';

import s from './Checkbox.module.scss';

export interface CheckboxProps {
  /**
   * Состояние чекбокса
   */
  checked?: boolean;

  /**
   * Неактивное состояние
   */
  disabled?: boolean;

  /**
   * Коллбек, который срабатывает при изменении состояния чекбокса
   * @param value
   * @param e
   */
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;

  /**
   * Наименование инпута
   */
  name?: string;

  /**
   * Проброс реф ссылки
   */
  inputRef?: React.Ref<HTMLInputElement>;

  /**
   * Произвольный класс для элемента
   */
  className?: string;

  /**
   * Пропы для родительского элемента
   */
  rootProps?: React.HTMLProps<HTMLLabelElement>;

  /**
   * Пояснение к чекбоксу
   */
  children?: ReactNode;

  /**
   * Произвольные классы для внутренних элементов
   */
  classes?: {
    input?: string;
    label?: string;
  };
}

export const Checkbox = ({
  checked = false,
  disabled = false,
  onChange,
  className,
  classes,
  rootProps,
  inputRef,
  children,
  ...props
}: CheckboxProps) => {
  const onChangeHandler = onChange
    ? (e: ChangeEvent<HTMLInputElement>) => {
        onChange(!checked, e);
      }
    : undefined;

  return (
    <label
      className={clsx(s.Checkbox, className, {
        [s.Checkbox_checked]: checked,
        [s.Checkbox_disabled]: disabled
      })}
      {...rootProps}
    >
      <input
        type="checkbox"
        className={clsx(s.Checkbox__input, classes?.input)}
        checked={checked}
        ref={inputRef}
        disabled={disabled}
        onChange={onChangeHandler}
        {...props}
      />

      <div className={s.Checkbox__icon}>
        <CheckIcon />
      </div>

      {children && (
        <div className={clsx(s.Checkbox__label, classes?.label)}>
          {children}
        </div>
      )}
    </label>
  );
};
