import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { useAsyncOperation } from '@proscom/prostore-react';
import { AxiosResponse } from 'axios';
import { apiUrl } from '../../config';
import { ensureTrue } from '../../utils/error/UnexpectedResultError';
import { ApiResponse } from '../interfacesResponse';

export interface IPaymentCreateResponse extends ApiResponse {
  payment?: {
    id: number;
    is_offline: boolean;
    link: string;
  };
}

export interface IPaymentCreateVars {
  pay_personal?: boolean;
}

export function usePaymentCreate(id: number, secret: string) {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (data: IPaymentCreateVars = {}): Promise<IPaymentCreateResponse> => {
      const methodUrl = `/orders/${id}/payment`;
      return client
        .post(apiUrl + methodUrl, data, {
          params: {
            secret: secret
          }
        })
        .then((response: AxiosResponse<IPaymentCreateResponse>) => {
          const t = response.data;
          ensureTrue(t.success, `Call to \`${methodUrl}\` was not successful`);
          return t;
        });
    },
    {
      finishedTimeout: 5000,
      singleton: true
    }
  );
}
