import React from 'react';
import clsx from 'clsx';

import s from './Amount.module.scss';

export enum AmountSize {
  small = 'small',
  medium = 'medium'
}

export interface AmountProps {
  /**
   * Значение бейджика
   */
  value: number;

  /**
   * Размер ("small", "medium")
   */
  size?: AmountSize;

  /**
   * Произвольный класс
   */
  className?: string;
}

export const Amount = ({
  value,
  className,
  size = AmountSize.medium,
  ...props
}: AmountProps) => {
  return (
    <span
      className={clsx(s.Amount, s[`Amount_size_${size}`], className)}
      {...props}
    >
      {value > 0 ? value : 0}
    </span>
  );
};
