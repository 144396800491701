import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { Button } from '../Button/Button';
import { ReactComponent as ArrowBackIcon } from '../../../../assets/icons/arrow_back.svg';
import { ReactComponent as ArrowForwardIcon } from '../../../../assets/icons/arrow_forward.svg';

import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import s from './IndexPageBlock.module.scss';

export interface IndexPageBlockProps {
  header: string;
  actionLink: React.ReactNode;
  blockRef: React.RefObject<HTMLDivElement>;
  invert?: boolean;
  className?: string;
  showArrows?: boolean;
  onBackClick?: () => void;
  onForwardClick?: () => void;
  children?: React.ReactNode;
  classes?: {
    header?: string;
    linkBlock?: string;
  };
}

export const IndexPageBlock = ({
  header,
  actionLink,
  className,
  showArrows = false,
  onBackClick,
  onForwardClick,
  children,
  invert,
  classes,
  blockRef
}: IndexPageBlockProps) => {
  const { isMobile } = useWindowSize();

  return (
    <div
      className={clsx(s.IndexPageBlock, className, {
        [s.IndexPageBlock_invert]: invert
      })}
      ref={blockRef}
    >
      <div className={clsx(s.IndexPageBlock__header, classes?.header)}>
        {header}
      </div>
      <div className={s.IndexPageBlock__actions}>
        {actionLink}
        {!isMobile && showArrows && (
          <>
            <button
              className={s.IndexPageBlock__button}
              onClick={() => onBackClick?.()}
            >
              <ArrowBackIcon />
            </button>
            <button
              className={s.IndexPageBlock__button}
              onClick={() => onForwardClick?.()}
            >
              <ArrowForwardIcon />
            </button>
          </>
        )}
      </div>
      <div className={s.IndexPageBlock__content}>{children}</div>
    </div>
  );
};
