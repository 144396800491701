import React from 'react';
import { RUBLE } from '@proscom/ui-utils';
import StarImage from '../../../../assets/images/star.svg';
import { Tag, TagSize, TagVariant } from '../Tag/Tag';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { prettyNumber } from '../../../../utils/number';
import s from './SoldCardBanner.module.scss';

export interface SoldCardBannerProps {
  card: React.ReactNode;
  minPrice: number;
  actionButton: React.ReactNode;
}

export const SoldCardBanner = ({
  card,
  minPrice = 1,
  actionButton
}: SoldCardBannerProps) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={s.SoldCardBanner}>
      <div className={s.MainBanner__content}>
        <img src={StarImage} alt="" className={s.SoldCardBanner__decoration} />
        <div className={s.SoldCardBanner__card}>{card}</div>
        <div className={s.SoldCardBanner__title}>
          Продавайте свои карточки у нас
        </div>
        <div className={s.SoldCardBanner__description}>
          У нас вы можете продать карточки по выгодной цене. Отправьте нам
          список карт, которые вы хотите продать, и мы рассчитаем их стоимость
        </div>
        <div className={s.SoldCardBanner__actionButton}>{actionButton}</div>
        {!isMobile && (
          <Tag
            className={s.SoldCardBanner__price}
            variant={TagVariant.secondary}
            size={TagSize.large}
          >
            от {prettyNumber(minPrice, RUBLE)}
          </Tag>
        )}
      </div>
    </div>
  );
};
