import React from 'react';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { Button } from '../../../common/components/ui/Button/Button';
import { OrderStatusEnum } from '../../../constants';
import { IOrderDetails } from '../../../utils/data';
import { ContactPhoneLink } from '../../../common/components/ui/Contacts/ContactPhoneLink';
import { ContactEmailLink } from '../../../common/components/ui/Contacts/ContactEmailLink';
import { Spinner } from '../../../common/components/ui/Spinner/Spinner';
import { StepBlock, StepsType } from './StepBlock/StepBlock';
import s from '../common.module.scss';

function getErrorMessage(orderInfo?: IOrderDetails) {
  const id = orderInfo?.id;
  const status = orderInfo?.order.status;
  if (status === OrderStatusEnum.PAYMENT_FAILED) {
    return {
      title: 'При оплате произошла ошибка',
      description: (
        <p>
          К сожалению, при оплате произошла ошибка. Пожалуйста, вернитесь на
          страницу оплаты и попробуйте еще раз. Вы также можете связаться с нами
          по телефону <ContactPhoneLink /> или по почте{' '}
          <ContactEmailLink orderId={id} />
        </p>
      )
    };
  }
  if (status === OrderStatusEnum.CANCELLED) {
    return {
      title: 'Заказ отменён',
      description: (
        <p>
          Ваш заказ был отменён менеджером. Если это ошибка, то свяжитесь с нами
          по телефону <ContactPhoneLink /> или по почте{' '}
          <ContactEmailLink orderId={id} />
        </p>
      )
    };
  }
  if (status === OrderStatusEnum.WAITING_FOR_PAYMENT) {
    return {
      title: 'Платёж обрабатывается',
      description: (
        <>
          <Spinner loading />
          <p>
            Если платёж обрабатывается слишком долго, а деньги не списались, то
            возможно вы не завершили его. В таком случае, вернитесь на шаг
            оплаты и попробуйте ещё раз.
          </p>
        </>
      )
    };
  }
  return {
    title: 'Произошла ошибка',
    description: (
      <p>
        Произошла ошибка при оформлении заказа. Пожалуйста, свяжитесь с нами по
        телефону <ContactPhoneLink /> или по почте{' '}
        <ContactEmailLink orderId={id} />
      </p>
    )
  };
}

export function OrderPaymentError({
  orderInfo,
  enabledSteps
}: {
  orderInfo: IOrderDetails;
  enabledSteps?: StepsType;
}) {
  const { isMobile } = useWindowSize();

  const { title, description } = getErrorMessage(orderInfo);
  const orderId = orderInfo?.id;

  return (
    <div className={s.OrderPage}>
      {!isMobile && (
        <StepBlock
          step={4}
          error={
            orderInfo?.order.status === OrderStatusEnum.CANCELLED ? [4] : [3, 4]
          }
          enabledSteps={enabledSteps}
        />
      )}
      <div className={s.OrderDetails}>
        <div className={s.OrderDetails__title}>{title}</div>
        <div className={s.OrderDetails__description}>
          {description}
          <p>
            Номер вашего заказа:
            <span className={s.OrderDetails__textAccent}>{orderId}</span>
          </p>
        </div>
      </div>
      {orderInfo.order?.status === OrderStatusEnum.CANCELLED && (
        <Button to={`/cart`}>Вернуться в корзину</Button>
      )}
      {[
        OrderStatusEnum.PAYMENT_FAILED,
        OrderStatusEnum.WAITING_FOR_PAYMENT
      ].includes(orderInfo.order?.status) && (
        <Button to={`/orders/${orderInfo.id}?step=3&token=${orderInfo.secret}`}>
          Вернуться к оплате
        </Button>
      )}
    </div>
  );
}
