import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { CardData, MetaCard } from '../interfacesResponse';
import { apiUrl } from '../../config';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  method: 'get'
};

export interface SimilarCardsQueryProps {
  include?: 'originalCard' | 'originalCard.originalSet';
}

export interface SimilarCardsQueryVars {
  url: string;
  params: SimilarCardsQueryProps;
}
const queryOptions: IUseAxiosQueryOptions<SimilarCardsQueryVars, MetaCard[]> = {
  query,
  mapData: (result) => result.data
};

export function useSimilarCards(
  cardId: number | string,
  variables?: SimilarCardsQueryProps
) {
  return useAxiosQuery({
    queryOptions,
    variables: {
      url: `${apiUrl}/meta-cards/${cardId}/similar/`,
      params: {
        include: 'originalCard',
        ...variables
      }
    }
  });
}
