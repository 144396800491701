import React from 'react';
import clsx from 'clsx';
import { Link, LinkProps } from '../Link/Link';
import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg';

import s from './BackButton.module.scss';

export const BackButton = ({ className, children, ...props }: LinkProps) => {
  return (
    <Link className={clsx(s.BackButton, className)} {...props}>
      <BackIcon className={s.BackButton__icon} />
      {children}
    </Link>
  );
};
