import { tryNumber } from '@proscom/ui-utils';

function env<T = string | undefined>(
  variable: string | undefined,
  defaultValue?: T
) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

export const apiUrl = env(process.env.REACT_APP_API_URL);

export const priceUpdateTime = env(
  process.env.REACT_APP_PRICE_UPDATE_TIME,
  '03:00:00+0300'
);

export const yandexMetrikaId = env(process.env.REACT_APP_METRIKA_ID, '');

export const hideCardTypesFilter =
  env(process.env.REACT_APP_HIDE_CARD_TYPES_FILTER, '') === 'true';

export const priceUpdateUncertainty = tryNumber(
  process.env.REACT_APP_PRICE_UPDATE_UNCERTAINTY,
  300
);
