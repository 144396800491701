import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Spinner } from '../Spinner/Spinner';
import s from './Autocomplete.module.scss';

export interface AutocompleteProps {
  options: string[];
  onOptionClick: (option: string) => void;
  optionUrlFunc: (value: string) => string;
  isLoading?: boolean;
  className?: string;
  onEnterPressed?: (e: KeyboardEvent) => void;
}

export function Autocomplete({
  options,
  onOptionClick,
  onEnterPressed,
  isLoading,
  optionUrlFunc,
  className
}: AutocompleteProps) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  useEffect(() => {
    setSelectedOptionIndex(-1);
  }, [options, isLoading]);

  const handleArrowKeyDown = useCallback(
    (e) => {
      if (
        (e.key === 'ArrowDown' || e.key === 'ArrowUp') &&
        selectedOptionIndex === -1
      ) {
        setSelectedOptionIndex(0);
        return;
      }

      if (e.key === 'ArrowDown') {
        if (selectedOptionIndex < options.length - 1) {
          setSelectedOptionIndex(selectedOptionIndex + 1);
        } else {
          setSelectedOptionIndex(0);
        }
      } else if (e.key === 'ArrowUp') {
        if (selectedOptionIndex > 0) {
          setSelectedOptionIndex(selectedOptionIndex - 1);
        } else {
          setSelectedOptionIndex(options.length - 1);
        }
      } else if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
        if (selectedOptionIndex >= 0 && selectedOptionIndex < options.length) {
          e.preventDefault();
          onOptionClick(options[selectedOptionIndex]);
        }
      }

      if (e.key === 'Enter') {
        onEnterPressed?.(e);
      }
    },
    [options, selectedOptionIndex, onOptionClick, onEnterPressed]
  );

  useEffect(() => {
    document.body.addEventListener('keydown', handleArrowKeyDown);

    return () =>
      document.body.removeEventListener('keydown', handleArrowKeyDown);
  }, [handleArrowKeyDown]);

  const handleOptionClick = useCallback(
    (name, e) => {
      e.preventDefault();
      onOptionClick(name);
    },
    [onOptionClick]
  );

  /**
   * prevents search-input blur event on option click
   */
  const handleOptionMouseDown = useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <div className={clsx(s.Autocomplete, className)}>
      {isLoading ? (
        <Spinner loading />
      ) : (
        options.map((name, optionIndex) => (
          <Link
            key={optionIndex}
            className={clsx(s.Autocomplete__option, {
              [s.Autocomplete__option_focus]:
                optionIndex === selectedOptionIndex
            })}
            to={optionUrlFunc(name)}
            onClick={(e) => handleOptionClick(name, e)}
            onMouseDown={handleOptionMouseDown}
            tabIndex={-1}
          >
            {name}
          </Link>
        ))
      )}
    </div>
  );
}
