import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import s from './OrderLayout.module.scss';

export interface OrderLayoutProps {
  title?: string;
  rightBlock?: ReactNode;
  mobileBottomBlock?: ReactNode;
  contentAnnotation?: ReactNode;
  backLink?: ReactNode;
  contentClassName?: string;
  children?: ReactNode;
}

export function OrderLayout({
  title,
  rightBlock,
  mobileBottomBlock,
  backLink,
  contentAnnotation,
  contentClassName,
  children
}: OrderLayoutProps) {
  const { isDesktop } = useWindowSize();

  return (
    <div className={s.OrderLayout__layout}>
      <div className={s.OrderLayout}>
        {backLink && <div className={s.OrderLayout__backLink}>{backLink}</div>}
        <h1 className={s.OrderLayout__title}>{title}</h1>
        {contentAnnotation && (
          <div className={s.OrderLayout__annotation}>{contentAnnotation}</div>
        )}
        <div className={s.OrderLayout__contentLayout}>
          <div className={clsx(s.OrderLayout__content, contentClassName)}>
            {children}
          </div>
          {isDesktop && rightBlock}
        </div>
      </div>

      {!isDesktop && rightBlock}
    </div>
  );
}
