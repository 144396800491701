import { EnumHelpers } from '@proscom/ui-utils';

export class CardFormat {
  static CardFormats = {
    standard: 'standard',
    pioneer: 'pioneer',
    modern: 'modern',
    legacy: 'legacy',
    pauper: 'pauper',
    edh: 'edh'
  };

  static cardFormats = new EnumHelpers(CardFormat.CardFormats, {
    standard: { name: 'Standard' },
    pioneer: { name: 'Pioneer' },
    modern: { name: 'Modern' },
    legacy: { name: 'Legacy' },
    pauper: { name: 'Pauper' },
    edh: { name: 'EDH' }
  });
}
