import React, { useCallback, useEffect, useMemo } from 'react';
import {
  compareSimple,
  makeComparator,
  makeMultiComparator,
  RUBLE
} from '@proscom/ui-utils';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { Helmet } from 'react-helmet';
import { Tag, TagSize } from '../../../common/components/ui/Tag/Tag';
import {
  Button,
  ButtonSize,
  ButtonVariant
} from '../../../common/components/ui/Button/Button';
import { ButtonsRadio } from '../../../common/components/ui/Button/ButtonsRadio/ButtonsRadio';
import { Card } from '../../../common/components/ui/Card/Card';
import { CardData, MetaCard } from '../../../store/interfacesResponse';
import {
  CardConditionEnum,
  CardConditionType
} from '../../../common/components/ui/Card/CardStatusEnum';
import {
  NumberSlider,
  NumberSliderSize,
  NumberSliderVariant
} from '../../../common/components/ui/NumberSlider/NumberSlider';
import { getCardForTextarea } from '../../../utils/data';
import { Link } from '../../../common/components/ui/Link/Link';
import { CardRarity } from '../../../store/filterOptions/CardRarity';
import { prettyNumber } from '../../../utils/number';
import { STORE_COMMON } from '../../../store/stores';
import { CardSize } from '../../../common/components/ui/Card/CardImage';
import { CommonStore } from '../../../store/CommonStore';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import {
  DEFAULT_FIND_CARD_POPUP_CONTENT,
  DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT,
  DEFAULT_PREORDER_POPUP_CONTENT
} from '../../../common/components/ui/Popups/FindCardPopup';
import { CardHelper } from '../../../utils/helpers/Card';
import { CartStockTextState, useCart } from '../../../common/hooks/useCart';
import { CardSetName } from '../../../common/components/ui/Set/CardSetName';
import {
  TCondition,
  useMetaCardStates
} from '../../../common/hooks/useCardStates';
import { CardStateVariant } from '../../../common/components/ui/CardStates/CardState';
import { useCardTags } from '../../../common/hooks/useCardTags';
import { CardLanguages } from '../../../store/filterOptions/CardLanguages';
import { OriginalCardHelper } from '../../../utils/helpers/OriginalCard';
import { URL_KEY_CARD_ID, URL_KEY_CARD_STATE } from '../../../store/urlKeys';
import { useLocationState } from '../../../common/hooks/useLocationState';
import { useLocationStore } from '../../../common/hooks/useLocationQuery';
import { MetaCardHelper } from '../../../utils/helpers/MetaCard';
import s from './CardInfo.module.scss';

export interface ICardOption extends TCondition {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface CardStateProps {
  id: number;
  cardType: CardConditionEnum;
  store: CommonStore;
  card: CardData;
  price: number;
  stock: number;
  isPreorder?: boolean;
}

export function CardState({
  id,
  cardType,
  store,
  price,
  stock,
  isPreorder,
  card
}: CardStateProps) {
  const { isMobile } = useWindowSize();

  const { getCartCount, getConditionStockText, handleChangeCart } = useCart();
  const cartCount = getCartCount(id, cardType);

  const conditionStockText = getConditionStockText(id, cardType, stock);

  return (
    <>
      <div className={s.CardInfo__availability}>
        {conditionStockText === CartStockTextState.allInCart ? (
          'Добавлено все, что есть'
        ) : conditionStockText === CartStockTextState.inCart ? (
          <>
            Осталось еще <span>{stock - cartCount} шт</span>
          </>
        ) : (
          <>
            В наличии: <span>{stock} шт</span>
          </>
        )}
      </div>
      {isPreorder && (
        <div className={s.CardInfo__notAvailableTitle}>
          Новинка! Скоро поступит в продажу. Оформите предзаказ, чтобы оказаться
          в числе первых кто получит эту карточку.
        </div>
      )}
      {price >= 1 && (
        <div
          className={clsx(s.CardInfo__price, {
            [s.CardInfo__price_secondary]: isPreorder
          })}
        >
          {prettyNumber(price, RUBLE)}
        </div>
      )}
      {price >= 1 ? (
        <div className={s.CardInfo__addCard}>
          {cartCount === 0 ? (
            <Button
              onClick={() => {
                handleChangeCart({
                  cardId: id,
                  condition: cardType,
                  value: 1,
                  stockCount: stock
                });
              }}
              size={isMobile ? ButtonSize.small : ButtonSize.medium}
              fullWidth={isMobile}
              variant={
                isPreorder ? ButtonVariant.secondary : ButtonVariant.primary
              }
            >
              {isPreorder ? 'Предзаказать' : 'Добавить в корзину'}
            </Button>
          ) : (
            <NumberSlider
              className={s.CardInfo__cartCount}
              onChange={(value) => {
                handleChangeCart({
                  cardId: id,
                  condition: cardType,
                  stockCount: stock,
                  value
                });
              }}
              size={isMobile ? NumberSliderSize.small : NumberSliderSize.large}
              value={cartCount}
              max={stock}
              formatValue={(value) =>
                isMobile ? `В корзине ${value} шт` : `${value} шт`
              }
              variant={
                isPreorder
                  ? NumberSliderVariant.secondary
                  : NumberSliderVariant.primary
              }
            />
          )}
        </div>
      ) : (
        <CardInvalidPriceOrder store={store} card={card} />
      )}
    </>
  );
}

export interface CardButtonProps {
  store: CommonStore;
  card: CardData;
  maxPrice?: number;
  minPrice?: number;
  stock?: number;
}

const CardPreOrder = ({ store, card, minPrice }: CardButtonProps) => {
  return (
    <>
      <div className={s.CardInfo__notAvailableTitle}>
        Новинка! Скоро поступит в продажу. Оформите предзаказ, чтобы оказаться в
        числе первых кто получит эту карточку.
      </div>
      <div className={s.CardInfo__addCard}>
        <Button
          variant={ButtonVariant.secondary}
          onClick={() => {
            store.setFindCardModalContent({
              ...DEFAULT_FIND_CARD_POPUP_CONTENT,
              textareaDefaultValue: getCardForTextarea({
                id: card.id,
                title: CardHelper.getName(card),
                isFoil: card.is_foil
              })
            });
          }}
        >
          {'Заказать поиск'}
        </Button>
      </div>
    </>
  );
};

const CardFindOrder = ({ store, card, maxPrice }: CardButtonProps) => {
  return (
    <>
      <div className={s.CardInfo__notAvailableTitle}>
        Карточки нет в наличии, но вы можете заказать её поиск. Мы сделаем всё
        возможное, чтобы её найти, и свяжемся с вами, когда она будет у нас.
      </div>
      {maxPrice >= 1 && (
        <>
          <p className={s.CardInfo__notAvailableTitle}>
            Цена указана ориентировочно
          </p>
          <div className={clsx(s.CardInfo__price, s.CardInfo__price_secondary)}>
            ~{prettyNumber(maxPrice, RUBLE)}
          </div>
        </>
      )}
      <div className={s.CardInfo__addCard}>
        <Button
          variant={ButtonVariant.secondary}
          onClick={() => {
            store.setFindCardModalContent({
              ...DEFAULT_FIND_CARD_POPUP_CONTENT,
              textareaDefaultValue: getCardForTextarea({
                id: card.id,
                title: CardHelper.getName(card),
                isFoil: card.is_foil
              })
            });
          }}
        >
          Заказать поиск
        </Button>
      </div>
    </>
  );
};

const CardInvalidPriceOrder = ({ store, card, stock }: CardButtonProps) => {
  return (
    <>
      {stock && (
        <div className={s.CardInfo__availability}>
          В наличии: <span>{stock} шт</span>
        </div>
      )}
      <div className={s.CardInfo__notAvailableTitle}>
        У карточки не указана цена. Заполните форму, чтобы наш менеджер уточнил
        цену карточки и связался с вами
      </div>
      <div className={s.CardInfo__addCard}>
        <Button
          variant={ButtonVariant.secondary}
          onClick={() => {
            store.setFindCardModalContent({
              ...DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT,
              textareaDefaultValue: getCardForTextarea({
                id: card.id,
                title: CardHelper.getName(card),
                isFoil: card.is_foil
              })
            });
          }}
        >
          Оформить заявку
        </Button>
      </div>
    </>
  );
};

export interface CardInfoProps {
  data?: MetaCard;
}

export function CardInfo({ data }: CardInfoProps) {
  const [locationState, locationStore] = useLocationStore();
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);
  const { isMobile } = useWindowSize();

  const tags = useCardTags(data);

  const {
    states,
    allStates,
    priceRange,
    commonState,
    availableStates,
    validStates,
    minPreorderPrice
  } = useMetaCardStates(data);

  const cardLanguages = useMemo(() => {
    return MetaCardHelper.getCustomLanguages(data)
      .map((customLanguage) => {
        const { lang, langCode, cardId, isCustom } = customLanguage;

        return {
          label: lang.toUpperCase(),
          value: cardId,
          langCode: langCode,
          isPriority: isCustom,
          disabled: !availableStates?.some((state) => state.language === lang)
        };
      })
      .sort((a, b) => {
        if (a.isPriority) return -1;
        return (
          CardLanguages.getOrder(a.langCode) -
          CardLanguages.getOrder(b.langCode)
        );
      });
  }, [data, availableStates]);

  const [cardId, setCardId] = useLocationState(
    locationState.query,
    locationStore,
    URL_KEY_CARD_ID,
    useCallback(
      (cardId) => {
        if (cardLanguages.some((lang) => lang.value === cardId)) {
          return cardId;
        }

        const ENCardId = MetaCardHelper.getCardIdByLangCode(
          data,
          CardLanguages.CardLanguages.en
        );
        return ENCardId ?? data.cards?.[0].id;
      },
      [data, cardLanguages]
    )
  );

  const { cardData, originalCard } = useMemo(() => {
    const selectedCard = data.cards.find((card) => card.id === cardId);

    return {
      cardData: selectedCard,
      originalCard: selectedCard.original_card
    };
  }, [data, cardId]);

  const stateOptions: ICardOption[] = useMemo(() => {
    if (!data) {
      return allStates.map((option) => ({
        ...option,
        label: null,
        disabled: true,
        value: null
      }));
    }

    return allStates
      .filter((state) => state.id === cardId)
      .filter((state) =>
        commonState === CardStateVariant.preorderFromStock
          ? state.isAvailable
          : true
      )
      .map((state) => ({
        ...state,
        disabled: !state.isAvailable,
        label: CardConditionType.getFullName(state.type),
        value: state.type
      }));
  }, [data, allStates, cardId, commonState]);

  const [cardType, setCardType] = useLocationState(
    locationState.query,
    locationStore,
    URL_KEY_CARD_STATE,
    useCallback((queryValue) => {
      if (CardConditionType.keys().includes(queryValue as any)) {
        return queryValue as CardConditionEnum;
      }
      return CardConditionEnum.gm;
    }, [])
  );

  const selectedState = useMemo(() => {
    return allStates.find(
      (state) => state.id === cardId && state.type === cardType
    );
  }, [allStates, cardId, cardType]);

  const artistsList = originalCard.artist
    .split(',')
    .map((artist, artistIndex) => ({
      id: originalCard.artist_ids[artistIndex],
      name: artist
    }));

  const cardName = originalCard.printed_name || data.name;

  const selectorsEnabled =
    commonState !== CardStateVariant.preorder &&
    commonState !== CardStateVariant.invalidAllStates &&
    commonState !== CardStateVariant.nonAvailable;

  let cardActionBlock = null;
  if (commonState === CardStateVariant.preorder) {
    cardActionBlock = (
      <CardPreOrder
        card={cardData}
        store={commonStore}
        minPrice={minPreorderPrice}
      />
    );
  } else if (commonState === CardStateVariant.preorderFromStock) {
    cardActionBlock = (
      <CardState
        id={cardData.id}
        cardType={cardType}
        card={cardData}
        store={commonStore}
        stock={selectedState.stock}
        price={selectedState.price}
        isPreorder
      />
    );
  } else if (commonState === CardStateVariant.invalidAllStates) {
    cardActionBlock = (
      <CardInvalidPriceOrder store={commonStore} card={cardData} />
    );
  } else if (!selectedState.isAvailable) {
    cardActionBlock = (
      <CardFindOrder
        store={commonStore}
        card={cardData}
        maxPrice={selectedState.price}
      />
    );
  } else if (selectedState.isAvailable && !selectedState.isValid) {
    cardActionBlock = (
      <CardInvalidPriceOrder
        store={commonStore}
        card={cardData}
        stock={selectedState.stock}
      />
    );
  } else if (selectedState.isAvailable && selectedState.isValid) {
    cardActionBlock = (
      <CardState
        id={cardData.id}
        cardType={cardType}
        card={cardData}
        store={commonStore}
        stock={selectedState.stock}
        price={selectedState.price}
      />
    );
  }

  useEffect(() => {
    if (
      locationState.query.cardId ||
      locationState.query.cardState ||
      (commonState !== CardStateVariant.normal &&
        commonState !== CardStateVariant.preorderFromStock)
    ) {
      return;
    }

    const setCardIdAndState = (cardId: number, state: string) => {
      locationStore.changeQuery(
        {
          [URL_KEY_CARD_ID]: cardId,
          [URL_KEY_CARD_STATE]: state
        },
        true
      );
    };

    if (allStates.length && !availableStates.length) {
      const sortedAllStates = allStates
        .slice()
        .sort(
          makeComparator(compareSimple(), (x) =>
            CardLanguages.getOrder(x.language)
          )
        );

      setCardIdAndState(sortedAllStates[0].id, sortedAllStates[0].type);
    } else if (commonState === CardStateVariant.preorderFromStock) {
      const sortedAvailableStates = availableStates
        .slice()
        .sort(
          makeMultiComparator<TCondition, TCondition[]>(
            [compareSimple(), (x) => CardLanguages.getOrder(x.language)],
            [compareSimple(), (x) => CardConditionType.getOrder(x.type)]
          )
        );

      setCardIdAndState(
        sortedAvailableStates[0].id,
        sortedAvailableStates[0].type
      );
    } else {
      const sortedStates = availableStates
        .slice()
        .sort(
          makeMultiComparator<TCondition, TCondition[]>(
            [compareSimple(), (x) => CardLanguages.getOrder(x.language)],
            [compareSimple(), (x) => CardConditionType.getOrder(x.type)]
          )
        );

      setCardIdAndState(sortedStates[0].id, sortedStates[0].type);
    }
  }, [commonState, allStates, availableStates, locationState, locationStore]);

  return (
    <div className={s.CardInfo}>
      <Helmet title={cardName} />
      <div className={s.CardInfo__image}>
        <Card
          size={CardSize.autoSize}
          images={OriginalCardHelper.getImageUris(originalCard)}
          foil={data.is_foil}
          enablePreview={true}
          className={s.CardInfo__cardImage}
        />
      </div>
      <div className={s.CardInfo__contentTitle}>
        <CardSetName originSet={data.original_set} />
        <div className={s.CardInfo__title}>
          <div className={s.CardInfo__name}>
            {originalCard.printed_name || data.name}
          </div>
        </div>
        {tags.length > 0 && (
          <div className={s.CardInfo__tags}>
            {tags.map((tag, tagIndex) => (
              <Tag key={tagIndex} size={TagSize.small} variant={tag.variant}>
                {tag.label}
              </Tag>
            ))}
          </div>
        )}

        {selectorsEnabled && (
          <>
            <ButtonsRadio
              header="Выберите язык:"
              options={cardLanguages}
              selected={cardId}
              className={s.CardInfo__languages}
              onChange={setCardId}
              renderLabel={(option) => (
                <span>
                  {isMobile ? option?.label.toUpperCase() : option.label}
                </span>
              )}
            />

            <ButtonsRadio
              header="Выберите состояние:"
              options={stateOptions}
              selected={cardType}
              onChange={(cardType) => setCardType(cardType)}
              renderLabel={(option) => (
                <span>
                  {isMobile ? option?.value.toUpperCase() : option.label}
                </span>
              )}
            />
          </>
        )}
      </div>
      <div className={s.CardInfo__contentCard}>
        {cardActionBlock}

        {commonState === CardStateVariant.preorder && (
          <div className={s.CardInfo__availableLanguages}>
            Доступные языки:{' '}
            <span>
              {MetaCardHelper.getCardLanguageNames(data, true).join(', ')}
            </span>
          </div>
        )}

        <div className={s.CardInfo__rarity}>
          Редкость:{' '}
          <span>
            {CardRarity.cardRarities
              .getName(originalCard.rarity)
              ?.toLowerCase()}
          </span>
        </div>
        <div className={clsx(s.CardInfo__annotation, s.CardInfo__typeLine)}>
          {originalCard.printed_type_line || originalCard.type_line}
        </div>
        <div className={s.CardInfo__description}>
          {originalCard.printed_text || originalCard.oracle_text}
        </div>
        {originalCard.flavor_text && (
          <div className={s.CardInfo__description}>
            {originalCard.flavor_text}
          </div>
        )}
        <div className={s.CardInfo__illustrator}>
          {artistsList.length === 1 ? 'Художник' : 'Художники'}:
          {artistsList.map((artist) => (
            <Link
              className={s.CardInfo__illustratorLink}
              to={`/cards?artists=${artist.id}`}
              key={artist.id}
            >
              {artist.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
