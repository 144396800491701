export interface BackendValidationError {
  [fieldName: string]: string[];
}

export interface FrontendValidationError {
  field: string;
  messages: string[];
}

export class FormValidationError extends Error {
  name = 'FormValidationError';

  constructor(
    public validationErrors: BackendValidationError[],
    public requestKey?: string
  ) {
    super(
      'Some fields did not pass backend validation: ' +
        Object.keys(validationErrors).join(', ')
    );
    Object.setPrototypeOf(this, FormValidationError.prototype);
  }

  getErrors(): FrontendValidationError[] {
    const fieldNames = Object.keys(this.validationErrors);

    return (
      fieldNames?.map((fieldName) => {
        return {
          field: fieldName,
          messages: this.validationErrors[fieldName]
        };
      }) || []
    );
  }
}
