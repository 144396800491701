import React, { HTMLAttributes, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import s from './Spinner.module.scss';

export enum SpinnerSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  XL = 'XL',
  XXL = 'XXL'
}

export interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Загруженное состояние
   */
  done?: boolean;
  /**
   * Значение прогресса загрузки от 0 до 100
   */
  value?: number;
  /**
   * Состояние бесконечной загрузки. Алиас для indeterminate
   */
  loading?: boolean;
  /**
   * Состояние бесконечной загрузки
   */
  indeterminate?: boolean;
  /**
   * Спиннер располагается по-центру по горизонтали
   */
  centered?: boolean;
  /**
   * Размер компонента
   */
  size?: SpinnerSize;
  /**
   * Произвольные классы элементов:
   * * cancelIcon – класс иконки отмены
   * * checkmark – класс галочки
   */
  classes?: {
    cancelIcon?: string;
    checkmark?: string;
  };
}

const radius = 20;
const width = 2;

export const Spinner: React.FC<SpinnerProps> & {
  Size: typeof SpinnerSize;
} = ({
  className,
  style,
  size = SpinnerSize.small,
  value = 0,
  loading: loadingProp,
  indeterminate,
  done,
  centered = true,
  classes,
  ...props
}) => {
  const sizePx = useMemo(() => {
    return {
      [SpinnerSize.XXL]: 56,
      [SpinnerSize.XL]: 44,
      [SpinnerSize.large]: 32,
      [SpinnerSize.medium]: 24,
      [SpinnerSize.small]: 16
    }[size];
  }, [size]);

  const normalizedValue = useMemo(() => {
    if (done) return 100;
    if (!value || value < 0) return 0;

    if (value > 100) return 100;

    return typeof value === 'string' ? parseFloat(value) : value;
  }, [value, done]);

  const circumference = useMemo(() => {
    return 2 * Math.PI * radius;
  }, []);

  const strokeDashArray = useMemo(() => {
    return Math.round(circumference * 1000) / 1000;
  }, [circumference]);

  const strokeDashOffset = useMemo(() => {
    return `${((100 - normalizedValue) / 100) * circumference}px`;
  }, [normalizedValue, circumference]);

  const viewBoxSize = useMemo(() => {
    return radius / (1 - Number(width) / Number(sizePx));
  }, [sizePx]);

  const strokeWidth = useMemo(() => {
    return (Number(width) / Number(sizePx)) * (viewBoxSize || 1) * 2;
  }, [sizePx, viewBoxSize]);

  const checkmarkCheckStyle = useMemo(() => {
    let x = '50% - 4px';
    let y = '50% - 4px';
    if ([SpinnerSize.XL, SpinnerSize.XXL].includes(size)) {
      x = '50% - 5px';
      y = '50% - 5px';
    }
    return {
      transform: `translate(calc(${x}), calc(${y}))`,
      strokeWidth: strokeWidth
    };
  }, [size, strokeWidth]);

  const loading = loadingProp || indeterminate;

  const ariaAttrs = {
    'aria-valuemin': 0,
    'aria-valuemax': 100,
    'aria-valuenow': loading ? undefined : normalizedValue
  };

  return (
    <div
      {...(ariaAttrs as any)}
      className={clsx(s.Spinner, className, {
        [s.Spinner_loading]: !done && loading,
        [s.Spinner_done]: done,
        [s.Spinner_centered]: centered
      })}
      style={{
        height: `${sizePx}px`,
        width: `${sizePx}px`,
        minWidth: `${sizePx}px`,
        padding: 0,
        ...style
      }}
      role={'progressbar'}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`${viewBoxSize} ${viewBoxSize} ${2 * viewBoxSize} ${
          2 * viewBoxSize
        }`}
        className={clsx(classes?.checkmark, s.Spinner__checkmark)}
      >
        <circle
          className={s.Spinner__overlay}
          fill="transparent"
          cx={2 * viewBoxSize}
          cy={2 * viewBoxSize}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDashArray}
          strokeDashoffset={strokeDashOffset}
        />
        {done && (
          <path
            className={s.Spinner__checkmarkCheck}
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
            style={checkmarkCheckStyle}
          />
        )}
      </svg>
    </div>
  );
};

Spinner.Size = SpinnerSize;
