import React from 'react';
import {
  ExpansionCard,
  ExpansionCardDecorations
} from '../../../common/components/ui/Card/ExpansionCard';
import ModernHorizons from '../../../assets/images/exp1.png';
import Ikoria from '../../../assets/images/exp2.png';
import Commander2020 from '../../../assets/images/exp3.png';
import Teros from '../../../assets/images/exp4.png';
import Jumpstart from '../../../assets/images/exp5.png';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { ScrollBlock } from '../../../common/components/ui/ScrollBlock/ScrollBlock';

import { useNewestSets } from '../../../store/query/useNewestSets';
import {
  customQueryLoader,
  queryLoader
} from '../../../common/components/utils/queryLoader';
import { ExpansionsLoading } from './ExpansionsLoading';
import s from '../IndexPage.module.scss';

export const Expansions = () => {
  const { isMobile } = useWindowSize();

  const newestSetsQuery = useNewestSets();
  const newestSetsData = newestSetsQuery.state.data;

  const setsImages = [ModernHorizons, Ikoria, Commander2020, Teros, Jumpstart];

  return (
    <ScrollBlock
      cardWidth={218}
      cardsCount={5}
      height={isMobile ? 390 : 400}
      cardGap={isMobile ? 8 : 24}
      className={s.IndexPage__expansions}
      header="Новые издания"
      noScroll={newestSetsQuery.state.error}
      linkProps={{
        to: '/cards'
      }}
    >
      {customQueryLoader(newestSetsQuery, <ExpansionsLoading />) ||
        newestSetsData.map((setItem, setItemIndex) => (
          <ExpansionCard
            to={`/cards?sets=${setItem.id}`}
            key={setItemIndex}
            title={setItem.name}
            image={<img src={setsImages[setItemIndex]} alt="" />}
            decoration={ExpansionCardDecorations[`variant${setItemIndex + 1}`]}
          />
        ))}
    </ScrollBlock>
  );
};
