import React from 'react';

import s from './FilterCard.module.scss';

export interface FilterCardBlockProps {
  header: string;
  children: React.ReactChild;
}

export const FilterCardBlock = ({
  header,
  children,
  ...props
}: FilterCardBlockProps) => {
  return (
    <div className={s.FilterCard__block} {...props}>
      <div className={s.FilterCard__subheader}>{header}</div>
      {children}
    </div>
  );
};
