import React from 'react';
import clsx from 'clsx';
import { RUBLE } from '@proscom/ui-utils';
import { CardConditionEnum, CardConditionType } from '../CardStatusEnum';
import { prettyNumber } from '../../../../../utils/number';
import s from './CardOrderBlock.module.scss';

export interface CardOrderProps {
  condition?: CardConditionEnum;
  amount?: number;
  price?: number;
  onChangeAmount?: (count: number) => void;
  onDelete?: (e: React.MouseEvent) => void;
  isPreorder?: boolean;
}

export const CardOrderBlock = ({
  condition,
  amount,
  price,
  onChangeAmount,
  onDelete,
  isPreorder,
  ...props
}: CardOrderProps) => {
  return (
    <div className={clsx(s.CardOrderBlock)} {...props}>
      <div className={s.CardOrderBlock__label}>
        <div className={s.CardOrderBlock__condition}>
          {condition}
          {isPreorder ? ' (Предзаказ)' : null}
        </div>
        <div>{CardConditionType.getName(condition)}</div>
      </div>
      <div className={s.CardOrderBlock__info}>
        <div className={s.CardOrderBlock__amount}>{amount} шт</div>
        <div className={s.CardOrderBlock__price}>
          {prettyNumber(price, RUBLE)}
        </div>
      </div>
    </div>
  );
};
