import React from 'react';
import { ErrorBoundary } from '../../common/components/utils/ErrorBoundary';
import { ErrorMessage } from '../../common/components/ui/ErrorMessage/ErrorMessage';
import { Header } from '../../common/components/ui/Header/Header';
import { Footer } from '../../common/components/ui/Footer/Footer';

import s from './FilterLayout.module.scss';

export function FilterLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    return (
      <>
        <Header />
        <div className={s.FilterLayoutWrapper}>
          <div className={s.FilterLayout}>
            <ErrorBoundary component={ErrorMessage}>
              <Comp {...props} />
            </ErrorBoundary>
          </div>
        </div>
        <Footer />
      </>
    );
  };
}
