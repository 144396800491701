import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { ErrorMessage } from '../../common/components/ui/ErrorMessage/ErrorMessage';
import { Link } from '../../common/components/ui/Link/Link';
import buttonStyles from '../../common/components/ui/Button/Button.module.scss';
import s from '../../common/components/ui/ErrorMessage/ErrorMessage.module.scss';

export function NotFoundPage() {
  return (
    <ErrorMessage text="404" description="Страница не найдена">
      <Helmet title={'Ничего не найдено'} />
      <Link
        to="/cards"
        className={clsx(
          buttonStyles.Button,
          buttonStyles.Button_size_medium,
          buttonStyles.Button_variant_primary,
          s.ErrorMessage__actionButton
        )}
      >
        Вернуться в каталог
      </Link>
    </ErrorMessage>
  );
}
