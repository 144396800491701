import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { SwitcherOption } from '../common/components/ui/Switcher/Switcher';
import { LOCAL_STORAGE_CARD_VIEW } from './storageKeys';

export interface CardViewStoreArgs {
  localStorage: Storage;
}

function parseStorageValue(
  value: string,
  defaultValue: SwitcherOption = SwitcherOption.list
) {
  if (!value) return defaultValue;
  for (const key of Object.keys(SwitcherOption)) {
    if (SwitcherOption[key] === value) {
      return value as SwitcherOption;
    }
  }
  return defaultValue;
}

export class CardViewStore extends WebStorageValueStore<SwitcherOption> {
  constructor({ localStorage }: CardViewStoreArgs) {
    super(localStorage, LOCAL_STORAGE_CARD_VIEW, parseStorageValue);
  }

  setView = (view: SwitcherOption) => {
    this.setValue(view);
  };
}
