import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as ZoomInIcon } from '../../../../assets/icons/zoom-in.svg';
import { IntrinsicPropsWithoutRef } from '../../../types';
import { OriginalCardImages } from '../../../../store/interfacesResponse';
import s from './CardImage.module.scss';

export enum CardSize {
  autoSize = 'autoSize',
  xlarge = 'xlarge',
  large = 'large',
  medium = 'medium',
  small = 'small',
  xsmall = 'xsmall',
  xxsmall = 'xxsmall',
  banner = 'banner'
}

export interface CardImageProps extends IntrinsicPropsWithoutRef<'div'> {
  /**
   * Размер карточки ("xlarge", "large", "medium", "small", "xsmall", "xxsmall", "banner")
   */
  size: CardSize;

  /**
   * Ссылки на изображения карточки
   */
  images?: OriginalCardImages;

  /**
   * Флажок foil
   */
  foil?: boolean;

  /**
   * Произвольный класс
   */
  className?: string;

  /**
   * Альтернативный текст изображения
   */
  alt?: string;

  /**
   * Пропсы для img
   */
  imageProps?: React.PropsWithoutRef<'img'>;
  /**
   * При клике на карту раскрывать арт на весь экран
   */
  enablePreview?: boolean;
  /**
   * Колбек при клике на превью карты
   */
  onPreviewClick?: () => void;
}

export function CardImage({
  size,
  images,
  foil,
  className,
  alt,
  imageProps,
  enablePreview,
  onPreviewClick,
  ...props
}: CardImageProps) {
  return (
    <div
      className={clsx(s.CardImage, className, s[`CardImage_size_${size}`], {
        [s.CardImage_foil]: foil
      })}
      {...props}
    >
      <div className={s.CardImage__overlay} />
      <img
        className={clsx(s.CardImage__image)}
        src={images?.large || images?.normal || images?.png}
        alt={alt}
        {...imageProps}
      />
      {enablePreview && (
        <div className={s.CardImage__preview} onClick={onPreviewClick}>
          <ZoomInIcon />
        </div>
      )}
    </div>
  );
}
