import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { ReactComponent as BasketIcon } from '../../../../assets/icons/basket.svg';
import { Link, LinkProps } from '../Link/Link';
import { SetsPopup } from '../SetsPopup/SetsPopup';
import { Logo } from '../Logo/Logo';
import { Amount, AmountSize } from '../Amount/Amount';
import { CartStore } from '../../../../store/CartStore';
import { STORE_CART, STORE_COMMON } from '../../../../store/stores';
import { CommonStore } from '../../../../store/CommonStore';
import { DEFAULT_SELL_CARD_POPUP_CONTENT } from '../Popups/SellCardPopup';
import { HeaderSearch } from './HeaderSearch';
import s from './Header.module.scss';

export interface DesktopHeaderProps {
  currentFormatId?: number;
  setCurrentFormatId?: (number) => void;
  setLinkProps?: (OriginalSet) => LinkProps;
}

export const DesktopHeader = ({
  currentFormatId,
  setCurrentFormatId,
  setLinkProps
}: DesktopHeaderProps) => {
  const [isCatalogVisible, setIsCatalogVisible] = useState(false);
  const [isSearchFocus, setIsSearchFocus] = useState(false);
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const cart = cartStore.getItems();

  const handleOutsideClick = useCallback(() => {
    setIsCatalogVisible(false);
  }, []);

  return (
    <header
      className={clsx(s.Header, {
        [s.Header_focused]: isSearchFocus
      })}
    >
      <div className={s.Header__content}>
        <Logo iconProps={{ className: s.Header__logo }} />
        <div className={s.Header__navigation}>
          <Link
            className={s.Header__link}
            onClick={() => setIsCatalogVisible(!isCatalogVisible)}
          >
            Каталог
          </Link>
          <Link
            className={s.Header__link}
            onClick={() =>
              commonStore.setSellCardModal(DEFAULT_SELL_CARD_POPUP_CONTENT)
            }
          >
            Продать карточки
          </Link>
          <Link
            to={'/deliveryandpayments'}
            className={clsx(s.Header__link, s.Header__link_delivery)}
          >
            Доставка и оплата
          </Link>
        </div>

        <HeaderSearch onSearchInputFocus={setIsSearchFocus} />

        <Link
          to="/cart"
          className={clsx(s.Header__link, s.Header__shoppingCart)}
        >
          <BasketIcon className={s.Header__linkIcon} />
          {cart.length > 0 && (
            <Amount
              size={AmountSize.small}
              value={cart.length}
              className={s.Header__cartCounter}
            />
          )}
          Корзина
        </Link>
      </div>
      {isCatalogVisible && (
        <SetsPopup
          currentFormatId={currentFormatId}
          onFormatClick={setCurrentFormatId}
          setLinkPropsFn={setLinkProps}
          onOutsideClick={handleOutsideClick}
        />
      )}
    </header>
  );
};
