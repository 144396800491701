import React, { HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import { RUBLE } from '@proscom/ui-utils';
import { prettyNumber } from '../../../../../utils/number';
import s from './CardButton.module.scss';

export enum CardButtonSize {
  small = 'small',
  medium = 'default'
}

export interface CardButtonProps extends HTMLAttributes<HTMLButtonElement> {
  title?: string;
  text?: string;
  price?: string | number;
  price_free?: number;
  order_price?: number;
  /**
   * Заблокированное состояние
   */
  disabled?: boolean;
  active?: boolean;
  icon?: ReactNode;
  className: string;
  size?: CardButtonSize;
}

export function CardButton({
  title,
  text,
  price,
  price_free,
  active,
  icon,
  size = CardButtonSize.medium,
  className,
  ...props
}: CardButtonProps) {
  return (
    <button
      className={clsx(
        className,
        s.CardButton,
        { [s.CardButton_active]: active },
        s[`CardButton__${size}`]
      )}
      type={'button'}
      {...props}
    >
      <div className={s.CardButton__title} title={title}>
        {title}
      </div>
      <div className={s.CardButton__text}>{text}</div>
      {price || price_free ? (
        <div className={s.CardButton__price}>
          {price === 0 && price_free ? (
            <span>
              Бесплатно
              <br />
              (от {prettyNumber(price_free, RUBLE)})
            </span>
          ) : typeof price === 'number' ? (
            `${prettyNumber(price, RUBLE)}`
          ) : (
            price
          )}
        </div>
      ) : null}
      {icon && <div className={s.CardButton__icon}>{icon}</div>}
    </button>
  );
}

CardButton.Size = CardButtonSize;
