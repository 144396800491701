import React, { ReactNode, useCallback } from 'react';
import { AsyncSingletonError } from '@proscom/prostore-react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { Button } from '../../../common/components/ui/Button/Button';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { useFormTabs } from '../../../utils/helpers/useTabs';
import { CardButton } from '../../../common/components/ui/Button/CardButton/CardButton';
import { OrderBlockProps } from '../OrderPage';
import { OrderLayout } from '../../../common/components/layout/OrderLayout/OrderLayout';
import { BackLink } from '../../../common/components/ui/BackLink/BackLink';
import { OrderDetails } from '../../../common/components/ui/Order/OrderDetails';
import { FormControl } from '../../../common/components/ui/FormControl';
import { IDeliveryFormData } from '../common/DeliveryForm';
import { usePaymentCreate } from '../../../store/mutation/usePaymentCreate';
import { StepBlock, StepsType } from '../common/StepBlock/StepBlock';
import { ReactComponent as BoxIcon } from '../../../assets/icons/package.svg';
import { ReactComponent as CardIcon } from '../../../assets/icons/bankCard.svg';
import {
  DELIVERY_LOCAL_COURIER,
  DELIVERY_LOCAL_PICKUP
} from '../../../constants';
import { toastError } from '../../../utils/log';
import { ensureKeyTrue } from '../../../utils/error/UnexpectedResultError';
import s from '../common.module.scss';

interface ITabOption {
  value: string;
  title: string;
  text: string;
  icon: ReactNode;
}

const tabOptions: ITabOption[] = [
  {
    title: 'Онлайн',
    text: 'Банковской картой',
    value: 'online',
    icon: <CardIcon />
  },
  {
    title: 'При получении',
    text: 'Наличными или банковской картой',
    value: 'local',
    icon: <BoxIcon />
  }
];

function PaymentForm({
  deliveryCode,
  enabledSteps,
  buttonLoading
}: {
  deliveryCode: string;
  enabledSteps?: StepsType;
  buttonLoading?: boolean;
}) {
  const { isDesktop, isMobile } = useWindowSize();

  const [tab, getTabProps] = useFormTabs(
    'paymentType',
    tabOptions,
    (x) => x.value
  );

  return (
    <div className={s.OrderPage}>
      {!isMobile && <StepBlock step={3} enabledSteps={enabledSteps} />}
      <div className={s.OrderDetails}>
        <div className={s.OrderDetails__title}>Способ оплаты:</div>
        <div
          className={clsx(
            s.OrderDetails__deliveryTypes,
            s.OrderDetails__mobileFullWidth
          )}
        >
          {tabOptions.map((item, key) => (
            <CardButton
              key={key}
              className={s.OrderDetails__deliveryType}
              {...item}
              {...getTabProps(item.value)}
              disabled={
                item.value === 'local' &&
                deliveryCode !== DELIVERY_LOCAL_PICKUP &&
                deliveryCode !== DELIVERY_LOCAL_COURIER
              }
            />
          ))}
        </div>
        <div className={s.OrderDetails__description}>
          При оплате онлайн вы будете переадресованы на сайт платежной системы
          для завершения оплаты.
        </div>
        {isDesktop && (
          <Button
            className={s.OrderDetails__nextButton}
            type={'submit'}
            spinner={buttonLoading}
          >
            Перейти к оплате
          </Button>
        )}
      </div>
    </div>
  );
}

export function PaymentBlock({
  onStepChange,
  orderInfo,
  enabledSteps
}: OrderBlockProps) {
  const { run, loading } = usePaymentCreate(orderInfo.id, orderInfo.secret);
  const { isMobile } = useWindowSize();

  const onSubmit = useCallback(
    ({ paymentType = 'online' }) => {
      const payOnline = paymentType === 'online';
      run({ pay_personal: !payOnline })
        .then((data) => {
          if (payOnline) {
            const paymentData = ensureKeyTrue(
              data,
              'payment',
              'Unexpected payment information'
            );
            const link = ensureKeyTrue(
              paymentData,
              'link',
              'Unexpected payment information'
            );
            window.location.href = link;
          } else {
            onStepChange(4, {
              error: undefined,
              success: 'true',
              clear: 'true'
            });
          }
        })
        .catch((err: unknown) => {
          if (err instanceof AsyncSingletonError) return;
          toastError(err);
        });
    },
    [onStepChange, run]
  );

  return (
    <FormControl<IDeliveryFormData> onSubmit={onSubmit}>
      <Helmet title={'Оплата'} />
      <OrderLayout
        title={'Оформление заказа'}
        backLink={
          <BackLink
            title={'Доставка'}
            to={`/orders/${orderInfo.id}?step=2&token=${orderInfo.secret}`}
          />
        }
        rightBlock={
          <OrderDetails
            order={orderInfo.orderDetails}
            totalPrice={orderInfo.totalPrice}
            buttonText={isMobile ? 'Далее' : 'Оплатить'}
            buttonProps={{ type: 'submit', spinner: loading }}
          />
        }
      >
        <PaymentForm
          deliveryCode={orderInfo.order?.delivery_method?.code}
          enabledSteps={enabledSteps}
          buttonLoading={loading}
        />
      </OrderLayout>
    </FormControl>
  );
}
