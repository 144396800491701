import React from 'react';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import s from './ChipText.module.scss';

export interface ChipTextProps {
  title: string;
  text: React.ReactNode;
  className?: string;
}

export function ChipText({ title, text, className }: ChipTextProps) {
  return (
    <div className={clsx(s.TextBlock, className)}>
      <CheckIcon className={s.TextBlock__icon} />
      <div className={s.TextBlock__title}>{title}</div>
      <div className={s.TextBlock__text}>{text}</div>
    </div>
  );
}
