import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { CardData } from '../interfacesResponse';
import { apiUrl } from '../../config';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  method: 'get',
  useCache: true
};

export interface OrderDeliveryVars {
  url: string;
  params: {
    secret: string;
  };
}

export interface IDeliveryMethod {
  id: number;
  code: string;
  name: string;
  price: number;
  price_free: number;
  is_local: boolean;
  pay_online: boolean;
  pay_personal: false;
  comment: string;
  created_at: string;
  updated_at: string;
  order_price: number;
}

const queryOptions: IUseAxiosQueryOptions<
  OrderDeliveryVars,
  IDeliveryMethod[]
> = {
  query,
  mapData: (result) => result.data
};

export function useOrderDeliveryMethod(id: number, secret: string) {
  return useAxiosQuery({
    queryOptions,
    variables: {
      url: `${apiUrl}/orders/${id}/delivery-methods`,
      params: {
        secret: secret
      }
    }
  });
}
