import { EnumHelpers } from '@proscom/ui-utils';

export class CardRarity {
  static CardRarities = {
    common: 'common',
    uncommon: 'uncommon',
    rare: 'rare',
    mythic: 'mythic'
  };

  static cardRarities = new EnumHelpers(CardRarity.CardRarities, {
    common: { name: 'Обычная' },
    uncommon: { name: 'Необычная' },
    rare: { name: 'Редкая' },
    mythic: { name: 'Эпическая' }
  });
}
