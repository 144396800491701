import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import s from './Link.module.scss';

export interface LinkProps
  extends React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement> {
  /**
   * Ссылка для <Link>
   */
  to?: string;

  /**
   * Ссылка для обычного <a>
   */
  href?: string;

  /**
   * Произвольный класс
   */
  className?: string;

  /**
   * Содержимое ссылки
   */
  children?: React.ReactNode;

  /* Значение атрибута rel */
  rel?: string;

  target?: string;

  disabled?: boolean;
}

export const Link = ({
  to,
  href,
  className,
  children,
  ...props
}: LinkProps) => {
  if (to) {
    return (
      <RouterLink to={to} className={clsx(s.Link, className)} {...props}>
        {children}
      </RouterLink>
    );
  }

  if (href) {
    return (
      <a href={href} className={clsx(s.Link, className)} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button className={clsx(s.Link, className)} type="button" {...props}>
      {children}
    </button>
  );
};
