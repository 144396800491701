import React from 'react';

import clsx from 'clsx';
import { Link } from '../Link/Link';
import buttonStyles from '../Button/Button.module.scss';
import defaultStyles from '../../../../routes/_layouts/DefaultLayout.module.scss';
import s from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
  text?: React.ReactNode;
  description?: string;
  children?: React.ReactNode;
}

export function ErrorMessage({
  text,
  description,
  children
}: ErrorMessageProps) {
  return (
    <div className={s.ErrorMessage}>
      {text && <div className={s.ErrorMessage__text}>{text}</div>}
      {description && (
        <div className={s.ErrorMessage__description}>{description}</div>
      )}
      {children && <div className={s.ErrorMessage__children}>{children}</div>}
    </div>
  );
}
