import React from 'react';
import { RUBLE } from '@proscom/ui-utils';
import clsx from 'clsx';
import { useSubject } from '@proscom/prostore-react';
import { Button, ButtonProps, ButtonSize } from '../Button/Button';
import { isMobile$ } from '../../../../utils/helpers/useWindowSize';
import { prettyNumber } from '../../../../utils/number';
import { OrderDetailsEnum } from '../../../../utils/data';
import s from './OrderDetails.module.scss';

export interface IOrderItem {
  title: string;
  price: number;
  type: OrderDetailsEnum;
}

export interface OrderDetailsProps {
  order: IOrderItem[];
  totalPrice?: number;
  onButtonClick?: (e) => void;
  buttonText?: string;
  buttonProps?: Partial<ButtonProps>;
}

export function OrderDetails({
  order,
  totalPrice,
  onButtonClick,
  buttonText,
  buttonProps = {}
}: OrderDetailsProps) {
  const isMobile = useSubject(isMobile$);

  return (
    <div className={s.OrderDetails}>
      <div className={s.OrderDetails__content}>
        <div className={s.OrderDetails__header}>Детали заказа</div>

        {order.map((item, itemIndex) => (
          <div key={itemIndex} className={s.OrderDetailsItem}>
            <div className={s.OrderDetailsItem__title}>{item.title}:</div>
            <div className={s.OrderDetailsItem__value}>
              {prettyNumber(item.price, RUBLE)}
            </div>
          </div>
        ))}

        <div className={clsx(s.OrderDetailsItem, s.OrderDetailsItem_total)}>
          <div className={s.OrderDetailsItem__title}>Итого:</div>
          <div className={s.OrderDetailsItem__value}>
            {prettyNumber(totalPrice, RUBLE)}
          </div>
        </div>

        {buttonText && (
          <div className={s.OrderDetails__action}>
            <Button
              className={s.OrderDetails__actionButton}
              onClick={onButtonClick}
              size={isMobile ? ButtonSize.small : ButtonSize.medium}
              {...buttonProps}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
