import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { cleanObject } from '@proscom/ui-utils';
import { skipIfNull } from '@proscom/prostore';
import { apiUrl } from '../../config';
import { MetaCard, PaginationMeta } from '../interfacesResponse';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  url: `${apiUrl}/meta-cards`,
  method: 'get'
};

export interface MetaCardsQueryResponse {
  data: MetaCard[];
  meta: PaginationMeta;
}

export interface MetaCardsQueryVars {
  page?: string;
  sort?: string;
  include?: string;
  append?: 'is_showcase' | string;
  filter?: {
    id?: string;
    is_foil?: string;
    search?: string;
    rarity?: string;
    colors?: string;
    cmc?: string;
    showcase?: string;
    price_from?: number;
    price_to?: number;
    artists?: string;
    format?: string;
    type?: string;
    state?: string;
    set?: string;
    lang?: string;
  };
}

const queryOptions: IUseAxiosQueryOptions<
  MetaCardsQueryVars,
  MetaCardsQueryResponse
> = {
  query,
  mapVariables: (variables) => ({
    params: {
      ...variables,
      noCount: true
    }
  }),
  skipQuery: skipIfNull(null)
};

export function useMetaCards(variables?: MetaCardsQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables: variables ? cleanObject(variables) : null
  });
}
