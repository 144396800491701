import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  match as Match,
  RouteProps,
  withRouter
} from 'react-router-dom';
import { Location } from 'history';
import { cleanObject } from '@proscom/ui-utils';
import { useLocation } from 'react-router';

export interface RouteType extends RouteProps {
  path?: string;
}

export interface RoutesCompProps {
  location?: Location;
  match?: Match;
}

export interface ScrollToTopProps {
  children: React.ReactNode;
}

function ScrollToTop({ children }: ScrollToTopProps) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return <>{children}</>;
}

export function Routes(routes: RouteType[]) {
  return function RoutesComp({ location, match }: RoutesCompProps) {
    const pathPrefix = match?.path || '';
    return (
      <ScrollToTop>
        <Switch {...cleanObject({ location })}>
          {routes.map((props, i) => {
            const path = props.path ? `${pathPrefix}${props.path}` : undefined;
            const newProps = cleanObject({
              ...props,
              path
            });
            return <Route key={i} {...newProps} />;
          })}
        </Switch>
      </ScrollToTop>
    );
  };
}
