import { MetaCard } from '../../store/interfacesResponse';
import { CardLanguages } from '../../store/filterOptions/CardLanguages';

export class MetaCardHelper {
  static getImageUris(mc: MetaCard) {
    return mc.card_faces?.[0]?.image_uris || mc.image_uris;
  }

  static getCardLanguages(mc: MetaCard, includeRussian = false) {
    const langs = mc.cards.map((card) => card.original_card.lang);
    if (
      includeRussian &&
      langs.indexOf(CardLanguages.CardLanguages.ru) === -1
    ) {
      langs.push(CardLanguages.CardLanguages.ru);
    }

    return langs;
  }

  static getCustomLanguages(mc: MetaCard) {
    return mc.cards.map((card) => ({
      lang: card.original_card.front_lang,
      langCode: card.original_card.lang,
      cardId: card.id,
      isCustom: !!card.original_card.custom_lang
    }));
  }

  static getCardLanguageNames(mc: MetaCard, includeRussian = false) {
    return MetaCardHelper.getCardLanguages(mc, includeRussian).map((lang) =>
      CardLanguages.cardLanguages.getName(lang)
    );
  }

  static getCardPreorderLanguages(mc: MetaCard) {
    const resultLanguages = [];
    for (const card of mc.cards) {
      if (
        card.original_card.lang === 'en' ||
        card.original_card.lang === 'ru'
      ) {
        resultLanguages.push(card.original_card.lang);
      }
    }

    return resultLanguages;
  }

  static getCardIdByLangCode(mc: MetaCard, langCode: string) {
    return mc?.cards.find((card) => card.original_card.lang === langCode)?.id;
  }
}
