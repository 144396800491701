import { EnumHelpers } from '@proscom/ui-utils';

export enum CardConditionEnum {
  nm = 'nm', // Почти новая
  sp = 'sp', // Уже играли
  mp = 'mp', // Средне играли
  hp = 'hp', // Много играли
  gm = 'gm' // Новая
}

export const CardConditionType = new (class extends EnumHelpers<
  typeof CardConditionEnum,
  { name: string; fullName: string; order: number }
> {
  constructor() {
    super(CardConditionEnum, {
      gm: { name: 'Новая', fullName: 'Новая (GM)', order: 5 },
      nm: { name: 'Почти новая', fullName: 'Почти новая (NM)', order: 1 },
      sp: { name: 'Уже играли', fullName: 'Уже играли (SP)', order: 2 },
      mp: {
        name: 'Умеренно играли',
        fullName: 'Умеренно играли (MP)',
        order: 3
      },
      hp: { name: 'Много играли', fullName: 'Много играли (HP)', order: 4 }
    });
  }

  getFullName(condition: CardConditionEnum) {
    return `${this.getName(condition)} (${condition.toUpperCase()})`;
  }

  getOrder(condition: CardConditionEnum) {
    return CardConditionType.info[condition].order;
  }
})();
