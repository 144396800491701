import { useEffect, useState } from 'react';

export const useViewportWidth = () => {
  const [viewportWidth, setViewportWidth] = useState(document.body.clientWidth);

  useEffect(() => {
    function handleViewportResize() {
      setViewportWidth(document.body.clientWidth);
    }

    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();

    return () => window.removeEventListener('resize', handleViewportResize);
  }, []);

  return viewportWidth;
};
