import React, { useEffect, useState } from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import { useDebouncedCallback } from 'use-debounce';
import { FilterCardBlock } from '../FilterCardBlock';
import {
  URL_KEY_PRICE_FROM,
  URL_KEY_PRICE_TO
} from '../../../../store/urlKeys';
import { STORE_LOCATION } from '../../../../store/stores';
import { NumberInput } from '../../../../common/components/ui/Input/NumberInput';

export const FilterPrice = React.memo(function FilterPrice() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_PRICE_FROM,
    URL_KEY_PRICE_TO
  ]);

  const [fromPrice, setFromPrice] = useState(query[URL_KEY_PRICE_FROM] || '');
  const [toPrice, setToPrice] = useState(query[URL_KEY_PRICE_TO] || '');

  const priceFrom = query[URL_KEY_PRICE_FROM];
  const priceTo = query[URL_KEY_PRICE_TO];

  useEffect(() => {
    setFromPrice(priceFrom);
  }, [priceFrom]);
  useEffect(() => {
    setToPrice(priceTo);
  }, [priceTo]);

  const delayedFromPriceChange = useDebouncedCallback((value) => {
    locationStore.changeQuery({
      [URL_KEY_PRICE_FROM]: value
    });
  }, 500);

  const delayedToPriceChange = useDebouncedCallback((value) => {
    locationStore.changeQuery({
      [URL_KEY_PRICE_TO]: value
    });
  }, 500);

  const handleFromPriceChange = (value) => {
    setFromPrice(value);
    delayedFromPriceChange.callback(value);
  };

  const handleToPriceChange = (value) => {
    setToPrice(value);
    delayedToPriceChange.callback(value);
  };

  return (
    <FilterCardBlock header="Цена, ₽">
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, marginRight: 4 }}>
          <NumberInput
            value={fromPrice}
            onChange={handleFromPriceChange}
            placeholder={'от'}
          />
        </div>
        <div style={{ flexGrow: 1, marginLeft: 4 }}>
          <NumberInput
            value={toPrice}
            onChange={handleToPriceChange}
            placeholder={'до'}
          />
        </div>
      </div>
    </FilterCardBlock>
  );
});
