import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { range } from 'lodash-es';
import { useLatestCallbacksRef } from '@proscom/ui-react';
import { ReactComponent as DotsIcon } from '../../../../assets/icons/dots.svg';
import { ReactComponent as PrevPageIcon } from '../../../../assets/icons/arrow_back.svg';
import { ReactComponent as NextPageIcon } from '../../../../assets/icons/arrow_forward.svg';

import { Link } from '../Link/Link';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { ARROW_LEFT, ARROW_RIGHT, MAC_OPTION } from '../../../../constants';
import { prettyNumber } from '../../../../utils/number';
import s from './Pagination.module.scss';

export interface Page {
  active: boolean;
  label: number;
  url?: string | null;
}

export interface PaginationProps {
  currentPage: number;
  lastPageNumber: number;
  adjacentPages?: number;
  urlFunc: (page: number) => string;
  onNavigate?: (page: number) => void;
}

export const Pagination = ({
  currentPage,
  lastPageNumber,
  adjacentPages = 1,
  urlFunc,
  onNavigate
}: PaginationProps) => {
  const { isMobile } = useWindowSize();

  const isMac = useMemo(
    () => navigator.platform.toLowerCase().indexOf('mac') > -1,
    []
  );

  // Если lastPageNumber не указан, то считаем, что справа есть еще как минимум несколько страниц, равное числу максимального отображаемого числа страниц на экране
  const isFakedPagination = lastPageNumber === -1;
  lastPageNumber = isFakedPagination
    ? currentPage + (4 * adjacentPages + 3)
    : lastPageNumber;

  const pagesList = useMemo(() => {
    if (isMobile) {
      return null;
    }

    const resultPages: (number | '-')[] = [];

    const totalPages = 4 * adjacentPages + 3;

    if (isFakedPagination) {
      for (let i = currentPage; i <= currentPage + totalPages; i++) {
        resultPages.push(i);
      }

      resultPages.push('-');
      return resultPages;
    }

    if (totalPages > lastPageNumber) {
      for (let page = 1; page <= lastPageNumber; page++) {
        resultPages.push(page);
      }

      return resultPages;
    }

    if (
      currentPage < 2 * adjacentPages + 1 ||
      currentPage <= adjacentPages + 3
    ) {
      let page = 1;
      while (
        page <= 2 * adjacentPages + 1 ||
        page <= currentPage + adjacentPages
      ) {
        resultPages.push(page++);
      }

      resultPages.push(page);
      resultPages.push('-');

      let pageFromRight = lastPageNumber - (totalPages - page);
      while (page <= totalPages) {
        resultPages.push(pageFromRight);
        pageFromRight++;
        page++;
      }

      return resultPages;
    }

    if (
      lastPageNumber - currentPage + 1 < 2 * adjacentPages + 1 ||
      lastPageNumber - currentPage + 1 <= adjacentPages + 3
    ) {
      let page = 1;
      while (
        page <= 2 * adjacentPages + 1 ||
        lastPageNumber - page + 1 >= currentPage - adjacentPages
      ) {
        resultPages.push(lastPageNumber - page + 1);
        page++;
      }

      resultPages.push(lastPageNumber - page);
      resultPages.push('-');
      page++;
      let pagesFromRight = totalPages - page + 1;
      while (page <= totalPages) {
        resultPages.push(pagesFromRight);
        pagesFromRight--;
        page++;
      }

      return resultPages.reverse();
    }

    resultPages.push(1);
    resultPages.push('-');

    let page = 3;
    let pageFromRight = currentPage - 2 * adjacentPages + 1;
    while (page < 3 + 4 * adjacentPages - 3 + 2) {
      resultPages.push(pageFromRight);
      page++;
      pageFromRight++;
    }

    resultPages.push('-');
    resultPages.push(lastPageNumber);

    return resultPages;
  }, [isFakedPagination, currentPage, lastPageNumber, adjacentPages, isMobile]);

  const canGoPrev = currentPage > 1;
  const canGoNext = currentPage < lastPageNumber;

  const callbacksRef = useLatestCallbacksRef({
    navigateNextPage: () => {
      if (canGoNext) {
        onNavigate?.(currentPage + 1);
      }
    },
    navigatePrevPage: () => {
      if (canGoPrev) {
        onNavigate?.(currentPage - 1);
      }
    }
  });

  useEffect(() => {
    const onKeyDown = (e) => {
      if (
        (isMac && e.altKey && e.key === 'ArrowRight') ||
        (!isMac && e.ctrlKey && e.key === 'ArrowRight')
      ) {
        callbacksRef.navigateNextPage();
      } else if (
        (isMac && e.altKey && e.key === 'ArrowLeft') ||
        (!isMac && e.ctrlKey && e.key === 'ArrowLeft')
      ) {
        callbacksRef.navigatePrevPage();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [callbacksRef, isMac]);

  return (
    <div className={s.Pagination}>
      <Link
        className={s.Pagination__arrow}
        disabled={!canGoPrev}
        to={canGoPrev && urlFunc(currentPage - 1)}
        onClick={
          !urlFunc && canGoPrev
            ? () => onNavigate?.(currentPage - 1)
            : undefined
        }
      >
        <PrevPageIcon />
      </Link>
      {isMobile ? (
        <div className={s.Pagination__counter}>{`${prettyNumber(
          currentPage
        )}/${prettyNumber(Number(lastPageNumber))}`}</div>
      ) : (
        pagesList.map((page, pageIndex) => {
          return page === '-' ? (
            <DotsIcon key={pageIndex} className={s.Pagination__dots} />
          ) : (
            <Link
              key={pageIndex}
              to={urlFunc(page)}
              onClick={!urlFunc ? () => onNavigate?.(page) : undefined}
              className={clsx(s.Pagination__page, {
                [s.Pagination__page_active]: page === currentPage
              })}
            >
              {prettyNumber(Number(page))}
            </Link>
          );
        })
      )}
      <Link
        className={s.Pagination__arrow}
        disabled={!canGoNext}
        to={canGoNext && urlFunc(currentPage + 1)}
        onClick={
          !urlFunc && canGoNext
            ? () => onNavigate?.(currentPage + 1)
            : undefined
        }
      >
        <NextPageIcon />
      </Link>
      {!isMobile && (
        <div className={s.Pagination__hotkeysTip}>
          Используйте{' '}
          {isMac ? (
            <>
              <kbd>
                {MAC_OPTION}
                {ARROW_LEFT}
              </kbd>{' '}
              и{' '}
              <kbd>
                {MAC_OPTION}
                {ARROW_RIGHT}
              </kbd>
            </>
          ) : (
            <>
              <kbd>Ctrl+{ARROW_LEFT}</kbd> и <kbd>Ctrl+{ARROW_RIGHT}</kbd>
            </>
          )}{' '}
          для навигации по страницам
        </div>
      )}
    </div>
  );
};
