import React from 'react';
import ReactSelect from 'react-select';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';

export type OptionValueType = string | number | null;

export interface SelectOption {
  value: OptionValueType;
  label: string;
}

export interface SelectProps {
  /**
   * Список option
   */
  options: SelectOption[];

  /**
   * Значение по-умолчанию
   */
  defaultValue?: OptionValueType;

  /**
   * Заблокированное состояние
   */
  disabled?: boolean;

  /**
   * Колбек при выборе
   * @param OptionValueType
   */
  onChange?: (OptionValueType) => void;

  /**
   * Текст-плейсхолдер
   */
  placeholder?: string;

  /**
   * Произвольное имя класса
   */
  className?: string;

  values?: OptionValueType[];
  width?: number;
}

export const Select = ({
  options,
  className,
  disabled,
  placeholder,
  values,
  onChange,
  width
}: SelectProps) => {
  const { isTablet } = useWindowSize();

  return (
    <div className={className}>
      <ReactSelect
        className="SelectContainer"
        classNamePrefix="Select"
        options={options}
        placeholder={placeholder}
        onChange={(option) => {
          onChange?.(option);
        }}
        value={values}
        disabled={disabled}
        multiple={true}
        styles={{
          container: (provided, state) => ({
            ...provided,
            fontFamily: `'TT Norms', system-ui, -apple-system, 'Segoe UI', 'Ubuntu', 'Cantarell',
          'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
          'Noto Color Emoji'`,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            zIndex: state.isFocused ? 200 : 1,
            maxWidth: isTablet ? '100%' : 'unset',
            minWidth: isTablet ? '100%' : 'unset',
            cursor: 'pointer'
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
          }),
          control: (provided, state) => ({
            ...provided,
            backgroundColor: '#ffffff',
            border: `1px solid ${state.isFocused ? '#fe724c' : '#e6e8ea'}`,
            boxShadow: 'none',
            maxWidth: 'calc(100vw - 32px)',

            ...(state.isFocused
              ? {
                  borderColor: '#fe724c'
                }
              : {}),

            ...(state.menuIsOpen
              ? {
                  width: width || (isTablet ? '100%' : '400px'),
                  borderBottomColor: 'transparent',
                  borderRadius: '4px 4px 0 0'
                }
              : {}),

            ':hover': {
              borderColor: '#fe724c',
              ...(state.menuIsOpen
                ? {
                    borderBottomColor: 'transparent'
                  }
                : {})
            }
          }),
          menu: (provided, state) => ({
            ...provided,
            width: width || '400px',
            maxWidth: 'calc(100vw - 32px)',
            marginTop: '-1px',
            borderRadius: '0 0 4px 4px',
            border: '1px solid #fe724c',
            borderTop: 'none',
            boxShadow: 'none'
          }),
          option: (provided, state) => ({
            padding: '8px 12px',

            ':hover': {
              backgroundColor: '#f9f9f9'
            }
          })
        }}
      />
    </div>
  );
};
