import React, { ReactNode } from 'react';

import StarImage from '../../../../assets/images/star.svg';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import s from './MainBanner.module.scss';

export interface MainBannerProps {
  title: string | ReactNode;
  description: string;
  actionButton: React.ReactNode;
  backgroundImage: React.ReactNode;
  firstCard: React.ReactNode;
  secondCard: React.ReactNode;
  background?: string;
}

export const MainBanner = ({
  title,
  description,
  actionButton,
  backgroundImage,
  firstCard,
  secondCard,
  background
}: MainBannerProps) => {
  const { isTablet, isMobile } = useWindowSize();

  return (
    <div
      className={s.MainBanner}
      style={background && { background: background }}
    >
      <div className={s.MainBanner__backgroundImage}>{backgroundImage}</div>
      <div className={s.MainBanner__content}>
        {!isMobile && (
          <img
            src={StarImage}
            alt=""
            className={s.MainBanner__starDecoration}
          />
        )}
        {!isMobile && !isTablet && (
          <div className={s.MainBanner__secondCard}>{secondCard}</div>
        )}
        {!isMobile && (
          <div className={s.MainBanner__firstCard}>{firstCard}</div>
        )}
        <div className={s.MainBanner__title}>{title}</div>
        <div className={s.MainBanner__description}>{description}</div>
        <div className={s.MainBanner__actionButton}>{actionButton}</div>
      </div>
    </div>
  );
};
