import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { FormControl } from '../../../common/components/ui/FormControl';
import { useOrderUpdate } from '../../../store/mutation/useOrderUpdate';
import { useOrderDeliveryMethod } from '../../../store/query/useOrderDeliveryMethod';
import { queryLoaderWithExistenceCheck } from '../../../common/components/utils/queryLoader';
import {
  DeliveryForm,
  DeliveryTypeEnum,
  IDeliveryFormData,
  IDeliveryOptions
} from '../common/DeliveryForm';
import { OrderBlockProps } from '../OrderPage';
import { applyFormValidationErrors, toastError } from '../../../utils/log';

export function DeliveryBlock({
  orderInfo,
  onStepChange,
  updateOrder,
  enabledSteps
}: OrderBlockProps) {
  const deliveryMethodsQuery = useOrderDeliveryMethod(
    orderInfo.id,
    orderInfo.secret
  );
  const deliveryMethodsData = deliveryMethodsQuery.state.data;

  const { run } = useOrderUpdate(orderInfo.id, orderInfo.secret);

  const deliveryMethods: IDeliveryOptions = useMemo(
    () => ({
      local: deliveryMethodsData?.filter((method) => method.is_local) || [],
      internal:
        deliveryMethodsData
          ?.filter((method) => !method.is_local)
          .map((delivery) => {
            if (delivery.code === 'dhl') {
              return {
                ...delivery,
                name: 'Служба доставки'
              };
            }
            return delivery;
          }) || []
    }),
    [deliveryMethodsData]
  );

  const onSubmit = useCallback(
    (
      {
        deliveryInternational,
        deliveryLocal,
        deliveryType,
        ...data
      }: IDeliveryFormData,
      formMethods
    ) => {
      const deliveryCode =
        deliveryType === DeliveryTypeEnum.russia
          ? deliveryLocal
          : deliveryInternational;

      const deliveryMethod = deliveryMethodsData.find(
        (i) => i.code === deliveryCode
      );

      run({
        delivery_method_id: deliveryMethod.id + '',
        ...data
      })
        .then(() => {
          updateOrder();
          onStepChange(3);
        })
        .catch((err: unknown) => {
          if (err instanceof AsyncSingletonError) return;
          applyFormValidationErrors(formMethods, err);
          toastError(err);
        });
    },
    [deliveryMethodsData, onStepChange, updateOrder, run]
  );

  const defaultData: IDeliveryFormData = useMemo(() => {
    const orderData = orderInfo.order;
    const methodId = orderInfo.order?.delivery_method_id;
    const local = deliveryMethods.local?.find((i) => i.id === methodId)?.code;
    const international = deliveryMethods.internal?.find(
      (i) => i.id === methodId
    )?.code;

    return {
      ...orderData,
      deliveryLocal: local || deliveryMethods.local[0]?.code,
      deliveryInternational: international || deliveryMethods.internal[0]?.code,
      deliveryType: international
        ? DeliveryTypeEnum.international
        : DeliveryTypeEnum.russia
    };
  }, [deliveryMethods.internal, deliveryMethods.local, orderInfo.order]);

  return (
    <>
      {queryLoaderWithExistenceCheck(deliveryMethodsQuery) || (
        <FormControl<IDeliveryFormData>
          onSubmit={onSubmit}
          defaultData={defaultData}
        >
          <Helmet title={'Доставка'} />
          <DeliveryForm
            orderInfo={orderInfo}
            deliveryMethods={deliveryMethods}
            enabledSteps={enabledSteps}
          />
        </FormControl>
      )}
    </>
  );
}
