import React, { Fragment, useEffect } from 'react';
import { useStore } from '@proscom/prostore-react';
import { Helmet } from 'react-helmet';
import { tryParseIso } from '@proscom/ui-utils-date';
import { CardConditionEnum } from '../../../common/components/ui/Card/CardStatusEnum';
import { OrderLayout } from '../../../common/components/layout/OrderLayout/OrderLayout';
import { BackLink } from '../../../common/components/ui/BackLink/BackLink';
import { OrderDetails } from '../../../common/components/ui/Order/OrderDetails';
import { useQuery } from '../../../utils/useQuery';
import { OrderBlockProps } from '../OrderPage';
import { useCards } from '../../../store/query/useCards';
import { queryLoaderWithExistenceCheck } from '../../../common/components/utils/queryLoader';
import { CardOrderBlock } from '../../../common/components/ui/Card/CardBlock/CardOrderBlock';
import { STORE_CART } from '../../../store/stores';
import { CartStore } from '../../../store/CartStore';
import { StepBlock, StepsType } from '../common/StepBlock/StepBlock';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { OrderPaymentError } from '../common/OrderErrorBlocks';
import { OrderStatusEnum } from '../../../constants';
import { IOrderDetails } from '../../../utils/data';
import { ContactEmailLink } from '../../../common/components/ui/Contacts/ContactEmailLink';
import { OrderCardDetailedBlock } from '../../../common/components/ui/Card/CardBlock/OrderCardDetailedBlock';
import s from '../common.module.scss';

export function OrderBlock({
  orderId,
  enabledSteps
}: {
  orderId: number;
  enabledSteps?: StepsType;
}) {
  const { isMobile } = useWindowSize();

  return (
    <div className={s.OrderPage}>
      {!isMobile && (
        <StepBlock step={4} completed={[4]} enabledSteps={enabledSteps} />
      )}
      <div className={s.OrderDetails}>
        <div className={s.OrderDetails__title}>Заказ оформлен!</div>
        <div className={s.OrderDetails__description}>
          <p>
            Мы выслали письмо с подтверждением на вашу почту. Если у вас
            возникнут вопросы по статусу вашего заказа, пожалуйста, напишите нам
            на{' '}
            <ContactEmailLink
              className={s.OrderDetails__externalLink}
              orderId={orderId}
            />
          </p>
          <p>
            Номер вашего заказа:
            <span className={s.OrderDetails__textAccent}>{orderId}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export function OrderDetailsBlock({
  orderInfo
}: Pick<OrderBlockProps, 'orderInfo'>) {
  const orderCards = orderInfo.order?.order_cards || [];
  const cardsQuery = useCards(
    {
      include: 'originalCard.originalSet',
      filter: { id: orderCards.map((i) => i.card_id + '').join(',') }
    },
    true
  );

  const cards = cardsQuery.state.data;

  const todayDate = new Date();

  return (
    <div className={s.OrderDetailsBlock}>
      <div className={s.OrderDetailsBlock__title}>Состав заказа</div>
      <div className={s.OrderDetailsBlock__list}>
        {queryLoaderWithExistenceCheck(cardsQuery) ||
          orderCards.map(({ state_info, ...orderCard }, iCard) => {
            const conditions = (
              Object.keys(state_info) as CardConditionEnum[]
            ).filter((condition) => {
              const cardInfo = state_info[condition];
              return cardInfo.quantity !== 0;
            });
            const cardData = cards.data.find((i) => {
              return i.id === orderCard.card_id;
            });

            const cardReleaseDate = tryParseIso(
              orderCard.card.original_card.released_at
            );

            return (
              <Fragment key={orderCard.id}>
                {iCard !== 0 && <div className={s.OrderDetails__spacer} />}
                <OrderCardDetailedBlock
                  className={s.OrderDetailsBlock__card}
                  data={{
                    ...cardData,
                    ...orderCard
                  }}
                  outerTagList
                  actions={conditions.map((condition, iConditions) => {
                    const cardInfo = state_info[condition];

                    return (
                      <CardOrderBlock
                        key={`${orderCard.id}-${iConditions}`}
                        condition={condition}
                        price={cardInfo.price * cardInfo.quantity}
                        amount={cardInfo.quantity}
                        isPreorder={cardReleaseDate > todayDate}
                      />
                    );
                  })}
                />
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}

export function OrderDetailBlock({
  orderInfo,
  enabledSteps
}: {
  orderInfo: IOrderDetails;
  enabledSteps?: StepsType;
}) {
  const [cartState, cartStore] = useStore<CartStore>(STORE_CART);
  let [query, changeQuery] = useQuery();
  const { clear } = query;

  useEffect(() => {
    if (clear === 'true') {
      cartStore.clearCart();
    }
  }, [cartStore, clear]);

  const status = orderInfo?.order.status;

  return (
    <OrderLayout
      title={'Оформление заказа'}
      backLink={<BackLink title={'Главная'} to={'/'} />}
      rightBlock={
        <OrderDetails
          order={orderInfo.orderDetails}
          totalPrice={orderInfo.totalPrice}
        />
      }
    >
      <Helmet title={'Подтверждение заказа'} />
      {[
        OrderStatusEnum.PAYMENT_FAILED,
        OrderStatusEnum.CANCELLED,
        OrderStatusEnum.PAID_STOCK_NEGATIVE,
        OrderStatusEnum.WAITING_FOR_PAYMENT
      ].includes(status) ? (
        <OrderPaymentError orderInfo={orderInfo} />
      ) : (
        <OrderBlock orderId={orderInfo.id} enabledSteps={enabledSteps} />
      )}
      <OrderDetailsBlock orderInfo={orderInfo} />
    </OrderLayout>
  );
}
