import React, { ChangeEvent, useMemo } from 'react';
import clsx from 'clsx';
import { IntrinsicPropsWithoutRef } from '../../../types';
import { BaseInputProps } from './Input';

import { HelpBlockStatus, InputHelpBlock } from './InputHelpBlock';
import s from './Textarea.module.scss';

export interface TextareaProps
  extends BaseInputProps,
    Omit<IntrinsicPropsWithoutRef<'textarea'>, 'onChange'> {
  inputRef?: React.Ref<HTMLTextAreaElement>;

  /**
   * Произвольные классы для внутренних элементов
   */
  classes?: {
    textarea?: string;
  };
}

export const Textarea = ({
  value,
  onChange,
  invalid,
  disabled,
  className,
  inputRef,
  classes,
  infoText,
  errorText,
  ...props
}: TextareaProps) => {
  const handleChange = useMemo(
    () =>
      onChange &&
      ((e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value);
      }),
    [onChange]
  );

  return (
    <div
      className={clsx(s.Textarea, className, {
        [s.Textarea_invalid]: invalid
      })}
    >
      <textarea
        className={clsx(s.Textarea__control, classes?.textarea)}
        onChange={handleChange}
        value={value}
        ref={inputRef}
        disabled={disabled}
        {...props}
      />
      {infoText && (
        <InputHelpBlock status={HelpBlockStatus.info}>
          {infoText}
        </InputHelpBlock>
      )}
      {errorText && (
        <InputHelpBlock status={HelpBlockStatus.error}>
          {errorText}
        </InputHelpBlock>
      )}
    </div>
  );
};
