import React from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { CardColor } from '../../../../store/filterOptions/CardColor';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { URL_KEY_COLORS } from '../../../../store/urlKeys';
import { FilterCardBlock } from '../FilterCardBlock';
import { STORE_LOCATION } from '../../../../store/stores';
import { changeListFilterQuery } from '../../../../common/components/utils/filter';

export const FilterColors = React.memo(function FilterColors() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_COLORS
  ]);

  return (
    <FilterCardBlock header="Цвет">
      <CheckboxList direction={CheckboxListDirection.vertical}>
        {CardColor.cardColors.getOptions().map((option) => {
          return (
            <Checkbox
              key={option.value}
              checked={query[URL_KEY_COLORS]?.indexOf(option.value) > -1}
              onChange={(state) =>
                changeListFilterQuery(
                  query,
                  locationStore,
                  URL_KEY_COLORS,
                  option.value,
                  state
                )
              }
            >
              {option.label}
            </Checkbox>
          );
        })}
      </CheckboxList>
    </FilterCardBlock>
  );
});
