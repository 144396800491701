import React, { useMemo } from 'react';
import {
  URL_KEY_ARTISTS,
  URL_KEY_COLORS,
  URL_KEY_FORMAT,
  URL_KEY_IS_FOIL,
  URL_KEY_LANG,
  URL_KEY_MANACOST,
  URL_KEY_PRICE_FROM,
  URL_KEY_PRICE_TO,
  URL_KEY_RARITY,
  URL_KEY_SEARCH,
  URL_KEY_SETS,
  URL_KEY_SHOWCASE,
  URL_KEY_SORT,
  URL_KEY_STATES,
  URL_KEY_TYPES
} from '../../store/urlKeys';

export function useFilterCounter(filterData) {
  return useMemo(() => {
    let counter = 0;

    [
      URL_KEY_SEARCH,
      URL_KEY_SORT,
      URL_KEY_PRICE_FROM,
      URL_KEY_PRICE_TO,
      URL_KEY_IS_FOIL,
      URL_KEY_SHOWCASE,
      URL_KEY_ARTISTS,
      URL_KEY_COLORS,
      URL_KEY_FORMAT,
      URL_KEY_SETS,
      URL_KEY_MANACOST,
      URL_KEY_LANG,
      URL_KEY_RARITY,
      URL_KEY_TYPES,
      URL_KEY_STATES
    ].forEach((filter) => {
      if (filterData[filter]) {
        counter++;
      }
    });

    return counter;
  }, [filterData]);
}
