import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { useSearchPlaceholder } from '../../../hooks/useSearchPlaceholder';
import { Autocomplete } from '../Autocomplete/AutoComplete';
import { useAutocomplete } from '../../../../store/query/useAutocomplete';
import { URL_KEY_SEARCH } from '../../../../store/urlKeys';
import { STORE_LOCATION } from '../../../../store/stores';
import { useSearch } from '../../../hooks/useSearch';
import { SearchInput } from '../Input/SearchInput';
import s from './Header.module.scss';

export interface HeaderSearchProps {
  onSearchInputFocus?: (isFocused: boolean) => void;
}

export function HeaderSearch({ onSearchInputFocus }: HeaderSearchProps) {
  const [locationState, locationStore] =
    useStore<LocationStore>(STORE_LOCATION);
  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);
  const searchFormRef = useRef(null);

  const history = useHistory();
  const location = useLocation();

  const {
    search,
    isLoading,
    handleSearchChange,
    handleSearchFormSubmit,
    handleQueryLoaded
  } = useSearch();
  const searchTooltip = useSearchPlaceholder();

  const searchQuery = locationState.query[URL_KEY_SEARCH];

  const autoCompleteQuery = useAutocomplete(
    searchQuery && isAutocompleteVisible
      ? {
          q: searchQuery
        }
      : null
  );
  const autoCompleteData = autoCompleteQuery.state.data;
  const formattedAutoCompleteData = useMemo(
    () => (isLoading ? [] : autoCompleteData?.data.map((item) => item.title)),
    [isLoading, autoCompleteData]
  );
  const isAutocompleteLoaded = autoCompleteQuery.state.loaded;

  useEffect(() => {
    if (
      isAutocompleteLoaded &&
      autoCompleteQuery.state.variables?.[URL_KEY_SEARCH] === search
    ) {
      handleQueryLoaded();
    }
  }, [search, autoCompleteQuery, handleQueryLoaded, isAutocompleteLoaded]);

  const urlPreserver = locationStore.createUrlPreserver(locationState.query);

  const handleOptionClick = useCallback(
    (value: string) => {
      if (location.pathname === '/cards') {
        handleSearchChange(value, false);
        setIsAutocompleteVisible(false);
      } else {
        history.push('/cards?q=' + value);
      }
    },
    [history, location, handleSearchChange]
  );

  const handleChange = useCallback(
    (value) => {
      if (value.trim()) {
        setIsAutocompleteVisible(true);
      } else {
        setIsAutocompleteVisible(false);
      }
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  const handleBlur = useCallback(() => {
    onSearchInputFocus?.(false);
    setIsAutocompleteVisible(false);
  }, [onSearchInputFocus]);

  const handleEnterPressed = useCallback((e) => {
    setTimeout(() => e.target.blur(), 100);
  }, []);

  return (
    <form
      action="/cards"
      method="GET"
      className={s.Header__search}
      onSubmit={handleSearchFormSubmit}
      ref={searchFormRef}
    >
      <SearchInput
        value={search}
        onChange={handleChange}
        placeholder={`Например, "${searchTooltip}"`}
        name="q"
        onFocus={() => onSearchInputFocus?.(true)}
        onBlur={handleBlur}
      />
      {isLoading ||
      (isAutocompleteVisible &&
        (formattedAutoCompleteData?.length ||
          autoCompleteQuery.state.loading)) ? (
        <Autocomplete
          options={formattedAutoCompleteData}
          onOptionClick={handleOptionClick}
          isLoading={isLoading || autoCompleteQuery.state.loading}
          optionUrlFunc={(name) => urlPreserver('/cards?q=' + name)}
          onEnterPressed={handleEnterPressed}
        />
      ) : null}
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
}
