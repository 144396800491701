import React from 'react';
import { CardData, MetaCard } from '../../../../store/interfacesResponse';
import { useCardStates, useMetaCardStates } from '../../../hooks/useCardStates';
import { CardHelper } from '../../../../utils/helpers/Card';
import { CardLanguages } from '../../../../store/filterOptions/CardLanguages';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { CardState, CardStateVariant } from './CardState';

export interface MetaCardStatesListProps {
  data: MetaCard;
  showOnlyInCart?: boolean;
}

export interface CardStatesListProps {
  data: CardData;
  showOnlyInCart?: boolean;
}

export function MetaCardStatesList({ data }: MetaCardStatesListProps) {
  const { states, minPreorderPrice } = useMetaCardStates(data);

  const sortedStates = states.sort(
    (a, b) =>
      CardLanguages.getOrder(a.language) - CardLanguages.getOrder(b.language)
  );

  return (
    <div>
      {sortedStates.map((cardState, cardStateIndex) => (
        <CardState
          key={cardStateIndex}
          cardId={Number(cardState.id)}
          data={cardState}
          variant={cardState.state}
          cardIsFoil={data.is_foil}
          cardTitle={data.name}
          preorderPrice={minPreorderPrice}
          isMetaCard
        />
      ))}
    </div>
  );
}

export function CardStatesList({ data, showOnlyInCart }: CardStatesListProps) {
  const { states } = useCardStates(data, showOnlyInCart);

  return (
    <div>
      {states.map((cardState, cardStateIndex) => (
        <CardState
          key={cardStateIndex}
          cardId={data.id}
          data={cardState}
          variant={cardState.state}
          cardIsFoil={data.is_foil}
          cardTitle={CardHelper.getName(data)}
          showDeleteButton={showOnlyInCart}
        />
      ))}
    </div>
  );
}
