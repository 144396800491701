import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { RUBLE } from '@proscom/ui-utils';
import { ReactComponent as BagIcon } from '../../../../assets/icons/bag.svg';

import { Button, ButtonSize, ButtonVariant } from '../Button/Button';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { CartStore } from '../../../../store/CartStore';
import { STORE_CART, STORE_COMMON } from '../../../../store/stores';
import {
  NumberSlider,
  NumberSliderSize,
  NumberSliderVariant
} from '../NumberSlider/NumberSlider';
import { prettyNumber } from '../../../../utils/number';
import {
  getCardForTextarea,
  getStockDescription
} from '../../../../utils/data';
import { CommonStore } from '../../../../store/CommonStore';
import { CartStockTextState, useCart } from '../../../hooks/useCart';
import {
  DEFAULT_FIND_CARD_POPUP_CONTENT,
  DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT,
  DEFAULT_PREORDER_POPUP_CONTENT
} from '../Popups/FindCardPopup';
import { CardHelper } from '../../../../utils/helpers/Card';
import { CardConditionEnum, CardConditionType } from './CardStatusEnum';
import s from './CardAvailableBlock.module.scss';

export enum AvailableStatus {
  available = 'available', //состояние доступно для добавления в корзину
  preorder = 'preorder', //состояние, когда карточки еще нет в продаже, но появится в какое-то время
  notAvailable = 'notAvailable', //состояние, когда карточки нет на складе
  conditionNotAvailable = 'conditionNotAvailable', //состояние, когда есть на складе, но цена не указана (или указана неправильно)
  invalidPrices = 'invalidPrices' //состояние, когда у всех состояний карточки неправильная цена
}

export interface CardAvailableBlockProps {
  availableStatus?: AvailableStatus;
  cardId: number;
  condition?: CardConditionEnum;
  amount?: number;
  price?: number;
  onClick?: (e: React.MouseEvent) => void;
  buttonText?: string;
  cardTitle?: string;
  cardFoil?: boolean;
  maxPrice?: number;
}

interface CardAvailableButtonProps {
  isMobile: string;
  onClick?: (e: React.MouseEvent) => void;
  buttonText?: string;
  showIconOnMobile?: boolean;
  minimumPrice?: number;
}

export function PreorderButton({
  isMobile,
  onClick,
  minimumPrice = null
}: CardAvailableButtonProps) {
  return (
    <Button
      className={s.CardAvailableBlock__addToBasketButton}
      variant={ButtonVariant.secondary}
      size={ButtonSize.small}
      onClick={onClick}
      fullWidth
    >
      {CardHelper.getPreorderText(minimumPrice)}
    </Button>
  );
}

export function NotAvailableButton({
  isMobile,
  onClick,
  buttonText,
  showIconOnMobile = true
}: CardAvailableButtonProps) {
  return (
    <Button
      className={s.CardAvailableBlock__addToBasketButton}
      variant={ButtonVariant.secondary}
      size={ButtonSize.small}
      onClick={onClick}
      fullWidth
    >
      {isMobile && showIconOnMobile ? <BagIcon /> : buttonText}
    </Button>
  );
}

interface AvailableButtonProps {
  isMobile: string;
  cardId: number;
  condition: CardConditionEnum;
  maxStock: number;
  price?: number;
  isPreorder?: boolean;
}

export function AvailableButton({
  isMobile,
  cardId,
  condition,
  maxStock,
  price,
  isPreorder
}: AvailableButtonProps) {
  const { getCartCount, getConditionStockText, handleChangeCart } = useCart();
  const conditionStockText = getConditionStockText(cardId, condition, maxStock);
  const cartCount = getCartCount(cardId, condition);

  const priceValue = price ? prettyNumber(price, RUBLE) : '';

  return conditionStockText === CartStockTextState.notInCart ? (
    <Button
      className={s.CardAvailableBlock__addToBasketButton}
      fullWidth
      variant={isPreorder ? ButtonVariant.secondary : ButtonVariant.primary}
      size={ButtonSize.small}
      onClick={() => {
        handleChangeCart({
          cardId: cardId,
          condition: condition,
          value: 1,
          stockCount: maxStock
        });
      }}
    >
      {isMobile ? (
        <>
          <span className={s.CardAvailableBlock__buttonPrice}>
            {priceValue}
          </span>{' '}
          <BagIcon />
        </>
      ) : price ? (
        `${isPreorder ? 'Предзаказать' : 'В корзину'} за ${priceValue}`
      ) : isPreorder ? (
        'Предзаказать'
      ) : (
        'В корзину'
      )}
    </Button>
  ) : (
    <div
      className={clsx(s.CardAvailableBlock__addToBasketSlider, {
        [s.CardAvailableBlock_preorderState]: isPreorder
      })}
    >
      {price && (
        <div className={s.CardAvailableBlock__sliderPrice}>{priceValue}</div>
      )}
      <NumberSlider
        className={clsx(
          s.CardAvailableBlock__addToBasketButton,
          s.CardAvailableBlock__addToBasketSlider
        )}
        size={isMobile ? NumberSliderSize.small : NumberSliderSize.medium}
        value={cartCount}
        onChange={(value) => {
          handleChangeCart({
            cardId: cardId,
            condition: condition,
            stockCount: maxStock,
            value
          });
        }}
        variant={
          isPreorder
            ? NumberSliderVariant.secondary
            : NumberSliderVariant.primary
        }
        max={maxStock}
      />
    </div>
  );
}

export const CardAvailableBlock = ({
  availableStatus = AvailableStatus.available,
  cardId,
  condition,
  amount,
  price,
  maxPrice = 0,
  buttonText,
  cardTitle,
  cardFoil
}: CardAvailableBlockProps) => {
  const { isMobile } = useWindowSize();
  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const cart = cartStore.getItems();
  const [, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const count = useMemo(() => {
    const currentCard = cart.find((card) => card.id === cardId);

    return currentCard ? currentCard.conditions[condition] || 0 : 0;
  }, [cart, cardId, condition]);

  let labelText = (condition && CardConditionType.getName(condition)) || '';
  if (availableStatus === AvailableStatus.preorder) {
    labelText = 'Новинка! Скоро поступит в продажу.';
  } else if (availableStatus === AvailableStatus.notAvailable) {
    labelText = 'Нет в наличии';
  } else if (availableStatus === AvailableStatus.invalidPrices) {
    labelText = 'Цена не указана';
  }

  const amountText = getStockDescription(amount, count);

  const availableButton = (
    <AvailableButton
      isMobile={isMobile}
      cardId={cardId}
      condition={condition}
      maxStock={amount}
    />
  );

  return (
    <div
      className={clsx(s.CardAvailableBlock, {
        [s.CardAvailableBlock_not_available]:
          availableStatus === AvailableStatus.preorder ||
          availableStatus === AvailableStatus.invalidPrices ||
          availableStatus === AvailableStatus.notAvailable
      })}
    >
      <div className={s.CardAvailableBlock__label}>
        {condition ? (
          <>
            <div className={s.CardAvailableBlock__condition}>{condition}</div>
            <div className={s.CardAvailableBlock__conditionName}>
              {CardConditionType.getName(condition)}
            </div>
          </>
        ) : (
          <>
            <div>{labelText}</div>
            {maxPrice >= 1 && (
              <div className={s.CardAvailableBlock__conditionName}>
                Цена указана ориентировочно
              </div>
            )}
          </>
        )}
      </div>
      {(availableStatus === AvailableStatus.available ||
        availableStatus === AvailableStatus.conditionNotAvailable) && (
        <>
          <div
            className={clsx(s.CardAvailableBlock__available, {
              [s.CardAvailableBlock__available_warn]:
                count >= amount || (amount === 0 && count)
            })}
          >
            {amountText}
          </div>
          <div className={s.CardAvailableBlock__price}>
            {price >= 1 ? prettyNumber(price, RUBLE) : ' '}
          </div>
        </>
      )}
      {availableStatus === AvailableStatus.notAvailable && maxPrice >= 1 && (
        <>
          <div
            className={clsx(
              s.CardAvailableBlock__price,
              s.CardAvailableBlock__priceMax
            )}
          >
            ~{prettyNumber(maxPrice, RUBLE)}
          </div>
        </>
      )}
      <div className={s.CardAvailableBlock__button}>
        {count > 0 ? (
          availableButton
        ) : availableStatus === AvailableStatus.preorder ? (
          <PreorderButton
            isMobile={isMobile}
            onClick={() => {
              commonStore.setFindCardModalContent({
                ...DEFAULT_PREORDER_POPUP_CONTENT,
                textareaDefaultValue: getCardForTextarea({
                  id: cardId,
                  title: cardTitle,
                  isFoil: cardFoil,
                  condition
                })
              });
            }}
          />
        ) : availableStatus === AvailableStatus.notAvailable ||
          availableStatus === AvailableStatus.invalidPrices ? (
          <NotAvailableButton
            isMobile={isMobile}
            onClick={() => {
              const defaultModalContent =
                availableStatus === AvailableStatus.notAvailable
                  ? DEFAULT_FIND_CARD_POPUP_CONTENT
                  : DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT;

              commonStore.setFindCardModalContent({
                ...defaultModalContent,
                textareaDefaultValue: getCardForTextarea({
                  id: cardId,
                  title: cardTitle,
                  isFoil: cardFoil,
                  condition
                })
              });
            }}
            buttonText={buttonText}
          />
        ) : availableStatus === AvailableStatus.conditionNotAvailable ? (
          <NotAvailableButton
            buttonText={'Заказать поиск'}
            isMobile={isMobile}
            onClick={() => {
              const actionName = 'Заказать поиск';

              commonStore.setFindCardModalContent({
                title: actionName,
                description:
                  'Оставьте нам свою почту и мы сообщим вам когда найдем карточку',
                buttonText: actionName,
                textareaDefaultValue: getCardForTextarea({
                  id: cardId,
                  title: cardTitle,
                  isFoil: cardFoil,
                  condition
                })
              });
            }}
          />
        ) : (
          availableButton
        )}
      </div>
    </div>
  );
};
