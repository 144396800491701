import { ILocalStorageState } from '@proscom/prostore-local-storage';
import {
  ITransformedQuery,
  LocationStore
} from '@proscom/prostore-react-router';

export const changeListFilterQuery = (
  query: ITransformedQuery,
  locationStore: LocationStore,
  prop: string,
  value: string,
  state = true
) => {
  const propValue = query[prop] || [];

  if (state && propValue.indexOf(value) === -1) {
    locationStore.changeQuery({
      [prop]: propValue ? [...propValue, value] : [value]
    });
  } else {
    locationStore.changeQuery({
      [prop]: propValue.filter((item) => item !== value)
    });
  }
};
