import React from 'react';
import { ControlledInput } from '../../../common/components/ui/Input/FormControl/ControlledInput';
import { ControlledTextarea } from '../../../common/components/ui/Input/FormControl/ControlledTextarea';
import s from '../common.module.scss';

function AddressBlock() {
  return (
    <>
      <ControlledInput
        className={s.OrderDetails__input}
        name={'address'}
        placeholder={'Адрес, улица, номер дома'}
      />
      <div className={s.OrderDetails__inputs}>
        <ControlledInput
          className={s.OrderDetails__input}
          name={'apartment_number'}
          placeholder={'кв./офис'}
        />
        <ControlledInput
          className={s.OrderDetails__input}
          name={'floor_number'}
          placeholder={'Этаж'}
        />
        <ControlledInput
          className={s.OrderDetails__input}
          name={'entrance_number'}
          placeholder={'Подъезд'}
        />
        <ControlledInput
          className={s.OrderDetails__input}
          name={'intercom_code'}
          placeholder={'Домофон'}
        />
      </div>
    </>
  );
}

export function RussianPost() {
  return (
    <>
      <div className={s.OrderDetails__title}>Адрес получателя:</div>
      <div className={s.OrderDetails__deliveryBlock}>
        <div className={s.OrderDetails__inputs}>
          <ControlledInput
            className={s.OrderDetails__input}
            name={'city'}
            placeholder={'Город'}
          />
          <ControlledInput
            className={s.OrderDetails__input}
            name={'postcode'}
            placeholder={'Индекс'}
          />
        </div>
        <ControlledInput
          className={s.OrderDetails__input}
          name={'address'}
          placeholder={'Улица, номер дома, квартира'}
        />
      </div>
    </>
  );
}

export function DeliveryBoxberry() {
  return (
    <>
      <div className={s.OrderDetails__title}>Способ доставки:</div>
      <div className={s.OrderDetails__deliveryBlock}>
        <div className={s.OrderDetails__description}>
          Выберите удобный для вас пункт выдачи Boxberry. Точный адрес вы можете
          узнать на сайте{' '}
          <a
            className={s.OrderDetails__externalLink}
            href="https://boxberry.ru/find_an_office"
          >
            boxberry.ru/find_an_office
          </a>
          .
        </div>
        <ControlledInput
          className={s.OrderDetails__input}
          name={'address'}
          placeholder={'Введите адрес пункта выдачи'}
        />
      </div>
    </>
  );
}

export function DeliveryCourier() {
  return (
    <>
      <div className={s.OrderDetails__title}>Адрес получателя:</div>
      <div className={s.OrderDetails__deliveryBlock}>
        <AddressBlock />
        <ControlledTextarea
          className={s.OrderDetails__input}
          name={'comment'}
          placeholder={'Комментарий курьеру'}
        />
        <div className={s.OrderDetails__description}>
          Курьерская доставка осуществляется в пределах МКАД по будням с 9 до
          21. Курьер свяжется с вами, чтобы выбрать удобное время для доставки.
        </div>
      </div>
    </>
  );
}

export function DeliveryPickup() {
  return (
    <>
      <div className={s.OrderDetails__title}>Адрес для самовывоза:</div>
      <div className={s.OrderDetails__deliveryBlock}>
        <div className={s.OrderDetails__description}>
          <p>Москва, Малый Дровяной переулок 6</p>
          <p>+7 495 922-37-65</p>
          <p>Ежедневно с 11 до 23.</p>
        </div>
        <div className={s.OrderDetails__image}>
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A6bb5680642ad21e0479a3f7500376ed62edb03f51f0d90ea575df20ba373af85&amp;source=constructor"
            width="100%"
            height="304"
            frameBorder="0"
            title="Карта"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export function DeliveryInternational() {
  return (
    <>
      <div className={s.OrderDetails__title}>Адрес получателя:</div>
      <div className={s.OrderDetails__deliveryBlock}>
        <div className={s.OrderDetails__inputs}>
          <ControlledInput
            className={s.OrderDetails__input}
            name={'country'}
            placeholder={'Страна'}
          />
          <ControlledInput
            className={s.OrderDetails__input}
            name={'city'}
            placeholder={'Город'}
          />
          <ControlledInput
            className={s.OrderDetails__input}
            name={'postcode'}
            placeholder={'Индекс'}
          />
        </div>
        <ControlledInput
          className={s.OrderDetails__input}
          name={'address'}
          placeholder={'Адрес, улица, номер дома'}
        />
        <ControlledInput
          className={s.OrderDetails__input}
          name={'comment'}
          placeholder={'Комментарий (не обязательно)'}
        />
      </div>
    </>
  );
}
