import React from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { CardFormat } from '../../../../store/filterOptions/CardFormat';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { URL_KEY_FORMAT } from '../../../../store/urlKeys';
import { FilterCardBlock } from '../FilterCardBlock';
import { STORE_LOCATION } from '../../../../store/stores';
import { changeListFilterQuery } from '../../../../common/components/utils/filter';

export const FilterFormat = React.memo(function FilterFormat() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_FORMAT
  ]);

  return (
    <FilterCardBlock header="Формат">
      <CheckboxList
        direction={CheckboxListDirection.vertical}
        compactVisibleLimit={6}
      >
        {CardFormat.cardFormats.getOptions().map((option) => {
          return (
            <Checkbox
              key={option.value}
              checked={query[URL_KEY_FORMAT]?.indexOf(option.value) > -1}
              onChange={(state) =>
                changeListFilterQuery(
                  query,
                  locationStore,
                  URL_KEY_FORMAT,
                  option.value,
                  state
                )
              }
            >
              {option.label}
            </Checkbox>
          );
        })}
      </CheckboxList>
    </FilterCardBlock>
  );
});
