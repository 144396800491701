import React from 'react';
import { Link, LinkProps } from '../Link/Link';

export function ContactPhoneLink(props: LinkProps) {
  return (
    <Link href="tel:+79854911630" {...props}>
      +7 985 491-16-30
    </Link>
  );
}
