import 'symbol-observable';

// Полифилл для :focus-visible
import 'focus-visible';

import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import './_fonts.scss';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { ProstoreContext } from '@proscom/prostore-react';
import {
  LocationProvider,
  LocationStore
} from '@proscom/prostore-react-router';
import {
  AxiosClientsContext,
  AxiosClientsManager
} from '@proscom/prostore-axios-react';
import ReactModal from 'react-modal';
import {
  STORE_CARD_VIEW,
  STORE_CART,
  STORE_COMMON,
  STORE_LOCATION
} from './store/stores';
import { urlTransformers } from './store/urlKeys';
import { createAppHistory } from './appHistory';
import { App } from './App';
import { defaultClient } from './Client';
import { CardViewStore } from './store/CardViewStore';
import { CartStore } from './store/CartStore';
import { CommonStore } from './store/CommonStore';
import { YandexMetrika } from './common/components/utils/YandexMetrika';

const axiosContext = new AxiosClientsManager({
  default: defaultClient
});

const appHistory = createAppHistory();

const cardViewStore = new CardViewStore({ localStorage: window.localStorage });

// Создаем LocationStore для отслеживания изменений в адресной строке
// Передаем ему набор трансформеров, чтобы преобразовывать параметры
// из строки в значения и наоборот
const locationStore = new LocationStore({
  history: appHistory,
  transformers: urlTransformers
});

const commonStore = new CommonStore();

const cartStorageStore = new CartStore({ localStorage: window.localStorage });

const stores = {
  [STORE_COMMON]: commonStore,
  [STORE_LOCATION]: locationStore,
  [STORE_CARD_VIEW]: cardViewStore,
  [STORE_CART]: cartStorageStore
};

const root = document.getElementById('root');
ReactModal.setAppElement(root);

ReactDOM.render(
  <Router history={appHistory}>
    <AxiosClientsContext.Provider value={axiosContext}>
      <ProstoreContext.Provider value={stores}>
        <LocationProvider storeOrName={STORE_LOCATION}>
          <YandexMetrika />
          <App />
        </LocationProvider>
      </ProstoreContext.Provider>
    </AxiosClientsContext.Provider>
  </Router>,
  root
);
