import { stringify as stringifyQuery } from 'query-string';
import { cleanObject, RUBLE } from '@proscom/ui-utils';
import { CardData } from '../../store/interfacesResponse';
import { URL_KEY_CARD_LANG } from '../../store/urlKeys';
import { prettyNumber } from '../number';

export class CardHelper {
  static getName(card: CardData) {
    const originalCard = card.original_card;
    if (!originalCard) {
      return null;
    }

    return originalCard.printed_name || originalCard.name;
  }

  static getUrl(card: CardData) {
    return (
      `/cards/${card.meta_card_id}?` +
      stringifyQuery(
        cleanObject({
          [URL_KEY_CARD_LANG]: card.original_card?.lang
        })
      )
    );
  }

  static getPreorderText(price: number) {
    if (!price) {
      return 'Оформить предзаказ';
    }

    return 'Предзаказать за ' + prettyNumber(price, RUBLE);
  }

  static getMinimumPrice(card: CardData, useFixedPrice = false) {
    let minimumPrice = card.price_gm;

    [card.price_nm, card.price_hp, card.stock_sp, card.price_mp].forEach(
      (price) => {
        if (price > 0 && price < minimumPrice) {
          minimumPrice = price;
        }
      }
    );

    if (useFixedPrice && card.fixed_price && card.fixed_price < minimumPrice) {
      minimumPrice = card.fixed_price;
    }

    return minimumPrice;
  }
}
