import React from 'react';
import clsx from 'clsx';
import s from '../../../common/components/ui/Card/ExpansionCard.module.scss';

export function ExpansionsLoading() {
  return (
    <>
      <div className={clsx(s.ExpansionCard, s.ExpansionCard_loading)} key={0} />
      <div className={clsx(s.ExpansionCard, s.ExpansionCard_loading)} key={1} />
      <div className={clsx(s.ExpansionCard, s.ExpansionCard_loading)} key={2} />
      <div className={clsx(s.ExpansionCard, s.ExpansionCard_loading)} key={3} />
      <div className={clsx(s.ExpansionCard, s.ExpansionCard_loading)} key={4} />
    </>
  );
}
