import React, { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as IconDown } from '../../../../assets/icons/arrow_down.svg';

import s from './CheckboxList.module.scss';

export enum CheckboxListDirection {
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export interface CheckboxListProps extends React.HTMLProps<HTMLDivElement> {
  direction: CheckboxListDirection;
  compactVisibleLimit?: number;
}

export const CheckboxList = ({
  direction,
  compactVisibleLimit = 3,
  children,
  className,
  ...props
}: CheckboxListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const childrenCheckboxes = React.Children.toArray(children);

  const expandButton =
    childrenCheckboxes.length > compactVisibleLimit ? (
      <button
        className={s.CheckboxList__expand}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded
          ? 'Свернуть'
          : `Еще ${childrenCheckboxes.length - compactVisibleLimit}`}
        <IconDown className={s.CheckboxList__arrow_icon} />
      </button>
    ) : null;

  return (
    <div
      className={clsx(
        s.CheckboxList,
        s[`CheckboxList_direction_${direction}`],
        className,
        {
          [s[`CheckboxList_expanded`]]: isExpanded
        }
      )}
      {...props}
    >
      {isExpanded ? children : childrenCheckboxes.slice(0, compactVisibleLimit)}
      {expandButton}
    </div>
  );
};
