import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { UseFormMethods } from 'react-hook-form';
import { joinNonEmpty } from '@proscom/ui-utils';
import { ERROR_VALIDATION_MESSAGE } from '../constants';
import { ApiResponse } from '../store/interfacesResponse';
import { FormValidationError } from './error/FormValidationError';

/**
 * Выполняет функцию cb, если err является ошибкой AxiosError
 *
 * @param err - ошибка
 * @param cb - колбек, который вызывается сразу
 */
export function runIfAxiosError<T = ApiResponse, Result = void>(
  err: any,
  cb: (err: AxiosError<T>) => Result
): Result | undefined {
  if (err.isAxiosError) {
    return cb(err);
  }
  return undefined;
}

/**
 * Если ошибка err является ошибкой Axios, то дополняет сообщение message
 * кодом ошибки
 *
 * @param err - ошибка
 * @param message - сообщение об ошибке
 */
export function getApiErrorMessageWithCode(err: any, message: string) {
  let codeMessage = '';
  runIfAxiosError(err, (err) => {
    const errorCode = err.response?.data?.requestKey;
    if (errorCode) {
      codeMessage = '. Код ошибки: ' + errorCode;
    } else {
      codeMessage = '. Неизвестная ошибка';
    }
  });
  return joinNonEmpty([message, codeMessage], ' ');
}

/**
 * Выводит сообщение об ошибке как тост и в консоль.
 * Автоматически подставляет код ошибки из Api, если он есть
 *
 * @param err - объект ошибки
 * @param message - сообщение об ошибке
 */
export function toastError(
  err: any,
  message: string = 'Возникла ошибка при обработке запроса'
) {
  toast.error(getApiErrorMessageWithCode(err, message), {
    autoClose: false,
    closeOnClick: false
  });
  console.error(err);
}

export function applyFormValidationErrors(
  formMethods: UseFormMethods,
  err: unknown
) {
  if (err instanceof FormValidationError) {
    err.getErrors()?.forEach((invalidField) => {
      invalidField.messages.forEach((message) => {
        formMethods.setError(invalidField.field, {
          message
        });
      });
    });
  }
}

export function checkForFormValidationError(err: any) {
  runIfAxiosError(err, (err) => {
    const errorData = err.response.data;

    if (errorData?.message === ERROR_VALIDATION_MESSAGE) {
      throw new FormValidationError(
        errorData.errors,
        err.response.data?.requestKey
      );
    }
  });
}
