import React, { useEffect, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { CommonStore } from '../../../../store/CommonStore';
import { STORE_COMMON } from '../../../../store/stores';
import { OriginalSet } from '../../../../store/interfacesResponse';
import { MobileHeader, MobileHeaderVariant } from './MobileHeader';
import { DesktopHeader } from './DesktopHeader';

export const Header = () => {
  const { isMobile } = useWindowSize();

  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const setLinkProps = (set: OriginalSet) => {
    // todo: поменять позднее
    return {
      href: '#' + set.id
    };
  };

  const [currentFormatId, setCurrentFormatId] = useState(-1);

  const onCloseHandler = () => {
    commonStore.setMobileHeaderVariant(MobileHeaderVariant.normal);
    commonStore.closeHeader();
  };

  useEffect(() => {
    commonStore.setMobileHeaderVariant(MobileHeaderVariant.normal);
  }, [commonStore]);

  return isMobile ? (
    <MobileHeader
      variant={commonStoreState.mobileHeaderVariant}
      onChangeVariant={(variant) => commonStore.setMobileHeaderVariant(variant)}
      onModalClose={onCloseHandler}
      onSearchClose={onCloseHandler}
      currentFormatId={currentFormatId}
      setCurrentFormatId={setCurrentFormatId}
      setLinkProps={setLinkProps}
    />
  ) : (
    <DesktopHeader
      currentFormatId={currentFormatId}
      setCurrentFormatId={setCurrentFormatId}
      setLinkProps={setLinkProps}
    />
  );
};
