import {
  defineQueryTransformers,
  ExtractTransformedQueryParams,
  IQueryTransformer
} from '@proscom/prostore-react-router';
import { tryNumber } from '@proscom/ui-utils';

// Рекомендуется перечислять все query-параметры, используемые в приложении,
// в виде констант и использовать их только через эти константы.
// Так легче находить места в коде, где они используются.

export const URL_KEY_FILTER = 'filter';
export const URL_KEY_SEARCH = 'q';
export const URL_KEY_SORT = 'sort';
export const URL_KEY_PRICE_FROM = 'price_from';
export const URL_KEY_PRICE_TO = 'price_to';
export const URL_KEY_IS_FOIL = 'is_foil';
export const URL_KEY_SHOWCASE = 'showcase';
export const URL_KEY_ARTISTS = 'artists';
export const URL_KEY_COLORS = 'colors';
export const URL_KEY_FORMAT = 'format';
export const URL_KEY_SETS = 'sets';
export const URL_KEY_MANACOST = 'manacost';
export const URL_KEY_LANG = 'lang';
export const URL_KEY_RARITY = 'rarity';
export const URL_KEY_TYPES = 'types';
export const URL_KEY_STATES = 'states';
export const URL_KEY_PAGE = 'page';
export const URL_KEY_IN_SALE = 'inSale';
export const URL_KEY_CARD_LANG = 'cardLang';
export const URL_KEY_CARD_ID = 'cardId';
export const URL_KEY_CARD_STATE = 'cardState';

const NumberTransformer: IQueryTransformer<number | undefined> = {
  parse(value) {
    if (value === '') return undefined;
    return value && (tryNumber(value) ?? undefined);
  },
  stringify(value: number | undefined) {
    return value !== undefined ? value.toString() : '';
  }
};

const NumberArrayTransformer: IQueryTransformer<number[]> = {
  parse(value) {
    return value
      .toString()
      .split(',')
      .map((itemId) => parseFloat(itemId))
      .filter((itemId) => !Number.isNaN(itemId));
  },
  stringify(value: number[]) {
    return value.join(',');
  }
};

const StringArrayTransformer: IQueryTransformer<string[]> = {
  parse(value) {
    return (
      value
        ?.toString()
        .split(',')
        .filter((itemId) => itemId) || []
    );
  },
  stringify(value: string[]) {
    return value.join(',');
  }
};

const StringTransformer: IQueryTransformer<string | undefined> = {
  parse(value) {
    return value?.toString();
  },
  stringify(value) {
    return value;
  }
};

const BooleanTransformer: IQueryTransformer<boolean> = {
  parse(value) {
    return value === 'true';
  },
  stringify(value) {
    return value ? 'true' : undefined;
  }
};

// Трансформеры определяют, как парсить и сериализовать query-параметры из адресной строки
export const urlTransformers = defineQueryTransformers({
  [URL_KEY_ARTISTS]: StringArrayTransformer,
  [URL_KEY_SETS]: NumberArrayTransformer,
  [URL_KEY_FORMAT]: StringArrayTransformer,
  [URL_KEY_COLORS]: StringArrayTransformer,
  [URL_KEY_RARITY]: StringArrayTransformer,
  [URL_KEY_LANG]: StringArrayTransformer,
  [URL_KEY_STATES]: StringArrayTransformer,
  [URL_KEY_MANACOST]: NumberArrayTransformer,
  [URL_KEY_TYPES]: StringArrayTransformer,
  [URL_KEY_PRICE_FROM]: NumberTransformer,
  [URL_KEY_PRICE_TO]: NumberTransformer,
  [URL_KEY_PAGE]: NumberTransformer,
  [URL_KEY_CARD_ID]: NumberTransformer,
  [URL_KEY_CARD_LANG]: StringTransformer,
  [URL_KEY_CARD_STATE]: StringTransformer,
  [URL_KEY_IN_SALE]: BooleanTransformer
});

export type TransformedQueryParams = ExtractTransformedQueryParams<
  typeof urlTransformers
>;
