import React from 'react';
import clsx from 'clsx';
import s from './PageContentBlock.module.scss';

interface PageContentBlockProps {
  className?: string;
  children: React.ReactNode;
}

export function PageContentBlock({
  className,
  children
}: PageContentBlockProps): React.ReactElement {
  return <div className={clsx(s.PageContentBlock, className)}>{children}</div>;
}
