import { EnumHelpers } from '@proscom/ui-utils';

export class CardColor {
  static CardColors = {
    colorless: 'colorless',
    white: 'white',
    black: 'black',
    red: 'red',
    blue: 'blue',
    green: 'green',
    artifact: 'artifact',
    multicolored: 'multicolored',
    land: 'land'
  };

  static cardColors = new EnumHelpers(CardColor.CardColors, {
    colorless: { name: 'Бесцветные' },
    white: { name: 'Белые' },
    black: { name: 'Черные' },
    red: { name: 'Красные' },
    blue: { name: 'Синие' },
    green: { name: 'Зеленые' },
    artifact: { name: 'Артефакты' },
    multicolored: { name: 'Многоцветные' },
    land: { name: 'Земли' }
  });
}
