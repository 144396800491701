import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { apiUrl } from '../../config';
import { IOrderCreateResponse } from '../mutation/useOrderCreate';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  method: 'get'
};

export interface OrderQueryParams {
  secret: string;
  append: string;
  include: string;
}

export interface OrderQueryVars {
  url: string;
  params: OrderQueryParams;
}

const queryOptions: IUseAxiosQueryOptions<
  OrderQueryVars,
  IOrderCreateResponse
> = {
  query,
  mapData: (result) => result.data
};

export function useOrder(id: string | number, token: string) {
  return useAxiosQuery({
    queryOptions,
    variables: {
      url: `${apiUrl}/orders/${id}`,
      params: {
        secret: token,
        append: 'delivery_price,card_price,total_price',
        include: 'orderCards,deliveryMethod,payment'
      }
    }
  });
}
