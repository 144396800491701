import React from 'react';
import clsx from 'clsx';

import s from './ButtonRadio.module.scss';

export interface ButtonProps {
  /**
   * Заблокированное состояние
   */
  disabled?: boolean;

  /**
   * Произвольные классы
   */
  className?: string;

  /**
   * Активна ли кнопка
   */
  active?: boolean;

  /**
   * Id формы
   */
  id: string;

  /**
   * Собитие при клике
   * @param event
   */
  onClick?: (event) => void;

  /**
   * Содержимое кнопки
   */
  children?: React.ReactNode;
}

export const ButtonRadio = ({
  id,
  disabled,
  className,
  children,
  active,
  ...props
}: ButtonProps) => {
  return (
    <label
      className={clsx(
        s.ButtonRadio,
        { [s.ButtonRadio_active]: active },
        { [s.ButtonRadio_disabled]: disabled },
        className
      )}
      htmlFor={id}
    >
      <input id={id} type={'radio'} {...props} />
      {children}
    </label>
  );
};
