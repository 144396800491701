import React from 'react';
import { Select } from '../../../../common/components/ui/Select/Select';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { FilterCardBlock } from '../FilterCardBlock';
import { useCheckboxesFromSelect } from '../../../../common/hooks/useCheckboxesFromSelect';
import {
  FILTER_SELECT_DESKTOP_WIDTH,
  FILTER_SELECT_TABLET_WIDTH,
  MANACOST
} from '../../../../constants';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { URL_KEY_MANACOST } from '../../../../store/urlKeys';

export const FilterManacost = React.memo(function FilterManacost() {
  const { isMobile, isTablet } = useWindowSize();

  const {
    addItem: addManacost,
    removeItem: removeManacost,
    list: manacostList,
    selectedItems: selectedManacost
  } = useCheckboxesFromSelect(URL_KEY_MANACOST, MANACOST);

  return (
    <FilterCardBlock header="Мана-стоимость">
      <>
        <Select
          options={manacostList}
          values={null}
          width={
            !isMobile && !isTablet
              ? FILTER_SELECT_DESKTOP_WIDTH
              : isTablet
              ? FILTER_SELECT_TABLET_WIDTH
              : undefined
          }
          placeholder="Выбрать"
          onChange={(option) => addManacost(option.value)}
        />
        {selectedManacost.length > 0 && (
          <CheckboxList direction={CheckboxListDirection.vertical}>
            {selectedManacost.map((manacost) => (
              <Checkbox checked onChange={() => removeManacost(manacost.value)}>
                {manacost?.label}
              </Checkbox>
            ))}
          </CheckboxList>
        )}
      </>
    </FilterCardBlock>
  );
});
