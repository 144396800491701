import React, { Ref, useCallback } from 'react';
import clsx from 'clsx';

import MaskedInput from 'react-text-mask';
import PhoneInput from 'react-phone-number-input';

import { useFocus } from '@proscom/ui-react';
import { HelpBlockStatus, InputHelpBlock } from './InputHelpBlock';
import s from './Input.module.scss';

export interface BaseInputProps {
  /**
   * Колбэк изменения значения в поле ввода
   */
  onChange?: (value: string) => void;

  /**
   * Состояние, что в поле ввода неправильное значение
   */
  invalid?: boolean;

  /**
   * Растягивается на всю ширину
   */
  fullWidth?: boolean;

  /**
   * Сообщение-подсказка под полем ввода
   */
  infoText?: string;

  /**
   * Сообщение об ошибке под полем ввода
   */
  errorText?: string;
}

export interface InputProps
  extends BaseInputProps,
    Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  /**
   * Проброс реф ссылки
   */
  inputRef?: Ref<HTMLInputElement> | ((ref: HTMLInputElement) => void);

  /**
   * Тип поля ввода
   */
  type?: string;

  /**
   * Иконка слева
   */
  leftIcon?: JSX.Element;

  /**
   * Иконка справа
   */
  rightIcon?: JSX.Element;

  mask?: ((rawValue: string) => (RegExp | string)[]) | (RegExp | string)[];
  guide?: boolean;

  isPhone?: boolean;

  /**
   * Произвольные классы для дочерних элементов
   */
  classes?: {
    iconLeft?: string;
    iconRight?: string;
    input?: string;
  };
}

const InputComponent = ({ isPhone, mask, ...props }) => {
  return isPhone ? (
    // @ts-ignore
    <PhoneInput {...props} />
  ) : mask ? (
    <MaskedInput mask={mask} {...props} />
  ) : (
    <input {...(props as any)} />
  );
};

export const Input = ({
  type = 'text',
  invalid,
  disabled,
  leftIcon,
  rightIcon,
  fullWidth,
  className,
  classes,
  inputRef,
  infoText,
  errorText,
  value,
  onChange,
  mask,
  isPhone,
  ...props
}: InputProps) => {
  const { focused, ...focusProps } = useFocus<HTMLInputElement>(props);
  const handleChange = useCallback(
    (e) => {
      if (isPhone) {
        onChange(e || ''); //если используется PhoneInput, то в "е" уже сразу хранится значение
      } else {
        onChange(e.target.value);
      }
    },
    [onChange, isPhone]
  );

  return (
    <div
      className={clsx(s.Input, className, {
        [s.Input_invalid]: invalid,
        [s.Input_disabled]: disabled,
        [s.Input_focused]: focused,
        [s.Input_phone]: isPhone
      })}
    >
      <InputComponent
        mask={mask}
        isPhone={isPhone}
        type={type}
        className={clsx(s.Input__control, classes?.input, {
          [s.Input__control_leftIcon]: leftIcon,
          [s.Input__control_rightIcon]: rightIcon
        })}
        ref={inputRef}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        {...props}
        {...focusProps}
      />

      {leftIcon && (
        <div className={clsx(s.Input__leftIcon, classes?.iconLeft)}>
          {leftIcon}
        </div>
      )}

      {rightIcon && (
        <div className={clsx(s.Input__rightIcon, classes?.iconRight)}>
          {rightIcon}
        </div>
      )}

      {infoText && (
        <InputHelpBlock status={HelpBlockStatus.info}>
          {infoText}
        </InputHelpBlock>
      )}
      {errorText && (
        <InputHelpBlock status={HelpBlockStatus.error}>
          {errorText}
        </InputHelpBlock>
      )}
    </div>
  );
};
