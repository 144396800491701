import React, { useCallback, useMemo } from 'react';
import { AsyncSingletonError, useStore } from '@proscom/prostore-react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { OrderLayout } from '../../common/components/layout/OrderLayout/OrderLayout';
import { OrderDetails } from '../../common/components/ui/Order/OrderDetails';
import { CardConditionEnum } from '../../common/components/ui/Card/CardStatusEnum';
import {
  formatTime,
  getPriceUpdateTime,
  useCurrentDate
} from '../../utils/date';
import { useCards } from '../../store/query/useCards';
import { CartStore } from '../../store/CartStore';
import { STORE_CART } from '../../store/stores';
import { customQueryLoader } from '../../common/components/utils/queryLoader';
import {
  Spinner,
  SpinnerSize
} from '../../common/components/ui/Spinner/Spinner';
import { CardDetailedBlock } from '../../common/components/ui/Card/CardBlock/CardDetailedBlock';
import { Link } from '../../common/components/ui/Link/Link';
import { useOrderCreate } from '../../store/mutation/useOrderCreate';
import { OrderDetailsEnum } from '../../utils/data';
import { ReactComponent as BagIcon } from '../../assets/icons/bag.svg';
import { useWindowSize } from '../../utils/helpers/useWindowSize';
import { priceUpdateUncertainty } from '../../config';
import orderDetailsStyles from '../../common/components/ui/Order/OrderDetails.module.scss';
import s from './CartPage.module.scss';

function TimerAnnotation() {
  const currentDate = useCurrentDate(20);
  const [updateTime, prevUpdateTime] = getPriceUpdateTime();

  const timeUntilEnd = updateTime.getTime() - currentDate.getTime();
  const timeAfterPrev = currentDate.getTime() - prevUpdateTime.getTime();

  let message: React.ReactNode = null;

  if (timeAfterPrev < priceUpdateUncertainty * 1000) {
    message = <> Цены обновляются...</>;
  } else {
    message = <> Цена обновится через {formatTime(timeUntilEnd)}</>;
  }

  return (
    <span>
      Цена на карточки привязана к курсу доллара и обновляется раз в сутки.
      {message}
    </span>
  );
}

export function CartPage({ history }) {
  const { isMobile, isDesktop } = useWindowSize();

  const [, cartStore] = useStore<CartStore>(STORE_CART);
  const cart = cartStore.getItems();

  const orderCreateRequest = useOrderCreate();

  const variables = useMemo(() => {
    return cart.length > 0
      ? {
          include: 'originalCard.originalSet',
          filter: { id: cart.map((i) => i.id).join(',') }
        }
      : null;
  }, [cart]);

  const cardsQuery = useCards(variables, true);
  const cardsData = cardsQuery.state.data;
  const cards = cardsQuery.state.loaded ? cardsData?.data || [] : null;

  const totalOrderInfo = useMemo(() => {
    const info = {
      price: 0,
      count: 0
    };

    if (!cards) {
      return info;
    }

    cart.forEach((card) => {
      const conditions = Object.keys(card.conditions) as CardConditionEnum[];
      const cardData = cards.find((i) => i.id === card.id);
      if (!cardData) {
        return;
      }

      conditions.forEach((condition) => {
        const count = card.conditions[condition];
        const price = cardData.state_info[condition].price * count;
        info.count += count;
        info.price += price;
      });
    });
    return info;
  }, [cards, cart]);

  const handleUpdateCards = useCallback(() => {
    cardsQuery.load();
  }, [cardsQuery]);

  const handleDeleteCard = useCallback(
    (cardId, cardCondition) => {
      cartStore.removeFromCart(cardId, cardCondition);
    },
    [cartStore]
  );

  const onOrderCreate = () => {
    const orderData = cartStore.getOrderVariables();
    orderCreateRequest
      .run({ cards: orderData })
      .then((data) => {
        // Если заказ создан, то переходим на его страницу
        history.push(`/orders/${data.id}?token=${data.secret}`);
      })
      .catch((err) => {
        if (err instanceof AsyncSingletonError) return;
        // Остальные ошибки обработаны внутри useAsyncOperation,
        // поэтому здесь их можно игнорировать

        // В любом случае перезагружаем список карт в корзине
        handleUpdateCards();
      });
  };

  const cartDetails = (
    <>
      {customQueryLoader(
        cardsQuery,
        <div
          className={clsx(
            orderDetailsStyles.OrderDetails__detail,
            s.CartPage__loadingBlock
          )}
        >
          {isDesktop && <Spinner size={SpinnerSize.XXL} loading />}
        </div>
      ) || (
        <OrderDetails
          order={[
            {
              title: `Карты (${totalOrderInfo.count} шт)`,
              price: totalOrderInfo.price,
              type: OrderDetailsEnum.cards
            }
          ]}
          totalPrice={totalOrderInfo.price}
          buttonText={isMobile ? 'К оформлению' : 'Оформить заказ'}
          buttonProps={{
            disabled: totalOrderInfo.count === 0,
            spinner: orderCreateRequest.loading
          }}
          onButtonClick={onOrderCreate}
        />
      )}
    </>
  );

  return (
    <OrderLayout
      title={'Корзина'}
      rightBlock={cartDetails}
      mobileBottomBlock={cartDetails}
      contentAnnotation={<TimerAnnotation />}
    >
      <Helmet title={'Корзина'} />
      {customQueryLoader(
        cardsQuery,
        <div className={s.CartPage__loading}>
          <Spinner loading size={SpinnerSize.XXL} />
        </div>
      ) ||
        (cart.length > 0 ? (
          <>
            {cart.map((cartCard, iCartCard) => {
              const cardData = cards.find((i) => i.id === cartCard.id);
              if (!cardData) {
                return null;
              }

              const conditions = Object.keys(
                cartCard.conditions || {}
              ) as CardConditionEnum[];

              return (
                <CardDetailedBlock
                  key={cartCard.id}
                  data={cardData}
                  outerTagList
                  isOrderCard
                  isFullWidth={isMobile}
                />
              );
            })}
          </>
        ) : (
          <div className={s.CartPage__emptyCart}>
            <div className={s.CartPage__emptyCartIcon}>
              <BagIcon />
            </div>
            <div className={s.CartPage__emptyCartTitle}>
              В корзине ничего нет :(
            </div>
            <div className={s.CartPage__emptyCartLink}>
              Перейти в <Link to={'/cards'}>Каталог</Link>
            </div>
          </div>
        ))}
    </OrderLayout>
  );
}
