import React, { useCallback, useEffect } from 'react';
import { match as Match } from 'react-router-dom';
import { noop } from 'lodash-es';
import { useQuery } from '../../utils/useQuery';
import { IOrderDetails, makeOrderInfo } from '../../utils/data';
import { useOrder } from '../../store/query/useOrder';
import { customQueryLoader } from '../../common/components/utils/queryLoader';
import { OrderStatusEnum, PaymentStatusEnum } from '../../constants';
import { RecipientBlock } from './stages/RecipientBlock';
import { DeliveryBlock } from './stages/DeliveryBlock';
import { PaymentBlock } from './stages/PaymentBlock';
import { OrderDetailBlock } from './stages/OrderBlock';
import { OrderLoadingBlock } from './stages/OrderLoadingBlock';
import { StepsType } from './common/StepBlock/StepBlock';

export interface OrderBlockProps {
  orderInfo: IOrderDetails;
  onStepChange: (step: number, query?: { [key: string]: string }) => void;
  updateOrder: () => void;
  enabledSteps?: StepsType;
}

export interface OrderPageProps {
  match: Match<{ orderId: string }>;
}

export function OrderPage({ match }: OrderPageProps) {
  const { orderId } = match.params;
  let [query, changeQuery] = useQuery();

  const orderQuery = useOrder(orderId, query.token);
  const order = orderQuery.state.data;

  const step = +query.step || 1;
  let redirectedStep: number | null = null;
  let enabledSteps: StepsType = 0;

  let shouldRefetch = false;
  if (order) {
    switch (order.status) {
      case OrderStatusEnum.NEW:
        if (!query.step) {
          if (order.city) {
            if (step !== 3) {
              redirectedStep = 3;
            }
            enabledSteps = 3;
          } else if (order.name) {
            if (step !== 2) {
              redirectedStep = 2;
            }
            enabledSteps = 2;
          } else {
            if (step !== 1) {
              redirectedStep = 1;
            }
            enabledSteps = 1;
          }
        } else {
          if (order.city) {
            enabledSteps = 3;
          } else if (order.name) {
            enabledSteps = 2;
          } else {
            enabledSteps = 1;
          }
        }

        break;
      case OrderStatusEnum.DELIVERY:
      case OrderStatusEnum.CANCELLED:
        if (step !== 4) {
          redirectedStep = 4;
        }
        enabledSteps = 0;
        break;
      case OrderStatusEnum.WAITING_FOR_PAYMENT:
        if (order.payment && order.payment.status === PaymentStatusEnum.NEW) {
          shouldRefetch = true;
          if (!query.step) {
            redirectedStep = 4;
          }
          enabledSteps = 4;
        } else {
          if (step !== 3) {
            redirectedStep = 3;
          }
        }
        enabledSteps = 0;
        break;
      case OrderStatusEnum.PAID_STOCK_NEGATIVE:
      case OrderStatusEnum.PAYMENT_FAILED:
      default:
        if (!query.step) {
          redirectedStep = 4;
        }
        enabledSteps = 4;
        break;
    }
  }

  const onStepChange = useCallback(
    (step: number, query: { [key: string]: any } = {}) => {
      changeQuery({ step: step, ...query }, true);
    },
    [changeQuery]
  );

  useEffect(() => {
    if (redirectedStep) {
      onStepChange(redirectedStep);
    }
  }, [redirectedStep, onStepChange]);

  useEffect(() => {
    if (shouldRefetch && step === 4) {
      const timeoutId = setTimeout(() => {
        orderQuery.load().catch(noop);
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [shouldRefetch, orderQuery.load, step, orderQuery]);

  const orderInfo = makeOrderInfo(order, query.token);

  if (redirectedStep) {
    return <OrderLoadingBlock />;
  }

  return (
    <>
      {customQueryLoader(orderQuery, <OrderLoadingBlock />) ||
        (step === 1 ? (
          <RecipientBlock
            orderInfo={orderInfo}
            onStepChange={onStepChange}
            updateOrder={orderQuery.load}
            enabledSteps={enabledSteps}
          />
        ) : step === 2 ? (
          <DeliveryBlock
            orderInfo={orderInfo}
            onStepChange={onStepChange}
            updateOrder={orderQuery.load}
            enabledSteps={enabledSteps}
          />
        ) : step === 3 ? (
          <PaymentBlock
            orderInfo={orderInfo}
            onStepChange={onStepChange}
            updateOrder={orderQuery.load}
            enabledSteps={enabledSteps}
          />
        ) : step === 4 ? (
          <OrderDetailBlock orderInfo={orderInfo} enabledSteps={enabledSteps} />
        ) : null)}
    </>
  );
}
