import React, { useCallback, useMemo } from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { URL_KEY_STATES } from '../../../../store/urlKeys';
import { FilterCardBlock } from '../FilterCardBlock';
import { STORE_LOCATION } from '../../../../store/stores';
import { changeListFilterQuery } from '../../../../common/components/utils/filter';
import { CardConditionType } from '../../../../common/components/ui/Card/CardStatusEnum';

export const FilterState = React.memo(function FilterState() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_STATES
  ]);

  const handleAllFormatsAvailableChange = useCallback(
    (checkedState) => {
      if (!checkedState) {
        locationStore.changeQuery({
          [URL_KEY_STATES]: []
        });
      } else {
        locationStore.changeQuery({
          [URL_KEY_STATES]: CardConditionType.keys()
        });
      }
    },
    [locationStore]
  );

  const isAllFormatsAvailableChecked = useMemo(() => {
    return CardConditionType.keys().every(
      (cardStateKey) =>
        query[URL_KEY_STATES] &&
        query[URL_KEY_STATES]?.indexOf(cardStateKey) > -1
    );
  }, [query]);

  return (
    <FilterCardBlock header="Состояние">
      <CheckboxList
        direction={CheckboxListDirection.vertical}
        compactVisibleLimit={6}
      >
        <Checkbox
          checked={isAllFormatsAvailableChecked}
          onChange={handleAllFormatsAvailableChange}
        >
          Любое
        </Checkbox>
        {CardConditionType.getOptions('fullName').map((option) => {
          return (
            <Checkbox
              key={option.value}
              checked={query[URL_KEY_STATES]?.indexOf(option.value) > -1}
              onChange={(state) =>
                changeListFilterQuery(
                  query,
                  locationStore,
                  URL_KEY_STATES,
                  option.value,
                  state
                )
              }
            >
              {option.label}
            </Checkbox>
          );
        })}
      </CheckboxList>
    </FilterCardBlock>
  );
});
