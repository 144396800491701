import { Routes, RouteType } from '../common/components/utils/Routes';
import { DefaultLayout } from './_layouts/DefaultLayout';
import { IndexPage } from './index/IndexPage';
import { NotFoundPage } from './notFound/NotFoundPage';
import { CardPage } from './card/CardPage';
import { FilterLayout } from './_layouts/FilterLayout';
import { IndexPageLayout } from './_layouts/IndexPageLayout';
import { CardsPage } from './cards/CardsPage';
import { CartPage } from './cart/CartPage';
import { OrderPage } from './order/OrderPage';
import { SimpleLayout } from './_layouts/SimpleLayout';
import { DeliveryAndPaymentsPage } from './deliveryAndPayments/DeliveryAndPaymentsPage';
import { PoliciesPage } from './policies/PoliciesPage';
import { ErrorLayout } from './_layouts/ErrorLayout';

const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    component: IndexPageLayout(IndexPage)
  },
  {
    path: '/cards',
    exact: true,
    component: FilterLayout(CardsPage)
  },
  {
    path: '/cards/:cardId',
    exact: true,
    component: FilterLayout(CardPage)
  },
  {
    path: '/cart',
    exact: true,
    component: SimpleLayout(CartPage)
  },
  {
    path: '/orders/:orderId',
    exact: true,
    component: SimpleLayout(OrderPage)
  },
  {
    path: '/deliveryandpayments',
    exact: true,
    component: DefaultLayout(DeliveryAndPaymentsPage)
  },
  {
    path: '/policies',
    exact: true,
    component: DefaultLayout(PoliciesPage)
  },
  {
    component: ErrorLayout(NotFoundPage)
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
