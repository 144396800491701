import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Location } from 'history';

import clsx from 'clsx';
import { useStore } from '@proscom/prostore-react';
import { LocationStore } from '@proscom/prostore-react-router';
import { cleanObject } from '@proscom/ui-utils';
import { useClickOutside, useTimeoutRef } from '@proscom/ui-react';
import { Helmet } from 'react-helmet';
import { CardBlock } from '../../common/components/ui/Card/CardBlock/CardBlock';
import {
  Switcher,
  SwitcherOption
} from '../../common/components/ui/Switcher/Switcher';
import { Dropdown } from '../../common/components/ui/Dropdown/Dropdown';
import { Pagination } from '../../common/components/ui/Pagination/Pagination';
import { useWindowSize } from '../../utils/helpers/useWindowSize';
import { ReactComponent as SortIcon } from '../../assets/icons/dropdown.svg';

import { BackButton } from '../../common/components/ui/Button/BackButton';
import {
  STORE_CARD_VIEW,
  STORE_CART,
  STORE_LOCATION
} from '../../store/stores';
import { CardViewStore } from '../../store/CardViewStore';
import { CARD_STATES, SORT_OPTIONS } from '../../constants';
import { MetaCardDetailedBlock } from '../../common/components/ui/Card/CardBlock/MetaCardDetailedBlock';
import { NothingFoundScreen } from '../../common/components/ui/NothingFoundScreen/NothingFoundScreen';
import {
  URL_KEY_ARTISTS,
  URL_KEY_COLORS,
  URL_KEY_FORMAT,
  URL_KEY_IN_SALE,
  URL_KEY_IS_FOIL,
  URL_KEY_LANG,
  URL_KEY_MANACOST,
  URL_KEY_PAGE,
  URL_KEY_PRICE_FROM,
  URL_KEY_PRICE_TO,
  URL_KEY_RARITY,
  URL_KEY_SEARCH,
  URL_KEY_SETS,
  URL_KEY_SHOWCASE,
  URL_KEY_SORT,
  URL_KEY_STATES,
  URL_KEY_TYPES
} from '../../store/urlKeys';
import { CardLoadingList } from '../../common/components/ui/Card/CardLoadingList';
import { customQueryLoader } from '../../common/components/utils/queryLoader';
import { useDebouncedValue } from '../../common/hooks/useDebouncedValue';
import { useFilterCounter } from '../../common/hooks/useFilterCounter';
import { CartStore } from '../../store/CartStore';
import { useMetaCards } from '../../store/query/useMetaCards';
import { usePagesCount } from '../../store/query/usePageCount';
import { FilterButton } from './Filter/FilterButton';
import { FilterCard, FilterCardState } from './Filter/FilterCard';
import s from './CardsPage.module.scss';

export interface IndexPageProps {
  location: Location;
}

export function CardsPage({ location }: IndexPageProps) {
  const [locationState, locationStore] = useStore<LocationStore>(
    STORE_LOCATION
  );

  const [cardViewStoreState, cardViewStore] = useStore<CardViewStore>(
    STORE_CARD_VIEW
  );

  const [cartStoreState, cartStore] = useStore<CartStore>(STORE_CART);

  const { isMobile, isTablet, isTabletLand, isDesktop } = useWindowSize();
  const [isFilterOpen, setIsFilterOpen] = useState<FilterCardState>('hidden');

  const isFirstRenderRef = useRef(true);

  const filterRef = useRef(null);
  const urlQuery = locationState.query;
  const urlQuerySort = urlQuery[URL_KEY_SORT];

  const currentSortOption = useMemo(() => {
    if (!urlQuerySort) {
      return SORT_OPTIONS[0];
    }

    return SORT_OPTIONS.find((option) => option.value === urlQuerySort);
  }, [urlQuerySort]);

  useEffect(() => {
    if (isFilterOpen === 'visible' && (isMobile || isTablet)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isFilterOpen, isTablet, isMobile]);

  const [filter, filtersDebounced] = useDebouncedValue(
    useMemo(
      () =>
        cleanObject({
          colors: urlQuery[URL_KEY_COLORS],
          format: urlQuery[URL_KEY_FORMAT],
          price_from: urlQuery[URL_KEY_PRICE_FROM],
          price_to: urlQuery[URL_KEY_PRICE_TO],
          rarity: urlQuery[URL_KEY_RARITY],
          search: urlQuery[URL_KEY_SEARCH],
          lang: urlQuery[URL_KEY_LANG],
          artists: urlQuery[URL_KEY_ARTISTS],
          set: urlQuery[URL_KEY_SETS],
          cmc: urlQuery[URL_KEY_MANACOST],
          showcase: urlQuery[URL_KEY_SHOWCASE],
          is_foil: urlQuery[URL_KEY_IS_FOIL]
            ? urlQuery[URL_KEY_IS_FOIL] === 'true'
              ? 'true'
              : 'false'
            : undefined,
          state: urlQuery[URL_KEY_STATES]?.length
            ? urlQuery[URL_KEY_STATES]
            : urlQuery[URL_KEY_IN_SALE]
            ? CARD_STATES.map((state) => state.value)
            : undefined,
          type: urlQuery[URL_KEY_TYPES]
        }),
      [urlQuery]
    )
  );

  const page = urlQuery[URL_KEY_PAGE] || 1;
  const sort = urlQuery[URL_KEY_SORT] || SORT_OPTIONS[0].value;

  const variables = useMemo(
    () => ({
      page,
      include: 'originalCard.originalSet',
      sort,
      append: 'is_showcase',
      filter
    }),
    [filter, page, sort]
  );

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    locationStore.changeQuery(
      {
        [URL_KEY_PAGE]: 1
      },
      true
    );
  }, [filter, locationStore]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [urlQuery.page]);

  const cardsQuery = useMetaCards(variables);
  const cardsData = cardsQuery.state.data;

  const cardsItemsCount = usePagesCount(
    cardsQuery.state.loaded ? { filter } : null
  );
  const cardsItemsCountData = cardsItemsCount.state.data;

  const totalPages = useMemo(() => {
    if (!cardsData || !cardsItemsCountData) return 0;
    const total = Math.ceil(
      cardsItemsCountData.total / cardsData.meta.per_page
    );

    if (cardsQuery.state.loaded && !cardsData?.data.length && total < page) {
      return page;
    }

    return total;
  }, [cardsQuery, page, cardsItemsCountData, cardsData]);

  const isPaginationHidden = useMemo(() => {
    return (
      page === 1 &&
      totalPages === 1 &&
      cardsQuery.state.loaded &&
      cardsData.data.length === 0
    );
  }, [page, totalPages, cardsQuery, cardsData]);

  const renderedCardData = useMemo(() => {
    return cardsData?.data?.map((card, iCard) => {
      return cardViewStoreState.value === SwitcherOption.grid ? (
        <CardBlock key={iCard} isFullWidth={isMobile} data={card} />
      ) : (
        <MetaCardDetailedBlock key={iCard} isFullWidth={isMobile} data={card} />
      );
    });
    // Нужно обновлять список состояний при каждом изменении корзины, чтобы удалять из списка состояния у которых сток=0, но в корзине было >0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartStore, cardViewStoreState, isMobile, cardsData, cartStoreState]);

  const cardsMetaData = cardsData?.meta;

  const isFilterVisible =
    isTabletLand || isDesktop || ((isMobile || isTablet) && isFilterOpen);

  const urlPreserver = locationStore.createUrlPreserver(urlQuery);

  const cardsLoader = (
    <CardLoadingList
      amount={15}
      cardLoadingProps={{
        isCompact: cardViewStoreState.value === SwitcherOption.grid,
        isFullWidth: isMobile
      }}
    />
  );

  const filterCounter = useFilterCounter(locationState.query);

  const outsideClickTimeout = useTimeoutRef();
  const handleOutsideFilterCardClick = useCallback(() => {
    setIsFilterOpen('hiding');

    function hideFilters() {
      setIsFilterOpen('hidden');
    }

    outsideClickTimeout.set(hideFilters, 200);
  }, [outsideClickTimeout, setIsFilterOpen]);

  useClickOutside(
    filterRef,
    isTablet && isFilterOpen === 'visible' ? handleOutsideFilterCardClick : null
  );

  return (
    <div className={s.FilterPage}>
      <Helmet title={'Каталог'} />
      <div className={s.FilterPage__head}>
        <BackButton to="/">На главную</BackButton>
      </div>
      <div className={s.FilterPage__viewOptions}>
        <div className={s.FilterPage__sort}>
          <Dropdown
            items={SORT_OPTIONS}
            selectedId={currentSortOption.id}
            onChange={(option) => {
              locationStore.changeQuery({
                [URL_KEY_SORT]: option.value
              });
            }}
            classes={{
              control: s.FilterPage__sortDropdown,
              list: s.FilterPage__sortList
            }}
            headerContent={(isMobile || isTablet) && <SortIcon />}
          />
        </div>
        <Switcher
          current={cardViewStoreState.value}
          onChange={cardViewStore.setView}
        />

        {(isMobile || isTablet) && (
          <FilterButton
            opened={isFilterOpen === 'visible'}
            count={isFilterOpen !== 'visible' && filterCounter}
            onClick={
              isFilterVisible !== 'visible'
                ? () => setIsFilterOpen('visible')
                : null
            }
          />
        )}
      </div>
      {((!isTablet && !isMobile) ||
        ((isTablet || isMobile) && isFilterVisible !== 'hidden')) && (
        <div className={s.FilterPage__filter}>
          <FilterCard
            state={isFilterVisible}
            onApply={handleOutsideFilterCardClick}
            filterRef={filterRef}
          />
        </div>
      )}

      <div className={s.FilterPage__content}>
        {
          <div
            className={clsx(
              s.FilterPage__cards,
              s[`FilterPage__cards_${cardViewStoreState.value}`],
              {
                [s.FilterPage__cards_nothingFound]:
                  !cardsQuery.state.loading && renderedCardData?.length === 0
              }
            )}
          >
            {filtersDebounced
              ? cardsLoader
              : customQueryLoader(cardsQuery, cardsLoader, true) ||
                (renderedCardData?.length > 0 ? (
                  renderedCardData
                ) : (
                  <NothingFoundScreen page={page} totalPages={totalPages} />
                ))}
          </div>
        }

        {!isPaginationHidden && (
          <div className={s.FilterPage__pagination}>
            <Pagination
              currentPage={urlQuery.page || 1}
              // lastPageNumber={cardsMetaData.last_page || 1}
              lastPageNumber={totalPages || -1}
              adjacentPages={1}
              urlFunc={(page) => urlPreserver('/cards', { page })}
              onNavigate={(page) => locationStore.changeQuery({ page })}
            />
          </div>
        )}
      </div>
    </div>
  );
}
