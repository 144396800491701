import { AxiosRequestConfig } from 'axios';
import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { cleanObject } from '@proscom/ui-utils';
import { skipIfNull } from '@proscom/prostore';
import { apiUrl } from '../../config';
import { AutocompleteData } from '../interfacesResponse';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  url: `${apiUrl}/cards/autocomplete`,
  method: 'post'
};

export interface AutocompleteQueryResponse {
  data: AutocompleteData[];
  success: boolean;
  userKey: string;
  requestKey: string;
}

export interface AutocompleteQueryVars {
  q: string;
}

const queryOptions: IUseAxiosQueryOptions<
  AutocompleteQueryVars,
  AutocompleteQueryResponse
> = {
  query,
  mapVariables: (variables) => ({ params: variables }),
  skipQuery: skipIfNull(null)
};

export function useAutocomplete(variables?: AutocompleteQueryVars) {
  return useAxiosQuery({
    queryOptions,
    variables: variables ? cleanObject(variables) : null
  });
}
