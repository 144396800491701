import React from 'react';
import { FormatCard } from '../../../common/components/ui/Card/FormatCard';
import LegacyImage from '../../../assets/images/legacy_set.png';
import { Link } from '../../../common/components/ui/Link/Link';
import StandartImage from '../../../assets/images/standart_set.png';
import PioneerImage from '../../../assets/images/pioneer_set.png';
import ModernImage from '../../../assets/images/modern_set.png';
import SpecialImage from '../../../assets/images/special_set.png';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { ScrollBlock } from '../../../common/components/ui/ScrollBlock/ScrollBlock';
import s from '../IndexPage.module.scss';

export const Formats = () => {
  const { isMobile } = useWindowSize();

  return (
    <div className={s.IndexPage__setsBlock}>
      <ScrollBlock
        cardWidth={218}
        cardsCount={5}
        cardGap={isMobile ? 8 : 24}
        height={isMobile ? 390 : 400}
        className={s.IndexPage__sets}
        header="Форматы"
        invert
        linkProps={{
          to: '/cards'
        }}
      >
        <FormatCard
          key="1"
          title="Standard"
          image={<img src={StandartImage} alt="" />}
          to="/cards?format=standard"
          actionLink="Смотреть"
        />
        <FormatCard
          key="2"
          title="Pioneer"
          image={<img src={PioneerImage} alt="" />}
          to="/cards?format=pioneer"
          actionLink="Смотреть"
        />
        <FormatCard
          key="3"
          title="Modern"
          image={<img src={ModernImage} alt="" />}
          to="/cards?format=modern"
          actionLink="Смотреть"
        />
        <FormatCard
          key="4"
          title="Pauper"
          image={<img src={LegacyImage} alt="" />}
          to="/cards?format=pauper"
          actionLink="Смотреть"
        />
        <FormatCard
          key="5"
          title="EDH"
          image={<img src={SpecialImage} alt="" />}
          to="/cards?format=edh"
          actionLink="Смотреть"
        />
      </ScrollBlock>
    </div>
  );
};
