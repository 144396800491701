import React from 'react';
import clsx from 'clsx';
import { RUBLE } from '@proscom/ui-utils';
import { LocationStore } from '@proscom/prostore-react-router';
import { useStore } from '@proscom/prostore-react';
import { Card } from '../Card';
import { MetaCard } from '../../../../../store/interfacesResponse';
import { Tag } from '../../Tag/Tag';
import { Button, ButtonSize, ButtonVariant } from '../../Button/Button';
import { Link } from '../../Link/Link';
import { prettyNumber } from '../../../../../utils/number';
import { CardSize } from '../CardImage';
import { STORE_LOCATION } from '../../../../../store/stores';
import notInSaleIcon from '../../../../../assets/images/notinsale.svg';
import comingSoonIcon from '../../../../../assets/images/comingsoon.svg';
import { CardSetName } from '../../Set/CardSetName';
import { useMetaCardStates } from '../../../../hooks/useCardStates';
import { CardStateVariant } from '../../CardStates/CardState';
import { useCardTags } from '../../../../hooks/useCardTags';
import { MetaCardHelper } from '../../../../../utils/helpers/MetaCard';
import { CardHelper } from '../../../../../utils/helpers/Card';
import s from './CardBlock.module.scss';

export enum CardBlockSticker {
  none = 'none',
  comingSoon = 'comingSoon',
  notInSale = 'notInSale'
}

export interface CardBlockProps {
  data: MetaCard;
  className?: string;
  isFullWidth?: boolean;
  outerTagList?: boolean;
}

export function CardBlock({
  data,
  className,
  isFullWidth = false,
  outerTagList = false
}: CardBlockProps) {
  const [locationState, locationStore] =
    useStore<LocationStore>(STORE_LOCATION);

  const {
    commonState,
    priceRange,
    availableStates,
    validStates,
    minPreorderPrice
  } = useMetaCardStates(data);

  let buttonText = '';
  if (commonState === CardStateVariant.normal) {
    buttonText = `Купить от ${prettyNumber(priceRange?.minAvailable, RUBLE)}`;
  } else if (commonState === CardStateVariant.preorderFromStock) {
    buttonText = CardHelper.getPreorderText(minPreorderPrice);
  } else if (
    commonState === CardStateVariant.nonAvailable ||
    commonState === CardStateVariant.preorder
  ) {
    buttonText = 'Заказать поиск';
  } else if (
    commonState === CardStateVariant.invalidPrice ||
    commonState === CardStateVariant.invalidAllStates
  ) {
    buttonText = 'Оформить заявку';
  }

  const tags = useCardTags(data);
  const urlPreserver = locationStore.createUrlPreserver(locationState.query);

  return (
    <div
      className={clsx(s.CardBlock, className, s.CardBlock__isCompact, {
        [s.CardBlock__isFullWidth]: isFullWidth
      })}
    >
      <div className={s.CardBlock__cardContainer}>
        <Card
          foil={data.is_foil}
          className={s.CardBlock__image}
          images={MetaCardHelper.getImageUris(data)}
          size={CardSize.medium}
          enablePreview={true}
        />

        <div className={s.CardBlock__innerTagsList}>
          {!outerTagList &&
            tags.map((tag, tagIndex) => (
              <Tag key={tagIndex} variant={tag.variant}>
                {tag.label}
              </Tag>
            ))}
        </div>
      </div>
      <CardSetName originSet={data.original_set} />

      <div className={s.CardBlock__titleBlock}>
        <Link
          className={s.CardBlock__title}
          to={urlPreserver(`/cards/${data.id}`)}
          title={data.name}
        >
          {data.name}
        </Link>
        {outerTagList && (
          <div className={s.CardBlock__outerTagsList}>
            {tags.map((tag, key) => (
              <Tag key={key} variant={tag.variant}>
                {tag.label}
              </Tag>
            ))}
          </div>
        )}
      </div>

      <Button
        variant={
          commonState === CardStateVariant.normal
            ? ButtonVariant.primary
            : ButtonVariant.secondary
        }
        size={ButtonSize.small}
        className={s.CardBlock__actions}
        to={`/cards/${data.id}`}
      >
        {buttonText}
      </Button>

      {commonState === CardStateVariant.preorder ? (
        <img src={comingSoonIcon} alt="" className={s.CardBlock__comingSoon} />
      ) : commonState === CardStateVariant.nonAvailable ? (
        <img src={notInSaleIcon} alt="" className={s.CardBlock__notInSale} />
      ) : null}
    </div>
  );
}
