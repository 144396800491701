import React, { useMemo } from 'react';
import { SEARCH_PLACEHOLDERS } from '../../constants';

export function useSearchPlaceholder() {
  return useMemo(
    () =>
      SEARCH_PLACEHOLDERS[
        Math.floor(Math.random() * SEARCH_PLACEHOLDERS.length)
      ],
    []
  );
}
