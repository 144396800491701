import React, { HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import s from './TabButton.module.scss';

export enum CardButtonSize {
  small = 'small',
  medium = 'default'
}

export interface TabButtonProps extends HTMLAttributes<HTMLButtonElement> {
  title?: string;
  disabled?: boolean;
  active?: boolean;
  icon?: ReactNode;
  className?: string;
  size?: CardButtonSize;
}

export function TabButton({
  title,
  active,
  size = CardButtonSize.medium,
  className,
  ...props
}: TabButtonProps) {
  return (
    <button
      className={clsx(
        className,
        s.TabButton,
        { [s.TabButton_active]: active },
        s[`CardButton__${size}`]
      )}
      type={'button'}
      {...props}
    >
      {title}
    </button>
  );
}

TabButton.Size = CardButtonSize;
