import React, { useCallback, useEffect, useState } from 'react';
import {
  useContextStore,
  useObservableCallback,
  useSubject
} from '@proscom/prostore-react';
import { STORE_COMMON } from '../../../../store/stores';
import { CommonStore } from '../../../../store/CommonStore';
import { MobileHeaderVariant } from '../Header/MobileHeader';
import { isMobile$ } from '../../../../utils/helpers/useWindowSize';
import { CardPreviewModal } from './CardPreviewModal';
import { CardImage, CardImageProps, CardSize } from './CardImage';

export interface CardProps extends CardImageProps {}

export const Card = ({
  size,
  images,
  foil,
  className,
  alt,
  imageProps,
  enablePreview,
  onPreviewClick,
  ...props
}: CardProps) => {
  const isMobile = useSubject(isMobile$);
  const commonStore = useContextStore<CommonStore>(STORE_COMMON);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const handleClose = () => {
    setIsPreviewVisible(false);
  };

  const handlePreviewClick = useCallback(() => {
    commonStore.setPreviewCardModal({
      card: (
        <CardImage
          size={isMobile ? CardSize.large : CardSize.xlarge}
          images={images}
          foil={foil}
        />
      )
    });
  }, [foil, images, isMobile, commonStore]);

  return (
    <CardImage
      size={size}
      images={images}
      foil={foil}
      className={className}
      alt={alt}
      imageProps={imageProps}
      enablePreview={enablePreview}
      onPreviewClick={handlePreviewClick}
      {...props}
    />
  );
};
