import { FieldErrors } from 'react-hook-form/dist/types/errors';
import React, { ReactNode, useEffect } from 'react';
import {
  DeepPartial,
  FieldName,
  FieldValues,
  FormProvider,
  UnpackNestedValue,
  useForm,
  UseFormMethods
} from 'react-hook-form';
import { useYupValidationResolver } from '../../../utils/helpers/useValidationSchema';

export interface FormControlProps<TShape> {
  onSubmit: (
    data: UnpackNestedValue<TShape>,
    formMethods?: UseFormMethods<TShape>
  ) => void;
  defaultData?: UnpackNestedValue<DeepPartial<TShape>>;
  validationSchema?: any;
  children:
    | ((
        data: UnpackNestedValue<TShape>,
        errors: FieldErrors<TShape>
      ) => ReactNode)
    | ReactNode;
}

export function FormControl<T>({
  onSubmit,
  defaultData,
  validationSchema,
  children
}: FormControlProps<T>) {
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<T>({
    resolver,
    defaultValues: defaultData
  });
  const { watch, errors, setError } = methods;
  const data = watch();

  function handleSubmit(data) {
    onSubmit?.(data, methods);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        {typeof children === 'function' ? children(data, errors) : children}
      </form>
    </FormProvider>
  );
}
