import {
  IUseAxiosQueryOptions,
  useAxiosQuery
} from '@proscom/prostore-axios-react';
import { MetaCard } from '../interfacesResponse';
import { apiUrl } from '../../config';
import { BaseRequestConfig } from '../BaseRequestConfig';

const query: BaseRequestConfig = {
  method: 'get'
};

export interface MetaCardQueryProps {
  include?: string;
  append?: 'is_showcase';
}

export interface MetaCardQueryVars {
  url: string;
  params: MetaCardQueryProps;
}

const queryOptions: IUseAxiosQueryOptions<MetaCardQueryVars, MetaCard> = {
  query,
  mapData: (result) => result.data
};

export function useMetaCard(
  cardId: number | string,
  variables?: MetaCardQueryProps
) {
  return useAxiosQuery({
    queryOptions,
    variables: {
      url: `${apiUrl}/meta-cards/${cardId}`,
      params: {
        include: 'originalCard',
        ...variables
      }
    }
  });
}
