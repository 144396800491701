import React, { useMemo } from 'react';
import clsx from 'clsx';

import { Link } from '../Link/Link';
import { BackButton } from '../Button/BackButton';
import { FORMATS } from '../../../../constants';
import { useSets } from '../../../../store/query/useSets';
import { SpinnerSize } from '../Spinner/Spinner';
import { queryLoader } from '../../utils/queryLoader';
import { PopupSetName } from '../Set/PopupSetName';
import { ExpansionsProps, groupSetsByTypes } from './SetsPopup';
import s from './SetsPopup.module.scss';
import buttonStyles from '../Button/Button.module.scss';

export interface MobileSetsPopupProps {
  currentFormatId?: ExpansionsProps['currentFormatId'];
  onFormatClick?: ExpansionsProps['onFormatClick'];
  setLinkPropsFn?: ExpansionsProps['setLinkPropsFn'];
  onAllCardsClick?: () => void;
  onClose?: () => void;
}

export const MobileSetsPopup = ({
  currentFormatId,
  onFormatClick,
  setLinkPropsFn,
  onAllCardsClick,
  onClose
}: MobileSetsPopupProps) => {
  const currentFormat = useMemo(() => {
    return FORMATS.find((format) => {
      return format.id === currentFormatId;
    });
  }, [currentFormatId]);

  const setsQuery = useSets(
    currentFormatId > -1
      ? {
          filter: { format: currentFormat.value }
        }
      : null
  );
  const setsData = setsQuery.state.data;

  const groupedByTypesSets = useMemo(() => {
    if (!setsData) {
      return null;
    }

    return groupSetsByTypes(setsData);
  }, [setsData]);

  const formatTypes =
    groupedByTypesSets &&
    Object.keys(groupedByTypesSets).sort(
      (a, b) => groupedByTypesSets[a].length - groupedByTypesSets[b].length
    );

  return (
    <div className={s.SetsPopup}>
      <div className={s.SetsPopup__content}>
        {currentFormatId === -1 ? (
          <div className={s.SetsPopup__setList}>
            <div className={s.SetsPopup__header}>Выберите формат игры</div>

            {FORMATS.map((format) => (
              <button
                key={format.id}
                className={clsx(s.SetsPopup__set, {
                  [s.SetsPopup__set_selected]: format.id === currentFormatId
                })}
                onClick={() => onFormatClick?.(format.id)}
              >
                {format.name}
              </button>
            ))}

            <Link
              to="/cards"
              onClick={onAllCardsClick}
              className={clsx(
                buttonStyles.Button,
                buttonStyles.Button_variant_primary,
                buttonStyles.Button_size_small,
                s.SetsPopup__allCards
              )}
            >
              Перейти в каталог
            </Link>
          </div>
        ) : (
          <div className={s.SetsPopup__lists}>
            <BackButton onClick={() => onFormatClick(-1)}>
              Формат игры
            </BackButton>
            <div className={s.SetsPopup__header}>Выберите издание</div>
            {queryLoader(setsQuery, {
              size: SpinnerSize.medium,
              style: { marginTop: 200, marginLeft: 'calc(50% - 12px' }
            }) || (
              <div className={s.SetsPopup__setList}>
                {formatTypes.map((formatType, formatTypeId) => (
                  <div className={s.SetsPopup__group} key={formatTypeId}>
                    <div className={s.SetsPopup__groupTitle}>
                      {formatType.replace('_', ' ')}
                    </div>
                    {groupedByTypesSets[formatType].map((set) => {
                      return (
                        <PopupSetName
                          key={set.id}
                          set={set}
                          onClick={onClose}
                        />
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
