import { useAsyncOperation } from '@proscom/prostore-react';
import { useContextAxiosClient } from '@proscom/prostore-axios-react';
import { apiUrl } from '../../config';
import { checkForFormValidationError } from '../../utils/log';
import { ApiResponse, AxiosApiResponse } from '../interfacesResponse';

export interface CardFeedbackVars {
  name: string;
  email: string;
  body: string;
  to_sell: boolean;
}

export function useCardFeedback() {
  const client = useContextAxiosClient();
  return useAsyncOperation(
    (data: CardFeedbackVars) => {
      return client
        .post(`${apiUrl}/cards/offers`, data)
        .then((response: AxiosApiResponse) => response.data)
        .catch(
          (err: unknown): ApiResponse => {
            checkForFormValidationError(err);
            throw err;
          }
        );
    },
    {
      finishedTimeout: 5000,
      singleton: true
    }
  );
}
