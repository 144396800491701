import React from 'react';

import { Link, LinkProps } from '../Link/Link';
import s from './FormatCard.module.scss';

export interface SetCardProps extends LinkProps {
  title: string;
  image: React.ReactNode;
  actionLink: React.ReactNode;
}

export const FormatCard = ({ title, image, actionLink, ...props }) => {
  return (
    <Link className={s.SetCard} {...props}>
      <div className={s.SetCard__image}>{image}</div>
      <div className={s.SetCard__title}>{title}</div>
      <div className={s.SetCard__action}>{actionLink}</div>
    </Link>
  );
};
