import React, { useCallback } from 'react';
import clsx from 'clsx';
import { UnpackNestedValue } from 'react-hook-form';
import * as yup from 'yup';
import { Helmet } from 'react-helmet';
import { AsyncSingletonError } from '@proscom/prostore-react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Button } from '../../../common/components/ui/Button/Button';
import { Link } from '../../../common/components/ui/Link/Link';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { ControlledCheckbox } from '../../../common/components/ui/Input/FormControl/ControlledCheckbox';
import { ControlledInput } from '../../../common/components/ui/Input/FormControl/ControlledInput';
import { FormControl } from '../../../common/components/ui/FormControl';
import { BackLink } from '../../../common/components/ui/BackLink/BackLink';
import { OrderDetails } from '../../../common/components/ui/Order/OrderDetails';
import { StepBlock } from '../common/StepBlock/StepBlock';
import { OrderLayout } from '../../../common/components/layout/OrderLayout/OrderLayout';
import { useOrderUpdate } from '../../../store/mutation/useOrderUpdate';
import { OrderBlockProps } from '../OrderPage';
import { applyFormValidationErrors, toastError } from '../../../utils/log';
import { ControlledPhoneInput } from '../../../common/components/ui/Input/FormControl/ControlledPhoneInput';
import s from '../common.module.scss';

export interface RecipientBlockInterface {
  policyCheck: boolean;
  isDesktop: boolean;
}

export interface IOrderPersonalData {
  name: string;
  email: string;
  phone: string;
  policy: boolean;
}

export function RecipientForm({
  policyCheck,
  isDesktop
}: RecipientBlockInterface) {
  return (
    <div className={s.OrderDetails}>
      <Helmet title={'Получатель'} />
      <div className={s.OrderDetails__title}>Получатель:</div>
      <ControlledInput
        className={s.OrderDetails__input}
        placeholder={'ФИО как в паспорте'}
        name={'name'}
      />
      <div className={s.OrderDetails__inputs}>
        <ControlledPhoneInput
          className={clsx(s.OrderDetails__input, s.OrderDetails__tel)}
          placeholder={'Телефон'}
          name={'phone'}
        />
        <ControlledInput
          className={clsx(s.OrderDetails__input, s.OrderDetails__email)}
          placeholder={'E-mail'}
          name={'email'}
        />
      </div>
      <ControlledCheckbox className={s.OrderDetails__policy} name={'policy'}>
        Я ознакомился и согласен с{' '}
        <Link
          href="/documents/policy.pdf"
          className={s.OrderDetails__externalLink}
          target="_blank"
        >
          политикой обработки персональных данных
        </Link>{' '}
        и{' '}
        <Link
          href="/documents/terms_of_use.pdf"
          className={s.OrderDetails__externalLink}
          target="_blank"
        >
          пользовательским соглашением
        </Link>
        .
      </ControlledCheckbox>
      {isDesktop && (
        <Button
          className={s.OrderDetails__nextButton}
          disabled={!policyCheck}
          type={'submit'}
        >
          Выбрать способ доставки
        </Button>
      )}
    </div>
  );
}

const validationSchema = yup.object({
  name: yup.string().required('Обязательное поле').nullable(),
  email: yup.string().required('Обязательное поле').nullable(),
  phone: yup
    .string()
    .required('Обязательное поле')
    .test('phone-test', 'Неправильный формат телефона', (value) => {
      return isValidPhoneNumber(value);
    })
    .nullable(),
  policy: yup
    .boolean()
    .required(
      'Необходимо прочитать и согласиться с политикой обработки персональных данных и пользовательским соглашением'
    )
});

export function RecipientBlock({
  orderInfo,
  onStepChange,
  updateOrder,
  enabledSteps = 0
}: OrderBlockProps) {
  const { isDesktop, isMobile } = useWindowSize();
  const { run } = useOrderUpdate(orderInfo.id, orderInfo.secret);

  const onSubmit = useCallback(
    (data: UnpackNestedValue<IOrderPersonalData>, formMethods) => {
      const personalData = {
        name: data.name,
        phone: data.phone,
        email: data.email
      };

      run(personalData)
        .then(() => {
          updateOrder();
          onStepChange(2);
        })
        .catch((err: unknown) => {
          if (err instanceof AsyncSingletonError) return;
          applyFormValidationErrors(formMethods, err);
          toastError(err);
        });
    },
    [onStepChange, updateOrder, run]
  );

  return (
    <FormControl<IOrderPersonalData>
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      defaultData={{
        name: orderInfo.order.name,
        email: orderInfo.order.email,
        phone: orderInfo.order.phone
      }}
    >
      {({ policy }, errors) => (
        <OrderLayout
          title={'Оформление заказа'}
          backLink={<BackLink title={'Корзина'} to={'/cart'} />}
          rightBlock={
            <OrderDetails
              order={orderInfo.orderDetails}
              totalPrice={orderInfo.totalPrice}
              buttonText={
                isMobile
                  ? 'Дальше'
                  : !isDesktop
                  ? 'Выбрать способ доставки'
                  : null
              }
              buttonProps={{ disabled: !policy, type: 'submit' }}
            />
          }
        >
          <div className={s.OrderPage}>
            {!isMobile && <StepBlock step={1} enabledSteps={enabledSteps} />}
            <RecipientForm policyCheck={policy} isDesktop={isDesktop} />
          </div>
        </OrderLayout>
      )}
    </FormControl>
  );
}
