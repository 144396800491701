import React from 'react';
import { ErrorBoundary } from '../../common/components/utils/ErrorBoundary';
import { ErrorMessage } from '../../common/components/ui/ErrorMessage/ErrorMessage';
import { Header } from '../../common/components/ui/Header/Header';
import { Footer } from '../../common/components/ui/Footer/Footer';
import s from './DefaultLayout.module.scss';

export function IndexPageLayout<Props>(Comp: React.ComponentType<Props>) {
  return function (props: Props) {
    return (
      <>
        <Header />
        <ErrorBoundary component={ErrorMessage}>
          <Comp {...props} />
        </ErrorBoundary>
        <Footer />
      </>
    );
  };
}
