import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../../../../utils/helpers/useWindowSize';
import { Tag } from '../../Tag/Tag';
import { Card } from '../Card';
import { ReactComponent as ComingSoonIcon } from '../../../../../assets/images/comingsoon.svg';
import { ReactComponent as NotInSaleIcon } from '../../../../../assets/images/notinsale.svg';
import { Link } from '../../Link/Link';
import { CardSize } from '../CardImage';
import { CardHelper } from '../../../../../utils/helpers/Card';
import { CardSetName } from '../../Set/CardSetName';
import { CardStatesList } from '../../CardStates/CardStatesList';
import { CardData } from '../../../../../store/interfacesResponse';
import { useCardStates } from '../../../../hooks/useCardStates';
import { CardStateVariant } from '../../CardStates/CardState';
import { OriginalCardHelper } from '../../../../../utils/helpers/OriginalCard';
import { useCardTags } from '../../../../hooks/useCardTags';
import { CardBlockProps } from './CardBlock';
import s from './CardBlock.module.scss';

export interface CardDetailedBlockProps extends Omit<CardBlockProps, 'data'> {
  data: CardData;
  actions?: ReactNode;
  isOrderCard?: boolean;
  hasActionButtons?: boolean;
}

export const CardDetailedBlock = ({
  data,
  className,
  isFullWidth,
  outerTagList,
  actions,
  isOrderCard,
  hasActionButtons = false
}: CardDetailedBlockProps) => {
  const { isMobile, isTablet, isTabletLand } = useWindowSize();
  const originCard = data?.original_card;

  const tags = useCardTags(data, isOrderCard);

  let cardImageSize = CardSize.small;
  if (isTabletLand || isTablet) {
    cardImageSize = CardSize.xsmall;
  }

  if (isMobile) {
    cardImageSize = CardSize.xxsmall;
  }

  const { commonState } = useCardStates(data, true);

  return (
    <div
      className={clsx(s.CardBlock, className, {
        [s.CardBlock__isFullWidth]: isFullWidth,
        [s.CardBlock_cartCard]: isOrderCard
      })}
    >
      <div className={s.CardBlock__cardContainer}>
        <Card
          foil={data.is_foil}
          className={s.CardBlock__image}
          images={OriginalCardHelper.getImageUris(originCard)}
          size={cardImageSize}
          enablePreview
        />
      </div>

      {commonState === CardStateVariant.preorder ||
      commonState === CardStateVariant.preorderFromStock ? (
        <ComingSoonIcon className={s.CardBlock__comingSoon} />
      ) : !isOrderCard && commonState === CardStateVariant.nonAvailable ? (
        <NotInSaleIcon className={s.CardBlock__comingSoon} />
      ) : null}

      <CardSetName originSet={data.original_card.original_set} />

      <div className={s.CardBlock__titleBlock}>
        <Link to={CardHelper.getUrl(data)} className={s.CardBlock__title}>
          {CardHelper.getName(data)}
        </Link>
        <div className={s.CardBlock__outerTagsList}>
          {tags.map((tag, key) => (
            <Tag key={key} variant={tag.variant}>
              {tag.label}
            </Tag>
          ))}
        </div>
      </div>

      <div className={s.CardBlock__actions}>
        <CardStatesList data={data} showOnlyInCart={isOrderCard} />
      </div>
    </div>
  );
};
