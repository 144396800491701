import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { AsyncSingletonError, useStore } from '@proscom/prostore-react';
import { Helmet } from 'react-helmet';
import { useCardFeedback } from '../../../../store/mutation/useCardFeedback';
import { CommonStore } from '../../../../store/CommonStore';
import { STORE_COMMON } from '../../../../store/stores';
import { applyFormValidationErrors, toastError } from '../../../../utils/log';
import { CardBasePopup, IPopupFormData } from './CardBasePopup';

export const DEFAULT_PREORDER_POPUP_CONTENT = {
  title: 'Оформить предзаказ карточки',
  description:
    'Оставьте нам свою почту чтобы оформить предзаказ. Мы свяжемся с вами когда карточка поступит в продажу.',
  buttonText: 'Оформить предзаказ'
};

export const DEFAULT_FIND_CARD_POPUP_CONTENT = {
  title: 'Заказать поиск карточек',
  description:
    'Отправьте нам список карточек, которые вы хотите найти. Мы свяжемся с вами в ближайшее время!',
  buttonText: 'Отправить список карточек'
};

export const DEFAULT_INVALID_PRICE_CARD_POPUP_CONTENT = {
  title: 'Оформить заявку',
  description:
    'Заполните форму и наш менеджер свяжется с вами для уточнения деталей',
  buttonText: 'Отправить заявку'
};

export function FindCardPopup() {
  const [commonStoreState, commonStore] = useStore<CommonStore>(STORE_COMMON);

  const { run } = useCardFeedback();

  const onSubmitFind = useCallback(
    (data: IPopupFormData, formMethods) => {
      run({
        name: data.name,
        email: data.email,
        body: data.cards,
        to_sell: false
      })
        .then(() => {
          toast('Список карт успешно отправлен');
          commonStore.setFindCardModalContent(null);
        })
        .catch((err) => {
          if (err instanceof AsyncSingletonError) return;

          applyFormValidationErrors(formMethods, err);

          toastError(err);
        });
    },
    [run, commonStore]
  );

  return (
    <>
      {!!commonStoreState.findCardModal && (
        <Helmet title={commonStoreState.findCardModal?.title} />
      )}
      <CardBasePopup
        isOpen={!!commonStoreState.findCardModal}
        title={commonStoreState.findCardModal?.title}
        onSubmit={onSubmitFind}
        onRequestClose={() => {
          if (commonStoreState.findCardModal) {
            commonStore.setFindCardModalContent(null);
          }
        }}
        description={commonStoreState.findCardModal?.description}
        buttonText={commonStoreState.findCardModal?.buttonText}
        textareaDefaultValue={
          commonStoreState.findCardModal?.textareaDefaultValue
        }
      />
    </>
  );
}
