import React, { ReactNode, useEffect } from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';
import { useContextStore } from '@proscom/prostore-react';
import { Button } from '../Button/Button';
import { ReactComponent as IconClose } from '../../../../assets/icons/cross.svg';
import { CommonStore } from '../../../../store/CommonStore';
import { STORE_COMMON } from '../../../../store/stores';
import { MobileHeaderVariant } from '../Header/MobileHeader';
import s from './Popup.module.scss';

export interface PopupProps {
  isOpen: boolean;
  onClose?: (e?: React.MouseEvent) => void;
  className?: string;
  overlayClassName?: string;
  popupRef?: React.MutableRefObject<HTMLDivElement>;
  children?: ReactNode;
  classes?: {
    closeButton?: string;
  };
}

export function Popup({
  isOpen,
  onClose,
  children,
  className,
  overlayClassName,
  popupRef,
  classes,
  ...props
}: PopupProps) {
  const commonStore = useContextStore<CommonStore>(STORE_COMMON);

  const handleClose = (e: React.MouseEvent) => {
    commonStore.setMobileHeaderVariant(MobileHeaderVariant.normal);
    onClose(e);
  };

  function onCloseHandler() {
    commonStore.setMobileHeaderVariant(MobileHeaderVariant.normal);
    onClose();
  }

  useEffect(() => {
    if (isOpen) {
      commonStore.setMobileHeaderVariant(MobileHeaderVariant.modal);
    }

    return () => {
      if (isOpen && commonStore.isModalHeader()) {
        commonStore.setMobileHeaderVariant(MobileHeaderVariant.normal);
      }
    };
  }, [commonStore, isOpen]);

  return (
    <ReactModal
      contentRef={(node) => {
        if (popupRef) {
          popupRef.current = node;
        }
      }}
      isOpen={isOpen}
      onRequestClose={onCloseHandler}
      overlayClassName={clsx(s.PopupOverlay, overlayClassName)}
      htmlOpenClassName={'ReactModal__Html--open'}
      className={clsx(className, s.Popup)}
      {...props}
    >
      <Button
        className={clsx(
          s.Popup__closeButton,
          'Popup__button',
          classes?.closeButton
        )}
        onClick={handleClose}
      >
        <IconClose className={clsx(s.Popup__closeIcon, 'Popup__closeSvg')} />
      </Button>

      {children}
    </ReactModal>
  );
}
