import React, { useEffect, ReactNode } from 'react';
import clsx from 'clsx';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { Card } from './Card';

import s from './CardLoadingBlock.module.scss';
import cardBlockStyles from './CardBlock/CardBlock.module.scss';

export interface CardLoadingBlockProps {
  isCompact?: boolean;
  isFullWidth?: boolean;
}

export const CardLoadingBlock = ({
  isCompact = true,
  isFullWidth = false
}: CardLoadingBlockProps) => {
  return (
    <div
      className={clsx(cardBlockStyles.CardBlock, s.CardLoadingBlock, {
        [cardBlockStyles.CardBlock__isCompact]: isCompact,
        [s.CardLoadingBlock_list]: !isCompact,
        [cardBlockStyles.CardBlock__isFullWidth]: isFullWidth
      })}
    >
      <div className={s.CardLoadingBlock__image} />

      <div className={s.CardLoadingBlock__tags}>
        <span />
        <span />
      </div>

      <div className={s.CardLoadingBlock__expansion}>
        <span />
        <span />
      </div>

      <div className={s.CardLoadingBlock__title} />

      <div className={s.CardLoadingBlock__action} />
    </div>
  );
};
