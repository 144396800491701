import { WebStorageValueStore } from '@proscom/prostore-local-storage';
import { cloneDeep } from 'lodash-es';
import { CardConditionEnum } from '../common/components/ui/Card/CardStatusEnum';
import { IOrderQueryItem } from './mutation/useOrderCreate';

export interface ICartStorageOrder {
  id: number;
  conditions: { [key in CardConditionEnum]?: number };
}

export type ICartStorageState = {
  cards: ICartStorageOrder[];
};

export interface ICartStoreArgs {
  localStorage: Storage;
}

export const LOCAL_STORAGE_CART = 'cart';

export class CartStore extends WebStorageValueStore<ICartStorageState> {
  constructor({ localStorage }: ICartStoreArgs) {
    super(localStorage, LOCAL_STORAGE_CART, (x) => x);
  }

  _mutateState(cb: (cards: ICartStorageOrder[]) => ICartStorageOrder[]) {
    const nextCartState: ICartStorageOrder[] =
      cloneDeep(this.state.value?.cards) || [];
    return cb(nextCartState);
  }

  removeFromCart(
    id: number,
    type?: CardConditionEnum,
    clearEmpty: boolean = true
  ) {
    const cards = this._mutateState((cards) => {
      const iItem = cards.findIndex((i) => i.id === id);
      const item = cards[iItem];
      if (!(iItem >= 0 && item)) {
        return cards;
      }

      if (type) {
        if (item.conditions) {
          delete item.conditions[type];
        }

        if (clearEmpty) {
          const hasCartCount = Object.keys(item.conditions).some(
            (i) => typeof item.conditions[i] === 'number'
          );

          if (!hasCartCount) {
            cards.splice(iItem, 1);
          }
        }
      } else {
        cards.splice(iItem, 1);
      }
      return cards;
    });
    this.setValue({ cards });
  }

  onChangeCount(
    id: number,
    type: CardConditionEnum,
    count: number,
    clearEmpty: boolean = true
  ) {
    const cards = this._mutateState((cards) => {
      const iItem = cards.findIndex((i) => i.id === id);
      const item = cards[iItem];

      if (!item) {
        cards.push({ id, conditions: { [type]: count } });
        return cards;
      }

      if (!item.conditions) {
        item.conditions = item.conditions ? item.conditions : {};
      }

      item.conditions[type] = count;

      if (count === 0) {
        item.conditions[type] = count;

        if (clearEmpty) {
          delete item.conditions[type];

          const hasCartCount = Object.keys(item.conditions).some(
            (i) => !!item.conditions[i]
          );

          if (!hasCartCount) {
            cards.splice(iItem, 1);
            return cards;
          }
        }
      }

      return cards;
    });

    this.setValue({ cards });
  }

  getItemCount(id: number, type: CardConditionEnum): number {
    const nextCartState: ICartStorageOrder[] = this.state.value?.cards || [];
    const item = nextCartState.find((i) => i.id === id);
    return item?.conditions?.[type] ?? 0;
  }

  getItems(): ICartStorageOrder[] {
    return this.state.value?.cards || [];
  }

  getOrderVariables(): IOrderQueryItem[] {
    const cartState: ICartStorageOrder[] = this.state.value?.cards;
    const orderData: IOrderQueryItem[] = [];

    cartState.forEach((card) => {
      (Object.keys(card.conditions) as CardConditionEnum[]).forEach(
        (condition) => {
          if (card.conditions[condition] !== 0) {
            orderData.push({
              id: card.id,
              state: condition,
              amount: card.conditions[condition]
            });
          }
        }
      );
    });

    return orderData;
  }

  removeDeletedCards(availableCardsIds: number[]) {
    const cartCardsIds = this.state.value.cards.map((cartCard) => cartCard.id);

    cartCardsIds.forEach((cartCardId) => {
      if (availableCardsIds.indexOf(cartCardId) === -1) {
        this.removeFromCart(cartCardId);
      }
    });
  }

  clearCart() {
    this.setValue({ cards: [] });
  }
}
