import React from 'react';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/remove.svg';
import { Input, InputProps } from './Input';
import s from './SearchInput.module.scss';

export const SearchInput = ({
  className,
  value = '',
  onChange,
  placeholder,
  ...props
}: InputProps) => {
  return (
    <Input
      type="search"
      className={clsx(s.SearchInput, className)}
      value={value || ''}
      onChange={onChange}
      classes={{
        input: s.SearchInput__input
      }}
      placeholder={placeholder}
      rightIcon={
        value ? (
          <button
            className={s.SearchInput__clear}
            disabled={props.disabled}
            onClick={() => onChange('')}
            type="button"
          >
            <RemoveIcon />
          </button>
        ) : (
          <SearchIcon />
        )
      }
      {...props}
    />
  );
};
