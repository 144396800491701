import React from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { URL_KEY_IS_FOIL, URL_KEY_SHOWCASE } from '../../../../store/urlKeys';
import { FilterCardBlock } from '../FilterCardBlock';
import { STORE_LOCATION } from '../../../../store/stores';

export const FilterDecorations = React.memo(function FilterDecorations() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_IS_FOIL,
    URL_KEY_SHOWCASE
  ]);

  return (
    <FilterCardBlock header="Оформление">
      <CheckboxList direction={CheckboxListDirection.vertical}>
        <Checkbox
          key={1}
          checked={query[URL_KEY_IS_FOIL] === 'true'}
          onChange={(state) =>
            locationStore.changeQuery({
              [URL_KEY_IS_FOIL]: state ? 'true' : undefined
            })
          }
        >
          Foil
        </Checkbox>
        <Checkbox
          key={2}
          checked={query[URL_KEY_IS_FOIL] === 'false'}
          onChange={(state) =>
            locationStore.changeQuery({
              [URL_KEY_IS_FOIL]: state ? 'false' : undefined
            })
          }
        >
          Non-foil
        </Checkbox>
        <Checkbox
          key={3}
          checked={query[URL_KEY_SHOWCASE]}
          onChange={(state) =>
            locationStore.changeQuery({
              [URL_KEY_SHOWCASE]: state ? 'true' : undefined
            })
          }
        >
          Showcase
        </Checkbox>
      </CheckboxList>
    </FilterCardBlock>
  );
});
