import React from 'react';
import { CardBlock } from '../../../common/components/ui/Card/CardBlock/CardBlock';
import { useWindowSize } from '../../../utils/helpers/useWindowSize';
import { ScrollBlock } from '../../../common/components/ui/ScrollBlock/ScrollBlock';
import { useCards } from '../../../store/query/useCards';
import { CardLoadingList } from '../../../common/components/ui/Card/CardLoadingList';
import { customQueryLoader } from '../../../common/components/utils/queryLoader';
import { SORT_OPTIONS } from '../../../constants';
import { useMetaCards } from '../../../store/query/useMetaCards';
import s from '../IndexPage.module.scss';

export const NewCards = () => {
  const { isMobile } = useWindowSize();

  const cardsQuery = useMetaCards({
    include: 'originalCard.originalSet',
    append: 'is_showcase',
    sort: SORT_OPTIONS[0].value
  });
  const cardsData = cardsQuery.state.data;

  return (
    <ScrollBlock
      cardWidth={288}
      cardsCount={cardsData ? cardsData.data.length : 6}
      height={isMobile ? 450 : 530}
      cardGap={isMobile ? 8 : 24}
      className={s.IndexPage__newCards}
      header="Новые карточки"
      noScroll={cardsQuery.state.error}
      linkProps={{
        to: '/cards'
      }}
    >
      {customQueryLoader(
        cardsQuery,
        <CardLoadingList amount={15} cardLoadingProps={{ isCompact: true }} />
      ) ||
        cardsData.data?.map(
          (card, iCard) => <CardBlock key={iCard} data={card} />,
          true
        )}
    </ScrollBlock>
  );
};
