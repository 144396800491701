import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { RUBLE } from '@proscom/ui-utils';
import { PageContentBlock } from '../../common/components/layout/PageContentBlock/PageContentBlock';
import {
  ChipText,
  ChipTextProps
} from '../../common/components/ui/ChipText/ChipText';
import jcbImage from '../../assets/images/jcb.svg';
import mastercardImage from '../../assets/images/mastercard.svg';
import mirImage from '../../assets/images/mir.svg';
import visaImage from '../../assets/images/visa.svg';
import { prettyNumber } from '../../utils/number';
import s from './DeliveryAndPaymentsPage.module.scss';

const deliveryRussianInfo: ChipTextProps[] = [
  {
    title: 'Доставка курьером',
    text: 'Курьер доставит заказ по указанному адресу с 10:00 до 21:00. Курьер дополнительно свяжется с вами перед выездом. Курьерская доставка осуществляется только по Москве.'
  },
  {
    title: 'Доставка Почтой России',
    text: 'Доставка Почтой России осуществляется в любой населенный пункт. После заказа мы отправим вам трек-код для отслеживания посылки.'
  },
  {
    title: 'Доставка в пункт выдачи Boxberry',
    text: (
      <span>
        Мы можем доставить заказ в удобный для вас пункт выдачи Boxberry. Вы
        можете найти адрес ближайшего пункта выдачи на официальном сайте сервиса{' '}
        <a
          className={s.DeliveryAndPaymentsPage__linkPrimary}
          href="https://boxberry.ru/find_an_office"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          boxberry.ru/find_an_office
        </a>
        .
      </span>
    )
  },
  {
    title: 'Самовывоз',
    text: (
      <span>
        Самовывоз доступен по адресу:{' '}
        <span className={s.DeliveryAndPaymentsPage__linkPrimary}>
          Москва, Малый дровяной переулок 6
        </span>
        . Ежедневно с 11 до 23.
      </span>
    )
  }
];
const deliveryInternationalInfo: ChipTextProps[] = [
  {
    title: 'Доставка Почтой России',
    text: 'Доставка Почтой России осуществляется в любой населенный пункт. После заказа мы отправим вам трек-код для отслеживания посылки.'
  },
  {
    title: 'Доставка курьерской службой',
    text: 'Доставка осуществляется международными службами доставки. Мы подберем оптимальный вариант и после отправки сообщим вам трек-код для отслеживания.'
  }
];

const paymentInfo: ChipTextProps[] = [
  {
    title: 'Банковской картой',
    text: (
      <div>
        <p>
          Для выбора оплаты товара с помощью банковской карты на соответствующей
          странице необходимо нажать кнопку "Оплата заказа банковской картой".
          Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт
          следующих платёжных систем:
        </p>

        <ul className={s.DeliveryAndPaymentsPage__paymentSystems}>
          <li>
            <img src={mirImage} alt="МИР" /> МИР;
          </li>
          <li>
            <img src={visaImage} alt="VISA International" />
            VISA International;
          </li>
          <li>
            <img src={mastercardImage} alt="Mastercard Worldwide" />
            Mastercard Worldwide;
          </li>
          <li>
            <img src={jcbImage} alt="JCB" />
            JCB.
          </li>
        </ul>

        <p>
          Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на
          платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача
          информации осуществляется в защищённом режиме с использованием
          протокола шифрования SSL. В случае если Ваш банк поддерживает
          технологию безопасного проведения интернет-платежей Verified By Visa,
          MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа
          также может потребоваться ввод специального пароля.
        </p>

        <p>
          Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность
          сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК.
          Введённая информация не будет предоставлена третьим лицам за
          исключением случаев, предусмотренных законодательством РФ. Проведение
          платежей по банковским картам осуществляется в строгом соответствии с
          требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl,
          JCB.
        </p>
      </div>
    )
  },
  {
    title: 'Наличный расчёт',
    text: 'Если товар доставляется курьером, то оплата осуществляется наличными курьеру в руки. При получении товара обязательно проверьте комплектацию товара, наличие гарантийного талона и чека.'
  }
];

export function DeliveryAndPaymentsPage({ location }) {
  return (
    <>
      <Helmet title="Доставка и оплата" />
      <PageContentBlock>
        <h1 className={s.DeliveryAndPaymentsPage__header}>Доставка</h1>
        <div className={s.DeliveryAndPaymentsPage__content}>
          <div className={s.DeliveryAndPaymentsPage__block}>
            <h3 className={s.DeliveryAndPaymentsPage__contentHeader}>
              Доставка по России
            </h3>
            {deliveryRussianInfo.map((item, iItem) => {
              return <ChipText key={iItem} {...item} />;
            })}
          </div>
          <div className={s.DeliveryAndPaymentsPage__block}>
            <h3 className={s.DeliveryAndPaymentsPage__contentHeader}>
              Международная доставка
            </h3>
            {deliveryInternationalInfo.map((item, iItem) => {
              return <ChipText key={iItem} {...item} />;
            })}
          </div>
          <div className={s.DeliveryAndPaymentsPage__block}>
            <h3 className={s.DeliveryAndPaymentsPage__contentHeader}>
              Бесплатная доставка
            </h3>
            <p>
              Бесплатная доставка осуществляется от следующих сумм вашего
              заказа:
            </p>
            <ul>
              <li>
                <b>Почтой России</b> от {prettyNumber(2500, RUBLE)}
              </li>
              <li>
                <b>Курьером</b> от {prettyNumber(6000, RUBLE)}
              </li>
              <li>
                <b>ЕМС Почтой России</b> от {prettyNumber(7500, RUBLE)}
              </li>
              <li>
                <b>Службой доставки DHL</b> от {prettyNumber(45000, RUBLE)}
              </li>
            </ul>
          </div>
        </div>
      </PageContentBlock>
      <PageContentBlock>
        <h1 className={s.DeliveryAndPaymentsPage__header}>Оплата</h1>
        <div className={s.DeliveryAndPaymentsPage__content}>
          <div
            className={clsx(
              s.DeliveryAndPaymentsPage__block,
              s.DeliveryAndPaymentsPage__payment
            )}
          >
            <ChipText {...paymentInfo[0]} />
          </div>
          <div
            className={clsx(
              s.DeliveryAndPaymentsPage__block,
              s.DeliveryAndPaymentsPage__payment
            )}
          >
            <ChipText {...paymentInfo[1]} />
          </div>
        </div>
      </PageContentBlock>
    </>
  );
}
