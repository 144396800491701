import React, { useMemo } from 'react';
import { Select } from '../../../../common/components/ui/Select/Select';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { FilterCardBlock } from '../FilterCardBlock';
import { useArtists } from '../../../../store/query/useArtists';
import { useCheckboxesFromSelect } from '../../../../common/hooks/useCheckboxesFromSelect';
import { URL_KEY_ARTISTS } from '../../../../store/urlKeys';
import { useWindowSize } from '../../../../utils/helpers/useWindowSize';
import { FILTER_SELECT_TABLET_WIDTH } from '../../../../constants';

export const FilterArtists = React.memo(function FilterArtists() {
  const artistsQuery = useArtists({});
  const artistsData = artistsQuery.state.data;

  const { isTablet } = useWindowSize();

  // Т.к. на бекенде все-таки используется поиск по uuid художника, то чтобы унифицировать select, нужно немного видоизменить данные
  const modifiedArtistData = useMemo(() => {
    return (
      artistsData &&
      artistsData.map((artist) => ({
        id: artist.origin_id,
        name: artist.name
      }))
    );
  }, [artistsData]);

  const {
    addItem: addArtist,
    removeItem: removeArtist,
    list: artistsList,
    selectedItems: selectedArtists
  } = useCheckboxesFromSelect(URL_KEY_ARTISTS, modifiedArtistData);

  return (
    <FilterCardBlock header="Художник">
      <>
        <Select
          options={artistsList}
          values={null}
          placeholder="Выбрать"
          onChange={(option) => {
            addArtist(option.value);
          }}
          width={isTablet && FILTER_SELECT_TABLET_WIDTH}
        />
        {selectedArtists.length > 0 && (
          <CheckboxList direction={CheckboxListDirection.vertical}>
            {selectedArtists.map((artist) => (
              <Checkbox
                checked
                key={artist.id}
                onChange={() => removeArtist(artist?.value)}
              >
                {artist?.label}
              </Checkbox>
            ))}
          </CheckboxList>
        )}
      </>
    </FilterCardBlock>
  );
});
