import { EnumHelpers } from '@proscom/ui-utils';

export class CardLanguages {
  static CardLanguages = {
    ru: 'ru',
    en: 'en',
    es: 'es',
    fr: 'fr',
    de: 'de',
    it: 'it',
    pt: 'pt',
    ja: 'ja',
    ko: 'ko',
    zhs: 'zhs',
    zht: 'zht'
  };

  static cardLanguages = new EnumHelpers<
    typeof CardLanguages.CardLanguages,
    { name: string; printedCode: string; order: number }
  >(CardLanguages.CardLanguages, {
    ru: { name: 'Русский (RU)', printedCode: 'ru', order: 1 },
    en: { name: 'Английский (EN)', printedCode: 'en', order: 2 },
    es: { name: 'Испанский (ES)', printedCode: 'es', order: 3 },
    fr: { name: 'Французский (FR)', printedCode: 'fr', order: 4 },
    de: { name: 'Немецкий (DE)', printedCode: 'de', order: 5 },
    it: { name: 'Итальянский (IT)', printedCode: 'it', order: 6 },
    pt: { name: 'Португальский (PT)', printedCode: 'pt', order: 7 },
    ja: { name: 'Японский (JA)', printedCode: 'ja', order: 8 },
    ko: { name: 'Корейский (KO)', printedCode: 'ko', order: 9 },
    zhs: { name: 'Китайский Упрощенный (CS)', printedCode: 'cs', order: 10 },
    zht: { name: 'Китайский Традиционный (CT)', printedCode: 'ct', order: 11 }
  });

  static getPrintedCode(lang: string) {
    return CardLanguages.cardLanguages.info[lang]?.printedCode;
  }

  static getOrder(lang: string) {
    return CardLanguages.cardLanguages.info[lang]?.order;
  }
}
