import React from 'react';
import { UnpackNestedValue, UseFormMethods } from 'react-hook-form';
import * as yup from 'yup';
import { Popup } from '../Popup/Popup';
import { Button } from '../Button/Button';
import { ControlledInput } from '../Input/FormControl/ControlledInput';
import { ControlledTextarea } from '../Input/FormControl/ControlledTextarea';
import { FormControl } from '../FormControl';
import s from './CardBasePopup.module.scss';

export interface IPopupFormData {
  name: string;
  email: string;
  cards: string;
}

export interface FeedBackModalContent {
  title: string;
  description: string;
  buttonText: string;
  textareaDefaultValue?: string;
}

export interface ICardBasePopup extends FeedBackModalContent {
  isOpen: boolean;
  onSubmit: (
    data: UnpackNestedValue<IPopupFormData>,
    formMethods?: UseFormMethods<IPopupFormData>
  ) => void;
  onRequestClose?: () => void;
}

const PopupFormDataDefault: IPopupFormData = {
  name: '',
  email: '',
  cards: ''
};

const validationSchema = yup.object({
  name: yup.string().required('Обязательное поле'),
  email: yup.string().required('Обязательное поле'),
  cards: yup.string().required('Обязательное поле')
});

export function CardBasePopup({
  isOpen,
  onRequestClose,
  title,
  description,
  buttonText,
  textareaDefaultValue,
  onSubmit
}: ICardBasePopup) {
  return (
    <Popup className={s.CardBasePopup} isOpen={isOpen} onClose={onRequestClose}>
      <FormControl<IPopupFormData>
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        defaultData={{ ...PopupFormDataDefault, cards: textareaDefaultValue }}
      >
        <h2 className={s.CardBasePopup__title}>{title}</h2>
        <div className={s.CardBasePopup__description}>{description}</div>
        <div className={s.CardBasePopup__inputs}>
          <ControlledInput name={'name'} placeholder={'Имя'} />
          <ControlledInput name={'email'} placeholder={'E-mail'} />
          <ControlledTextarea
            name={'cards'}
            placeholder={'Названия карточек'}
          />
        </div>
        <Button className={s.CardBasePopup__button} type={'submit'}>
          {buttonText}
        </Button>
      </FormControl>
    </Popup>
  );
}
