import React from 'react';
import clsx from 'clsx';
import { ReactComponent as MinusIcon } from '../../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';

import s from './NumberSlider.module.scss';

export enum NumberSliderSize {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export enum NumberSliderVariant {
  primary = 'primary',
  secondary = 'secondary'
}

export interface NumberSliderProps {
  /**
   * Заданное значение
   */
  value: number;

  /**
   * Колбек при изменении значения
   */
  onChange: (value: number) => void;

  /**
   * Размер слайдера ("small", "medium", "large")
   */
  size?: NumberSliderSize;

  /**
   * Неактивное состояние
   */
  disabled?: boolean;

  min?: number;

  max?: number;

  /**
   * Функция, которая возвращает нам строку для рендера
   * @param value
   */
  formatValue?: (value: number) => string;

  /**
   * Дополнительные стили
   */
  className?: string;

  /**
   * Дополнительные пропсы для Input
   */
  inputProps?: React.PropsWithoutRef<'input'>;

  variant?: NumberSliderVariant;
}

export const NumberSlider = ({
  size = NumberSliderSize.medium,
  value,
  disabled = false,
  formatValue,
  min = 0,
  max,
  className,
  onChange,
  inputProps,
  variant = NumberSliderVariant.primary,
  ...props
}: NumberSliderProps) => {
  const handleChange = (value, change) => {
    let nextValue = value + change;
    if (max !== undefined && nextValue > max) {
      nextValue = max;
    }
    if (min !== undefined && nextValue < min) {
      nextValue = min;
    }
    onChange(nextValue);
    return nextValue;
  };

  return (
    <div
      className={clsx(
        s.NumberSlider,
        s[`NumberSlider_size_${size}`],
        s[`NumberSlider_variant_${variant}`],
        className
      )}
      {...props}
    >
      <input type="hidden" value={value} {...inputProps} />

      <button
        className={s.NumberSlider__decrement}
        onClick={() => handleChange(value, -1)}
        disabled={disabled}
      >
        <MinusIcon />
      </button>

      <div className={s.NumberSlider__value}>
        {formatValue ? formatValue(value) : value}
      </div>

      <button
        className={s.NumberSlider__increment}
        onClick={() => handleChange(value, 1)}
        disabled={disabled}
      >
        <PlusIcon />
      </button>
    </div>
  );
};
