import { useEffect, useState } from 'react';
import { useTimeoutRef } from '@proscom/ui-react';
import { declinedText } from '@proscom/ui-utils';
import { formatDateIso } from '@proscom/ui-utils-date';
import { addDays, parseISO } from 'date-fns';
import { priceUpdateTime } from '../config';
import { div } from './number';

export function useCurrentDate(delay: number = 60): Date {
  const [date, setDate] = useState(new Date());
  const timeoutManager = useTimeoutRef();

  useEffect(() => {
    timeoutManager.set(() => {
      setDate(new Date());
    }, delay * 1000);
  }, [date, delay, timeoutManager]);

  return date;
}

export function formatTime(deltaTime: number) {
  const hour = div(deltaTime, 3600000);
  const minutes = div(deltaTime - hour * 3600000, 60000);

  if (hour === 0 && minutes < 2) {
    return 'Через 1 минуту';
  }

  const minutesText = declinedText(
    minutes,
    '$n минуту',
    '$n минуты',
    '$n минут'
  );

  if (hour === 0) {
    return minutesText;
  }

  const hoursText = declinedText(hour, '$n час', '$n часа', '$n часов');

  return hoursText + ' ' + minutesText;
}

export function getPriceUpdateTime() {
  const now = new Date();

  const prevDay = addDays(now, -1);
  const nextDay = addDays(now, 1);
  const prevTime = parseISO(formatDateIso(prevDay) + 'T' + priceUpdateTime);
  const todayTime = parseISO(formatDateIso(now) + 'T' + priceUpdateTime);
  const nextTime = parseISO(formatDateIso(nextDay) + 'T' + priceUpdateTime);

  if (todayTime > now) {
    return [todayTime, prevTime] as const;
  }
  return [nextTime, todayTime] as const;
}
