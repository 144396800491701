import React, { useCallback, useMemo } from 'react';
import { useLocationQuery } from '@proscom/prostore-react-router';
import {
  CheckboxList,
  CheckboxListDirection
} from '../../../../common/components/ui/Checkbox/CheckboxList';
import { Checkbox } from '../../../../common/components/ui/Checkbox/Checkbox';
import { URL_KEY_IN_SALE, URL_KEY_STATES } from '../../../../store/urlKeys';
import { FilterCardBlock } from '../FilterCardBlock';
import { STORE_LOCATION } from '../../../../store/stores';
import { changeListFilterQuery } from '../../../../common/components/utils/filter';
import { CardConditionType } from '../../../../common/components/ui/Card/CardStatusEnum';

export const FilterInSale = React.memo(function FilterState() {
  const [query, locationStore] = useLocationQuery(STORE_LOCATION, [
    URL_KEY_IN_SALE
  ]);

  const handleCheckboxChange = useCallback(
    (checkedState) => {
      locationStore.changeQuery({
        [URL_KEY_IN_SALE]: checkedState
      });
    },
    [locationStore]
  );
  return (
    <FilterCardBlock header="В наличии">
      <CheckboxList
        direction={CheckboxListDirection.vertical}
        compactVisibleLimit={1}
      >
        <Checkbox
          checked={query[URL_KEY_IN_SALE]}
          onChange={handleCheckboxChange}
        >
          Есть
        </Checkbox>
      </CheckboxList>
    </FilterCardBlock>
  );
});
