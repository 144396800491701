import { declineUnit, NBSP, Unit } from '@proscom/ui-utils';

export function div(value: number, by: number): number {
  return (value - (value % by)) / by;
}

export function prettyNumber(number: number, unit?: Unit) {
  let result = Intl.NumberFormat('ru-RU').format(number);
  if (!unit) {
    return result;
  }
  result += NBSP + declineUnit(number, unit);
  return result;
}
