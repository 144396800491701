import React from 'react';
import { Helmet } from 'react-helmet';
import { PageContentBlock } from '../../common/components/layout/PageContentBlock/PageContentBlock';
import { ChipText } from '../../common/components/ui/ChipText/ChipText';
import { Link } from '../../common/components/ui/Link/Link';
import s from './PoliciesPage.module.scss';

export function PoliciesPage({ location }) {
  return (
    <PageContentBlock>
      <Helmet title={'Юридические документы'} />
      <h1 className={s.PoliciesPage__header}>Юридические документы</h1>
      <ChipText
        title={'Договор публичной оферты'}
        className={s.PoliciesPage__policy}
        text={
          <Link
            className={s.PoliciesPage__link}
            href="/documents/oferta.pdf"
            target="_blank"
          >
            Скачать (437 Кб)
          </Link>
        }
      />
      <ChipText
        title={'Пользовательское соглашение'}
        className={s.PoliciesPage__policy}
        text={
          <Link
            className={s.PoliciesPage__link}
            href="/documents/terms_of_use.pdf"
            target="_blank"
          >
            Скачать (374 Кб)
          </Link>
        }
      />
      <ChipText
        title={'Политика обработки персональных данных'}
        className={s.PoliciesPage__policy}
        text={
          <Link
            className={s.PoliciesPage__link}
            href="/documents/policy.pdf"
            target="_blank"
          >
            Скачать (141 Кб)
          </Link>
        }
      />
    </PageContentBlock>
  );
}
